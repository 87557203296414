const LabelIcon = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="m6.71 1.416-4.413.88-.88 4.414a.437.437 0 0 0 .12.394l5.709 5.71a.431.431 0 0 0 .618 0l4.95-4.95a.433.433 0 0 0 0-.618l-5.71-5.71a.437.437 0 0 0-.394-.12v0Z"
      stroke="#1A202C"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.7 4.594a.106.106 0 1 1-.213 0 .106.106 0 0 1 .213 0Z"
      fill="#000"
      stroke="#1A202C"
      strokeWidth="1.1"
    />
  </svg>
);

export default LabelIcon;
