import Loading from "app/components/loading/Loading";
import useOutsideClick from "hooks/useOutsideClick";
import { handleGetAllMusicians } from "musicians/controllers";
import { useCallback, useMemo, useRef, useState } from "react";
import Button from "ui/components/button/Button";
import Label from "ui/components/label/Label";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import Search from "ui/icons/search/Search";
import { removeExtension } from "utils/mediaSizes";
import OpenStyleForm from "../openStyleForm/OpenStyleForm";
import styles from "./OpenGroupForm.module.scss";

type Props = {
  closeModal: Function;
  AppenGroup: Function;
  AppendNoGroup?: Function;
  otherGroup?: boolean;
};

const OpenGroupForm = ({
  closeModal,
  AppenGroup,
  AppendNoGroup,
  otherGroup,
}: Props) => {
  const listMusiciansRef = useRef(null);

  const [addGroupExist, setAddGroupExist] = useState(false);
  const [musicians, setMusicians] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [search, setSearch] = useState(null);
  const [groupSelected, setGroupSelected] = useState(null);
  const [modal, setModal] = useState("group");
  const [modalStyles, setModalStyles] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [noGroupInfos, setNoGroupInfos] = useState({
    name: "",
    styles: [],
    email: "",
  });

  const onClickOutsideIframe = useCallback(() => {
    setAddGroupExist(false);
  }, []);

  useOutsideClick(listMusiciansRef, onClickOutsideIframe);

  function opengroupExist() {
    setAddGroupExist(true);
  }

  function hideModal() {
    closeModal();
  }

  function selectGroup(group) {
    setGroupSelected(group);
    setSearch(group.name);
  }

  function AppendGroupToForm() {
    AppenGroup(groupSelected);
  }

  function getSearch(e) {
    const value = e.target.value;
    setSearch(value);
    setLoadingSearch(true);
    const filtersFinal = {
      search,
    };
    handleGetAllMusicians(filtersFinal)
      .then(function (response: any) {
        setMusicians(response.data);
        setAddGroupExist(true);
        setLoadingSearch(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: "aie",
          error: true,
        };
      });
  }

  function noGroup() {
    setModal("nogroup");
  }

  function handleName(e) {
    const value = e.target.value;
    setNoGroupInfos((prevState) => ({
      ...prevState,
      name: value,
    }));
  }

  function handleNoGroupEmail(e) {
    const value = e.target.value;
    setNoGroupInfos((prevState) => ({
      ...prevState,
      email: value,
    }));
  }

  function closeModalStyle() {
    setModalStyles(false);
  }

  function openStyles() {
    setModalStyles(true);
  }

  function addStyles(styles) {
    setNoGroupInfos((prevState) => ({
      ...prevState,
      styles: styles,
    }));
    setModalStyles(false);
  }

  function HandleAppendNoGroup() {
    if (noGroupInfos && noGroupInfos.name !== "") {
      AppendNoGroup(noGroupInfos);
    } else {
      setErrorName(true);
    }
  }

  function handleCloseNoGroup() {
    setModal("group");
    setNoGroupInfos({
      name: "",
      styles: [],
      email: "",
    });
  }

  const renderGroups = useMemo(() => {
    if (!musicians || musicians.length == 0) return null;

    const musicianActive = musicians.filter((x) => x.active == "1");
    return (
      <div className={styles.musicians}>
        {musicians.map((group) => {
          let picture;

          if (group.medias.length > 0) {
            picture = (
              <div className={styles.picture}>
                <img src={removeExtension(group.medias[0].url, "205x110")} />
              </div>
            );
          } else {
            picture = (
              <div className={styles.picture}>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                >
                  <path
                    opacity=".2"
                    d="M28.125 31.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75ZM8.125 36.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75Z"
                    fill="#A0AEC0"
                  />
                  <path
                    d="M28.125 31.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75ZM8.125 36.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75ZM32.5 12.5l-20 5"
                    stroke="#A0AEC0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 31.875V10l20-5v21.875"
                    stroke="#A0AEC0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          }

          return (
            <li
              className={styles.musicianitem}
              key={Math.random().toString(36)}
            >
              <button
                className={styles.musicianButton}
                type={"button"}
                onClick={() => selectGroup(group)}
              >
                {picture}

                <div className={styles.infos}>
                  <p className={styles.name}>{group.name}</p>
                </div>
              </button>
            </li>
          );
        })}
      </div>
    );
  }, [musicians]);

  return (
    <>
      {modal == "nogroup" && (
        <div className={styles.popup}>
          <div className={styles.box}>
            <p className={styles.popupTitle}>Ajouter un groupe</p>
            <button
              type={"button"}
              onClick={handleCloseNoGroup}
              className={styles.return}
            >
              <ArrowLeft />
              Revenir à la recherche de groupe
            </button>
            <div>
              <Label isRequired={true} htmlFor="name">
                Nom du groupe
              </Label>
              <input
                value={noGroupInfos.name}
                className={errorName ? "m-input m-input--red" : "m-input"}
                type={"text"}
                name={"name"}
                onChange={handleName}
              />
              {errorName && (
                <p className={"m-error"}>Le nom du groupe est requis</p>
              )}
            </div>
            <div className={styles.stylesBox}>
              <Label isRequired={true} htmlFor="styles">
                Style et ligne éditoriale
              </Label>
              {noGroupInfos.styles && noGroupInfos.styles.length > 0 && (
                <ul className={styles.listStyles}>
                  {noGroupInfos.styles.map((x) => {
                    return <li>{x.name}</li>;
                  })}
                </ul>
              )}
            </div>
            <button
              onClick={openStyles}
              type={"button"}
              className={styles.addStyles}
            >
              Ajouter des styles
            </button>
            {modalStyles && (
              <OpenStyleForm
                appendStyles={addStyles}
                stylesForm={noGroupInfos.styles}
                closeModal={closeModalStyle}
              />
            )}
            <div>
              <Label htmlFor="nogroup-email">Adresse email</Label>
              <input
                value={noGroupInfos.email}
                className={errorName ? "m-input m-input--red" : "m-input"}
                type={"text"}
                name={"email"}
                id="nogroup-email"
                onChange={handleNoGroupEmail}
                placeholder="Adresse email du musicien"
              />
              <p className={styles.info}>
                Renseignez l’adresse email du musicien pour lui envoyer une
                invitation pour rejoindre Zicozinc
              </p>
            </div>
            <div className={styles.popupButtons}>
              <Button
                onClick={handleCloseNoGroup}
                type={"button"}
                bgColor="grey300"
                textColor="grey900"
              >
                Annuler
              </Button>
              <Button
                type={"button"}
                onClick={HandleAppendNoGroup}
                bgColor="green"
                textColor="#fff"
              >
                Ajouter
              </Button>
            </div>
          </div>
        </div>
      )}
      {modal == "group" && (
        <div className={styles.popup}>
          <div className={styles.box}>
            <p className={styles.popupTitle}>Ajouter un groupe</p>
            <div>
              <Label isRequired={true} htmlFor="password">
                Nom du groupe
              </Label>
              <div className={styles.relative}>
                <div ref={listMusiciansRef}>
                  <div className={styles.search}>
                    <input
                      value={search}
                      onChange={getSearch}
                      type={"text"}
                      placeholder={"Rechercher un groupe ou un musicien"}
                    />
                    {loadingSearch ? (
                      <div className={styles.searchLoading}>
                        <Loading color={"green"} />
                      </div>
                    ) : (
                      <Search />
                    )}
                  </div>
                </div>
                {addGroupExist && renderGroups}
              </div>
              {otherGroup && (
                <button
                  onClick={noGroup}
                  type={"button"}
                  className={styles.otherpopup}
                >
                  Le groupe n’est pas sur Zicozinc
                </button>
              )}

              <div className={styles.popupButtons}>
                <Button
                  type={"button"}
                  onClick={hideModal}
                  bgColor="grey300"
                  textColor="grey900"
                >
                  Annuler
                </Button>
                <Button
                  type={"button"}
                  onClick={AppendGroupToForm}
                  bgColor="green"
                  textColor="#fff"
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OpenGroupForm;
