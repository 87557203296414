const Calendar = (props: any) => {
  // if (props.active) {
  //   return (
  //     <svg
  //       {...props}
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 16 16"
  //     >
  //       <path
  //         opacity=".2"
  //         d="M2.5 5.5h11V3a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v2.5Z"
  //         fill="#079F62"
  //       />
  //       <path
  //         d="M13 2.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5ZM11 1.5v2M5 1.5v2M2.5 5.5h11"
  //         stroke="#079F62"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   );
  // } else {
  //   return (
  //     <svg
  //       {...props}
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 16 16"
  //     >
  //       <path
  //         opacity=".2"
  //         d="M2.5 5.5h11V3a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v2.5Z"
  //         fill="#718096"
  //       />
  //       <path
  //         d="M13 2.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5ZM11 1.5v2M5 1.5v2M2.5 5.5h11"
  //         stroke="#718096"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   );
  // }
  switch (props.state) {
    case "active":
      return (
        <svg
          {...props}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <path
            opacity=".2"
            d="M2.5 5.5h11V3a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v2.5Z"
            fill="#079F62"
          />
          <path
            d="M13 2.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5ZM11 1.5v2M5 1.5v2M2.5 5.5h11"
            stroke="#079F62"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "grey":
      return (
        <svg
          {...props}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <path
            opacity=".2"
            d="M2.5 5.5h11V3a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v2.5Z"
            fill="#718096"
          />
          <path
            d="M13 2.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5ZM11 1.5v2M5 1.5v2M2.5 5.5h11"
            stroke="#718096"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path
            d="M13 2.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5ZM11 1.5v2M5 1.5v2M2.5 5.5h11"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};

export default Calendar;
