import "moment/locale/fr";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { useState, useEffect } from "react";
import fr from "date-fns/locale/fr";
import styles from "./AllEventsDatepicker.module.scss";
import { ArrowLeft, ArrowRight } from "phosphor-react";
import Button from "ui/components/button/Button";

registerLocale("fr", fr);

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setDateFilterOpen: (dateFilterOpen: boolean) => void;
  setPage: (page: number) => void;
};

const AllEventsDatepicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setDateFilterOpen,
  setPage,
}: Props) => {
  moment.locale("fr");
  const [currentStartDate, setCurrentStartDate] = useState<Date | null>(
    startDate || null
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date | null>(
    endDate || null
  );

  const onChange = (dates: (Date | null)[], e) => {
    e.stopPropagation();
    const [start, end] = dates;
    setCurrentStartDate(start);
    setCurrentEndDate(end);
  };

  const validate = () => {
    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
    setDateFilterOpen(false);
    setPage(1);
  };

  const reset = () => {
    setCurrentStartDate(null);
    setCurrentEndDate(null);
  };

  return (
    <>
      <div className={styles.container}>
        <DatePicker
          locale={fr}
          selected={currentStartDate}
          onChange={onChange}
          startDate={currentStartDate}
          endDate={currentEndDate}
          monthsShown={2}
          selectsRange
          inline
          formatWeekDay={(nameOfDay) =>
            nameOfDay.charAt(0).toUpperCase() + nameOfDay.slice(0, 3).slice(1)
          }
          renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => {
            return (
              <>
                <div className={styles.calendarHeader}>
                  <button
                    type="button"
                    onClick={decreaseMonth}
                    className={styles.decreaseMonth}
                  >
                    <ArrowLeft weight="bold" />
                  </button>
                  <p>
                    {monthDate
                      .toLocaleString("fr", {
                        month: "long",
                        year: "numeric",
                      })
                      .charAt(0)
                      .toUpperCase() +
                      monthDate
                        .toLocaleString("fr", {
                          month: "long",
                          year: "numeric",
                        })
                        .slice(1)}
                  </p>
                  <button
                    type="button"
                    onClick={increaseMonth}
                    className={styles.increaseMonth}
                  >
                    <ArrowRight weight="bold" />
                  </button>
                </div>
              </>
            );
          }}
        />
        <div className={styles.buttons}>
          <Button bgColor="grey300" textColor="grey900" onClick={reset}>
            Réinitialiser la période
          </Button>
          <Button bgColor="green" textColor="grey900" onClick={validate}>
            Valider
          </Button>
        </div>
      </div>
    </>
  );
};

export default AllEventsDatepicker;
