import { actionTypesUser } from "users/actions";
import { UserType } from "users/types";
type Nullable<T> = T | null;

export type State = Readonly<{
  isLogged: boolean;
  token: any;
  user?: Nullable<UserType>;
}>;

const initialState: State = {
  isLogged: false,
  token: null,
  user: null,
};

const userState = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypesUser.ON_LOGIN:
      return {
        ...state,
        isLogged: true,
        token: action.payload.token,
        user: action.payload.user,
      };
    case actionTypesUser.ON_LOGOUT:
      return {
        ...state,
        isLogged: false,
        token: null,
        user: null,
      };
    default:
      return state;
  }
};

export default userState;
