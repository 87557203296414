import { useMemo } from "react";
import ReactSoundCloud from "react-soundcloud-embedded";
import Soundcloud from "ui/icons/soundcloud/Soundcloud";

import styles from "./MusicianSingleSoundCloud.module.scss";

const MusicianSingleSoundCloud = (props) => {
  const renderSounds = useMemo(() => {
    if (props.sounds && props.sounds.length == 0)
      return <p className={"m-empty"}>Aucun son SoundCloud</p>;
    return props.sounds.map((sound, index) => {
      return (
        <li key={index}>
          <ReactSoundCloud height={90} url={sound.url} />
        </li>
      );
    });
  }, [props.sounds]);

  return (
    <div className={styles.main}>
      <div className={styles.subtitle}>
        <Soundcloud className={styles.subtitle_icon} />
        <p>SoundCloud</p>
      </div>
      <div className={styles.list}>{renderSounds}</div>
    </div>
  );
};

export default MusicianSingleSoundCloud;
