const Pen = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="m13.125 4.494-2.596-2.601a.938.938 0 0 0-1.348-.018L7.636 3.416 2.15 8.906a.932.932 0 0 0-.275.662v2.62a.937.937 0 0 0 .938.937h2.619a.943.943 0 0 0 .662-.275l7.031-7.032a.943.943 0 0 0 0-1.324ZM11.25 6.37 8.63 3.75l1.214-1.213 2.619 2.62-1.213 1.212Z"
      fill="#352545"
    />
  </svg>
);

export default Pen;
