import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import ArrowRightComplete from "ui/icons/arrow-right-complete/ArrowRightComplete";
import Check from "ui/icons/check/Check";
import Minus from "ui/icons/minus/Minus";
import { hoursFr } from "app/constants/hours";

import styles from "./HourBar.module.scss";

type Props = {
  day: string;
};

const HourBar = ({ day }: Props) => {
  const { register, getValues, setValue } = useFormContext();
  const [close, setClose] = useState(false);
  const [cutHour, setCutHour] = useState(false);
  const [mounted, setMounted] = useState(false);

  const values = getValues();

  useEffect(() => {
    if (window.location.pathname.includes("/edit")) {
      if (!mounted) {
        if (values) {
          const hours = values.open_hours
            ? values.open_hours[0][day]
            : { am_start: "", am_end: "", pm_start: "", pm_end: "" };
          if (hours.is_closed) {
            setClose(true);
          }
        }
        setMounted(true);
      }
    }
  }, [values.open_hours, values, day, mounted]);

  useEffect(() => {
    if (values) {
      const hours = values.open_hours
        ? values.open_hours[0][day]
        : { am_start: "", am_end: "", pm_start: "", pm_end: "" };

      if (hours.pm_end != "" || hours.pm_start != "") {
        setCutHour(true);
      }
    }
  }, [values]);

  function ClickCutHour(e) {
    setCutHour(e.target.checked);
    if (!e.target.checked) {
      setValue(`open_hours[0].${day}`, {
        am_start: values.open_hours[0][day].am_start,
        am_end: values.open_hours[0][day].am_end,
        pm_start: "",
        pm_end: "",
      });
    }
  }

  function ClickCloseHour(e) {
    setClose(e.target.checked);
    const data = {
      is_closed: e.target.checked,
    };
    setValue(`open_hours[0].${day}.is_closed`, e.target.checked);
  }

  const renderSeparation = useMemo(() => {
    if (cutHour) {
      return <Minus className={styles.minus_icon} />;
    } else {
      return <ArrowRightComplete className={styles.arrow_icon} />;
    }
  }, [cutHour]);

  return (
    <div className={styles.main}>
      {day && <p className={styles.label}>{hoursFr[day].label}</p>}
      <div className={styles.wrapper}>
        <input
          disabled={close}
          {...register(`open_hours[0].${day}.am_start`)}
          type="time"
          className={styles.input}
        />
        <ArrowRightComplete className={styles.arrow_icon} />
        <input
          disabled={close}
          {...register(`open_hours[0].${day}.am_end`)}
          type="time"
          className={styles.input}
        />

        {cutHour && (
          <>
            {renderSeparation}

            <input
              disabled={close}
              {...register(`open_hours[0].${day}.pm_start`)}
              type="time"
              className={styles.input}
            />
            <ArrowRightComplete className={styles.arrow_icon} />
            <input
              disabled={close}
              {...register(`open_hours[0].${day}.pm_end`)}
              type="time"
              className={styles.input}
            />
          </>
        )}
      </div>
      <div className={styles.checkboxs}>
        <div className={styles.checkboxsItem}>
          <input
            checked={close}
            tabIndex={-1}
            type={"checkbox"}
            onChange={ClickCloseHour}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p className={styles.checkLabel}>Fermé</p>
        </div>
        <div className={styles.checkboxsItem}>
          <input
            tabIndex={-1}
            checked={cutHour}
            type={"checkbox"}
            onChange={ClickCutHour}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p className={styles.checkLabel}>Séparer en deux créneaux</p>
        </div>
      </div>
    </div>
  );
};

export default HourBar;
