import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Button from "ui/components/button/Button";
import Trash from "ui/icons/trash/Trash";
import { removeExtension } from "utils/mediaSizes";
import styles from "./GroupsItem.module.scss";
import moment from "moment";

type Props = {
  group: any;
  canDelete?: any;
  onDelete?: Function;
  target?: boolean;
  index?: number;
};

const GroupsItem = ({ group, onDelete, canDelete, target, index }: Props) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const renderPicture = useMemo(() => {
    if (group.medias && group.medias.length > 0) {
      return (
        <div className={styles.picture}>
          <img
            src={removeExtension(group.medias[0].url, "205x110")}
            alt={`${group.name} en concert gratuit sur Zicozinc`}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.picture}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
          >
            <path
              opacity=".2"
              d="M28.125 31.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75ZM8.125 36.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75Z"
              fill="#A0AEC0"
            />
            <path
              d="M28.125 31.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75ZM8.125 36.25a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75ZM32.5 12.5l-20 5"
              stroke="#A0AEC0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 31.875V10l20-5v21.875"
              stroke="#A0AEC0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    }
  }, [group]);

  function deleteGroup() {
    if (group.id) {
      onDelete(group.id, true);
      hideDeleteModal();
    } else {
      onDelete(index, false);
      hideDeleteModal();
    }
  }

  function showDeleteModal() {
    setDeleteModal(true);
  }

  function hideDeleteModal() {
    setDeleteModal(false);
  }

  const renderStatus = useMemo(() => {
    if (group.active === "0" && group.updated_at === null) {
      return (
        <p className={styles.waiting}>
          <span></span>En attente d'activation{" "}
        </p>
      );
    }
    if (group.active === "0" && group.updated_at !== null) {
      return (
        <p className={styles.desactive}>
          <span></span>Inactif
        </p>
      );
    }
    if (group.active === "1") {
      return (
        <p className={styles.active}>
          <span></span>Actif
        </p>
      );
    }
  }, [group]);

  return (
    <div className={styles.main}>
      {canDelete && (
        <>
          <button
            onClick={showDeleteModal}
            type={"button"}
            className={styles.delete}
          >
            <Trash />
          </button>
          {deleteModal && (
            <div className={styles.popup}>
              <div className={styles.box}>
                <p className={styles.popupTitle}>
                  Détacher l’utilisateur du groupe
                </p>
                <p className={styles.popupText}>
                  Souhaitez-vous vraiment détacher cet utilisateur du groupe{" "}
                  <b>{group.name}</b> ?
                </p>
                {/* <p className={styles.popupWarning}>
                  Attention, cet utilisateur étant le seul administrateur du
                  groupe, le détacher du groupe rendra le groupe inactif tant
                  qu’un nouvel administrateur n’a pas été attribué.
                </p> */}
                <div className={styles.popupButtons}>
                  <Button
                    type={"button"}
                    onClick={hideDeleteModal}
                    bgColor="grey300"
                    textColor="grey900"
                  >
                    Annuler
                  </Button>
                  <Button
                    type={"button"}
                    onClick={deleteGroup}
                    bgColor="dark-red"
                    textColor="grey0"
                  >
                    Supprimer
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {group.id ? (
        <Link
          target={target ? "_blank" : ""}
          to={`/groupes-et-musiciens/${group.id}`}
        >
          {renderStatus}
          {renderPicture}
          <div className={styles.infos}>
            <p className={styles.name}>{group.name}</p>
            {group.styles && (
              <ul className={styles.styles}>
                {group.styles.map((style, index) => {
                  if (index > 3) return null;
                  return <li className={styles.styleItem}>{style.name}</li>;
                })}
                {group.styles.length > 4 && (
                  <li className={styles.styleItem}>
                    +{group.styles.length - 4}
                    <p className={styles.tooltip}>
                      {group.styles.map((group, i) => {
                        if (i > 3) {
                          return <span>{group.name}</span>;
                        }
                      })}
                    </p>
                  </li>
                )}
              </ul>
            )}
            <p className={styles.createdAt}>
              Inscrit le {moment(group.created_at).format("DD/MM/YYYY")}
            </p>
          </div>
        </Link>
      ) : (
        <>
          {renderStatus}
          {renderPicture}
          <div className={styles.infos}>
            <p className={styles.name}>{group.name}</p>
            <p className={styles.email}>{group.email}</p>
            {group.styles && (
              <ul className={styles.styles}>
                {group.styles.map((style, index) => {
                  if (index > 3) return null;
                  return <li className={styles.styleItem}>{style.name}</li>;
                })}
                {group.styles.length > 4 && (
                  <li className={styles.styleItem}>
                    +{group.styles.length - 4}
                    <p className={styles.tooltip}>
                      {group.styles.map((group, i) => {
                        if (i > 3) {
                          return <span>{group.name}</span>;
                        }
                      })}
                    </p>
                  </li>
                )}
              </ul>
            )}
            <p className={styles.createdAt}>
              Inscrit le {moment(group.created_at).format("DD/MM/YYYY")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupsItem;
