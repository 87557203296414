const Image = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M16.875 3.75H3.125a.625.625 0 0 0-.625.625v11.25c0 .345.28.625.625.625h13.75c.345 0 .625-.28.625-.625V4.375a.625.625 0 0 0-.625-.625Z"
      stroke="#718096"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m2.5 13.125 3.93-3.93a.625.625 0 0 1 .89 0l3.485 3.485a.624.624 0 0 0 .89 0l1.61-1.61a.625.625 0 0 1 .89 0l3.305 3.305"
      stroke="#718096"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.188 8.75a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z"
      fill="#718096"
    />
  </svg>
);

export default Image;
