const MapPin = (props: any) => {
  if (props.active) {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <path
          opacity=".2"
          d="M7.5 1.406a4.687 4.687 0 0 0-4.688 4.688c0 4.218 4.688 7.5 4.688 7.5s4.688-3.281 4.688-7.5A4.688 4.688 0 0 0 7.5 1.406Zm0 6.563a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z"
          fill="#079F62"
        />
        <path
          d="M7.5 7.969a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
          stroke="#079F62"
          strokeWidth=".938"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.188 6.094c0 4.218-4.688 7.5-4.688 7.5s-4.688-3.281-4.688-7.5a4.687 4.687 0 1 1 9.376 0v0Z"
          stroke="#079F62"
          strokeWidth=".938"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path
          d="M7.5 7.969a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
          stroke-width=".938"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.188 6.094c0 4.218-4.688 7.5-4.688 7.5s-4.688-3.281-4.688-7.5a4.687 4.687 0 1 1 9.376 0v0Z"
          stroke-width=".938"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};

export default MapPin;
