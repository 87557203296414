import SubtitleForm from "form/components/subtitleForm/SubtitleForm";
import { useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import Check from "ui/icons/check/Check";
import Info from "ui/icons/info/Info";

import styles from "./InformationMusician.module.scss";

const InformationMusician = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const selectNegociation = watch("negociation_open");

  function replaceNegociation(e) {
    const value = e.target.value;

    if (value == 1) {
      setValue("negociation_open", true);
    } else {
      setValue("negociation_open", false);
    }
  }
  return (
    <div>
      <SubtitleForm text={"Informations"} icon={<Info />} />
      <div className={styles.list}>
        <div className={styles.item}>
          <Label htmlFor="siret" isRequired={true}>
            Nom du groupe/musicien
          </Label>
          <input
            type={"text"}
            className={errors.name ? "m-input m-input--red" : "m-input"}
            {...register("name", { required: true })}
          />
          {errors.name && (
            <p className={"m-error"}>Un nom de groupe est requis</p>
          )}
        </div>
        <div className={styles.item}>
          <Label htmlFor="siret" isRequired={true}>
            Adresse email
          </Label>
          <input
            type={"text"}
            className={errors.email ? "m-input m-input--red" : "m-input"}
            {...register("email", { required: true })}
          />
          {errors.email && <p className={"m-error"}>Un email est requis</p>}
        </div>
      </div>

      <div className={styles.list}>
        <div className={styles.item}>
          <Label htmlFor="phone" isRequired={true}>
            Téléphone
          </Label>
          <input
            type={"text"}
            className={"m-input"}
            {...register("phone", { pattern: /^\d{10}$/im })}
          />
          {errors.phone && errors.phone.type === "pattern" && (
            <p className={"m-error"}>Le téléphone n'est pas valide</p>
          )}
        </div>
        <div className={styles.item}>
          <Label htmlFor="siret" isRequired={true}>
            Ouvert à la négociation
          </Label>
          <div className={"m-select-box"}>
            <select onChange={replaceNegociation}>
              <option selected={selectNegociation} value={1}>
                Oui
              </option>
              <option selected={!selectNegociation} value={0}>
                Non
              </option>
            </select>
            <ArrowDown className={styles.select_arrow} />
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <Label htmlFor="siret" isRequired={true}>
            Type de musique
          </Label>
          <div className={styles.checkboxs}>
            <div className={styles.checkboxsSingle}>
              <input
                {...register("music_type.composition")}
                type={"checkbox"}
              />
              <div className={styles.checkmark}>
                <Check className={styles.check_icon} />
              </div>
              <p>Compositions</p>
            </div>
            <div className={styles.checkboxsSingle}>
              <input {...register("music_type.reprise")} type={"checkbox"} />
              <div className={styles.checkmark}>
                <Check className={styles.check_icon} />
              </div>
              <p>Reprises</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationMusician;
