import { useMemo } from "react";

import MediaSingle from "app/components/mediaSingle/MediaSingle";
import Image from "ui/icons/image/Image";

import styles from "./EventSingleMedia.module.scss";

type Props = {
  medias: any;
};

const EventSingleMedia = ({ medias }: Props) => {
  const renderMedias = useMemo(() => {
    if (medias && medias.length == 0)
      return <p className={"m-empty"}>Aucune photo</p>;
    return medias.map((media, index) => {
      return (
        <li className={styles.item} key={index}>
          <MediaSingle media={media} sizes={"205x110"} />
        </li>
      );
    });
  }, [medias]);

  return (
    <div className={styles.main}>
      <div className={styles.subtitle}>
        <Image className={styles.subtitle_icon} />
        <p>Photos</p>
      </div>
      <ul className={styles.list}>{renderMedias}</ul>
    </div>
  );
};

export default EventSingleMedia;
