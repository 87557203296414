import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { resetMedia } from "app/actions/media";
import { showModal } from "app/actions/modal";
import { handleDeleteMedia } from "app/controllers/media";
import { RootState } from "app/redux/store";

import { handleGetEvent, handleUpdateEvent } from "events/controllers";
import EventBar from "events/components/eventForm/eventBar/EventBar";

import EventCharacteristic from "events/components/eventForm/eventCharacteristic/EventCharacteristic";
import EventDescription from "events/components/eventForm/eventDescription/EventDescription";
import EventGroup from "events/components/eventForm/eventGroup/EventGroup";
import EventHour from "events/components/eventForm/eventHour/EventHour";
import EventInformation from "events/components/eventForm/eventInformation/EventInformation";

import HeaderForm from "form/components/headerForm/HeaderForm";
import MediaForm from "form/components/mediaForm/MediaForm";
import StylesForm from "form/components/stylesForm/StylesForm";

import Button from "ui/components/button/Button";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import styles from "./EditEvents.module.scss";
import Loading from "app/components/loading/Loading";
import { errorsAPI } from "app/constants/errors";

const EditEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mediasToDelete = useSelector(
    (state: RootState) => state.medias.mediasToDelete
  );
  const { id } = useParams();
  const [medias, setMedias] = useState([]);
  const [bar, setBar] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupsOthers, setGroupsOthers] = useState([]);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [styleError, setStyleError] = useState(false);
  const methods = useForm();

  const { register, handleSubmit, setValue } = methods;

  useEffect(() => {
    setLoading(true);
    handleGetEvent(id)
      .then(function (response: any) {
        setEvent(response.data);
        setLoading(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoading(false);
      });
    return () => {
      dispatch(resetMedia());
    };
  }, []);

  useEffect(() => {
    if (event) {
      if (event.medias) {
        const ids = event.medias.map((x) => {
          return { media_id: x.id };
        });
        setMedias(event.medias);
        setValue("medias", ids);
      }

      let groups = [];
      if (event.groups) {
        let groupFinal = [];

        event.groups.map((x) => {
          if (x) {
            groupFinal.push(x);
            groups.push({ group_id: x.id });
          }
        });
        setGroups(groupFinal);
      }

      if (event.otherGroups) {
        event.otherGroups.map((x) => {
          groups.push(x);
        });

        setGroupsOthers(event.otherGroups);
      }

      setValue("groups", groups);
      if (event.bars) {
        setBar(event.bars);
        if (!event.bars.id) {
          const data = {
            name: event.bars.name,
            address: event.bars.address,
            town: event.bars.town,
            geolocation: event.bars.geolocation,
          };
          setValue("bars", data);
        } else {
          setValue("bars", { bar_id: event.bars.id });
        }
      }

      setValue("active", event.active == 1 ? true : false);
      setValue("name", event.name);
      setValue("description", event.description);
      setValue("event_date", moment(event.event_date).format("YYYY-MM-DD"));
      setValue("event_hours", event.event_hours);
      setValue("caracteristics", event.caracteristics);
      setValue("styles", JSON.parse(event.styles));
      setValue("transport", event.transport);
    }
  }, [event]);

  const addMedia = (data) => {
    setMedias([...medias, data]);
  };

  const removeMedia = (mediaId) => {
    setMedias(medias.filter((x) => x.id !== mediaId));
  };

  const onSubmit = (data) => {
    if (data.styles.length === 0) {
      setStyleError(true);
      return;
    } else {
      setStyleError(false);
    }
    setLoadingValidate(true);
    handleUpdateEvent(data, id)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Evènement modifié avec succes",
          error: false,
        };
        if (mediasToDelete && mediasToDelete.length > 0) {
          mediasToDelete.map((media) => {
            setLoadingValidate(true);
            handleDeleteMedia(media)
              .then(function (response: any) {
                setLoadingValidate(false);
              })
              .catch((err) => {});
          });
        }

        setTimeout(() => {
          dispatch(showModal(data));
          navigate("/evenements");
          setLoadingValidate(false);
        }, 2000);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoadingValidate(false);
      });
  };

  return (
    <div className={styles.main}>
      <Link to={`/evenements/${id}`} className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Annuler
      </Link>
      <FormProvider {...methods}>
        <HeaderForm title={"Modifier un évènement"} label={"Evènement actif"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.head}>
              <p className={styles.title}>Détails de l’évènement</p>
            </div>
            <div className={styles.information}>
              <EventInformation />
            </div>
            <div className={styles.hours}>
              <EventHour />
            </div>
            <div className={styles.description}>
              <EventDescription />
            </div>
            <div className={styles.description}>
              <EventCharacteristic />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.styles}>
              <StylesForm required={true} />
            </div>
          </div>
          <div className={styles.section}>
            <MediaForm
              mediasFiles={medias}
              addMediasFiles={addMedia}
              deleteMediasFiles={removeMedia}
              typeFile={"events"}
            />
          </div>
          <div className={styles.sectionempty}>
            <div className={styles.head}>
              <p className={styles.title}>Groupe(s)</p>
            </div>
            <EventGroup groupsEdit={groups} groupsOthers={groupsOthers} />
          </div>

          <div className={styles.sectionempty}>
            <div className={styles.head}>
              <p className={styles.title}>
                Bar <span>*</span>
              </p>
            </div>
            <EventBar barEdit={bar} />
          </div>

          <div className={styles.bottom}>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="purple"
              className={styles.save_button}
            >
              {loadingValidate ? <Loading /> : "Enregistrer les modifications"}
            </Button>
          </div>
          {styleError && (
            <p className={styles.error}>Veuillez choisir au moins un style</p>
          )}
        </form>
      </FormProvider>
      {loading && (
        <div className={styles.loading}>
          <Loading rounded={true} />
        </div>
      )}
    </div>
  );
};

export default EditEvents;
