import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { userLogin } from "account/types";

import Button from "ui/components/button/Button";
import Input from "ui/components/input/Input";
import Label from "ui/components/label/Label";
import Eye from "ui/icons/eye/Eye";

import { onLogin } from "users/actions";
import { showModal } from "app/actions/modal";

import styles from "./Login.module.scss";

import { handleLogin } from "account/controllers/users";
import Loading from "app/components/loading/Loading";
import { errorsAPI } from "app/constants/errors";
import EyeOpened from "ui/icons/eyeOpened";
import Logo from "ui/icons/logo/Logo";
import { destroyCookie } from "nookies";

const Login = () => {
  const dispatch = useDispatch();
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<userLogin>({
    email: "",
    password: "",
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    setUser((user) => ({
      ...user,
      [name]: value,
    }));
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    setLoading(true);
    handleLogin(user)
      .then(function (response: any) {
        if (response.data.user.role_name !== "SUPER_ADMIN") {
          destroyCookie(null, "token", { path: "/" });
          window.location.href = "https://zicozinc.com";
        } else {
          dispatch(onLogin(response.data.user, response.data.token.token));
          window.location.reload();
          setLoading(false);
        }
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        setLoading(false);
        dispatch(showModal(data));
      });
  }

  const changePasswordInputType = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const rendereye = useMemo(() => {
    if (passwordInputType === "password") return <EyeOpened />;
    return <Eye />;
  }, [passwordInputType]);

  return (
    <Wrapper>
      <ContentContainer>
        <div className={styles.logo}>
          <Logo />
        </div>

        <FormContainer>
          <form onSubmit={handleSubmit}>
            <Label isRequired={true} htmlFor="email">
              Adresse email
            </Label>
            <Input
              id="email"
              type="email"
              name="email"
              required={true}
              placeholder="Votre adresse email"
              className={styles.input}
              initialValue={user.email}
              onChange={handleChange}
            />
            <Label isRequired={true} htmlFor="password">
              Mot de passe
            </Label>

            <Input
              id="password"
              type={passwordInputType}
              name="password"
              required={true}
              placeholder="Votre mot de passe"
              className={styles.input}
              initialValue={user.password}
              onChange={handleChange}
            >
              <button
                type="button"
                className={styles.eye_icon}
                onClick={changePasswordInputType}
              >
                {rendereye}
              </button>
            </Input>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="grey900"
              widthFull={true}
            >
              {loading ? <Loading /> : "Connexion"}
            </Button>
          </form>
          <Link className={styles.link} to="/compte/mot-de-passe-oublie">
            Vous avez oublié votre mot de passe ?
          </Link>
        </FormContainer>
      </ContentContainer>
      <Image
        src="https://res.cloudinary.com/dvi4nelfa/image/upload/v1655408410/uxer/zicozinc/login.png"
        alt=""
        aria-hidden="true"
      />
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.main`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const ContentContainer = styled.div`
  position: relative;
  padding: 48px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    padding: 0;
    width: 50%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    max-width: 384px;
  }
`;

const Image = styled.img`
  display: none;
  width: 50%;
  height: 100vh;
  object-fit: cover;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;
