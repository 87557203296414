import Layout from "app/components/Layouts";
import "./App.scss";

function App() {

  return (
    <Layout />
  )
  
}

declare global {
  interface Window {
    onSpotifyIframeApiReady?: any;
  }
}

export default App;
