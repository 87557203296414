const Martini = ({ className, active }: MartiniProps) => {
  if (active) {
    return (
      <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <path opacity=".2" d="M3.5 4.5h9L8 9 3.5 4.5Z" fill="#079F62" />
        <path
          d="M1.5 2.5h13L8 9 1.5 2.5ZM8 9v4.5M5.5 13.5h5M3.5 4.5h9"
          stroke="#079F62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path
          d="M1.5 2.5h13L8 9 1.5 2.5ZM8 9v4.5M5.5 13.5h5M3.5 4.5h9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};

interface MartiniProps {
  active?: boolean;
  className?: string;
}

export default Martini;
