import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";

import Label from "ui/components/label/Label";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import Info from "ui/icons/info/Info";
import styles from "./InformationBar.module.scss";

import Geocode from "react-geocode";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import useOutsideClick from "utils/useOutsideClick";

const InformationsBar = () => {
  const citiesRef = useRef(null);
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext();
  const selectNegociation = watch("negociation_open");
  const dontWantContact = watch("dont_want_contact");
  const selectCountry = watch("country");

  const [selectedLocation, setSelectedLocation] = useState<string>(null);
  const [citiesOpen, setCitiesOpen] = useState<boolean>(false);
  const [results, setResults] = useState<any>(null);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE);
  Geocode.setLanguage("fr");

  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    language: "fr",
  });

  function replaceNegociation(e) {
    const value = e.target.value;
    if (value == 1) {
      setValue("negociation_open", true);
    } else {
      setValue("negociation_open", false);
    }
  }

  function replaceDontWantContact(e) {
    const value = e.target.value;
    if (value == 1) {
      setValue("dont_want_contact", true);
    } else {
      setValue("dont_want_contact", false);
    }
  }

  const pushInSearchGoogleMapUrl = (label) => {
    setCitiesOpen(false);
    Geocode.fromAddress(label).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setSelectedLocation(label);
        setValue(`town`, label);
        setValue(`geolocation.lat`, lat.toString());
        setValue(`geolocation.lng`, lng.toString());
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const renderGoogleMapResult = useCallback(
    (result) => {
      return (
        <li key={result.place_id}>
          <button
            type={"button"}
            onClick={() =>
              pushInSearchGoogleMapUrl(result.structured_formatting.main_text)
            }
          >
            {result.structured_formatting.main_text}
          </button>
        </li>
      );
    },
    [citiesOpen]
  );
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(`geolocation.lat`, "");
      setValue(`geolocation.lng`, "");
      const {
        target: { value: q },
      } = event;
      setCitiesOpen(true);
      getPlacePredictions({
        input: q,
        types: ["(cities)"],
        componentRestrictions: { country: "fr" },
      });
      setSelectedLocation(q);
    },
    [setSelectedLocation]
  );

  const renderInput = useMemo(
    () => (
      <input
        {...register(`town`)}
        className={"m-input"}
        type={"text"}
        value={selectedLocation || ""}
        onChange={handleChange}
        placeholder={""}
        autoComplete={"off"}
      />
    ),
    [register, selectedLocation, handleChange]
  );

  const onClickOutsideCities = useCallback(() => {
    const lat = getValues(`geolocation.lat`);
    const lng = getValues(`geolocation.lng`);

    if (lat == "" || lng == "" || lat === null || lng === null) {
      setSelectedLocation("");
      setCitiesOpen(false);
    }
  }, [getValues]);

  useOutsideClick(citiesRef, onClickOutsideCities);

  return (
    <>
      <div className={styles.head}>
        <p className={styles.title}>Profil du lieu</p>
      </div>
      <p className={styles.subtitle}>
        <Info className={styles.subtitle_icon} />
        Informations
      </p>
      <div className={styles.form}>
        <div>
          <Label htmlFor="bar-name" isRequired={true}>
            Nom du bar/lieu
          </Label>
          <input
            className={errors.name ? "m-input m-input--red" : "m-input"}
            {...register("name", { required: "Un nom de bar est requis" })}
          />
          {errors.name && <p className={"m-error"}>Un nom de bar est requis</p>}
        </div>
        <div>
          <Label htmlFor="siret" isRequired={true}>
            Numéro de SIRET
          </Label>
          <input
            className={errors.siret ? "m-input m-input--red" : "m-input"}
            placeholder="123 568 941 00056"
            {...register("siret", {
              required: true,
              pattern: /^\d{14}$/im,
              onChange: (e) => {
                const value = e.target.value;
                const formattedValue = value.split(" ").join("");
                e.target.value = formattedValue;
              },
            })}
          />

          {errors.siret && errors.siret.type === "required" && (
            <p className={"m-error"}>Un numéro de siret est requis</p>
          )}
          {errors.siret && errors.siret.type === "pattern" && (
            <p className={"m-error"}>Le siret doit contenir 14 chiffres</p>
          )}
        </div>
        <div>
          <Label htmlFor="address" isRequired={true}>
            Adresse
          </Label>
          <input
            className={errors.address ? "m-input m-input--red" : "m-input"}
            {...register("address", { required: true })}
          />
          {errors.address && (
            <p className={"m-error"}>Une adresse est requis</p>
          )}
        </div>
        <div>
          <Label htmlFor="country" isRequired={true}>
            Pays
          </Label>
          <div
            className={
              errors.country ? "m-select-box m-select--red" : "m-select-box"
            }
          >
            <select
              defaultValue={selectCountry}
              {...register("country", { required: true })}
            >
              <option value={"France"}>France</option>
              <option value={"Allemagne"}>Allemange</option>
            </select>
            <ArrowDown />
          </div>

          {errors.country && <p className={"m-error"}>Un pays est requis</p>}
        </div>
        <div ref={citiesRef}>
          <Label htmlFor="town" isRequired={true}>
            Ville
          </Label>
          <div className={styles.city}>
            {renderInput}
            {citiesOpen && (
              <ul className={styles.cities}>
                {placePredictions.map(renderGoogleMapResult)}
              </ul>
            )}
          </div>

          {/* <input
            className={errors.town ? "m-input m-input--red" : "m-input"}
            {...register("town", { required: true })}
          /> */}
          {errors.town && <p className={"m-error"}>Une ville est requis</p>}
        </div>
        <div className={styles.flex}>
          <div>
            <Label htmlFor="postal" isRequired={true}>
              Code postal
            </Label>
            <input
              className={
                errors.postal
                  ? "m-input m-input--small m-input--red"
                  : "m-input m-input--small"
              }
              {...register("postal", { required: true, pattern: /^\d{5}$/im })}
            />
            {errors.postal && errors.postal.type === "required" && (
              <p className={"m-error"}>Un code postal est requis</p>
            )}
            {errors.postal && errors.postal.type === "pattern" && (
              <p className={"m-error"}>Le doit contenir 5 chiffres</p>
            )}
          </div>
          <div>
            <Label htmlFor="negociation" isRequired={true}>
              Ouvert à la négociation
            </Label>
            <div className={"m-select-box m-select-box--small"}>
              <select onChange={replaceNegociation}>
                <option selected={selectNegociation} value={1}>
                  Oui
                </option>
                <option selected={!selectNegociation} value={0}>
                  Non
                </option>
              </select>
              <ArrowDown />
            </div>
          </div>
        </div>
        <div>
          <Label htmlFor="email" isRequired={true}>
            Adresse email{" "}
          </Label>
          <input
            className={errors.email ? "m-input m-input--red" : "m-input"}
            {...register("email", {
              required: true,
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
            })}
          />

          {errors.email && errors.email.type === "required" && (
            <p className={"m-error"}>Un email est requis</p>
          )}
          {errors.email && errors.email.type === "pattern" && (
            <p className={"m-error"}>L'email n'est pas valide</p>
          )}
        </div>
        <div>
          <Label htmlFor="phone" isRequired={true}>
            Numéro de téléphone
          </Label>
          <input
            className={errors.phone ? "m-input m-input--red" : "m-input"}
            {...register("phone", { required: true, pattern: /^\d{10}$/im })}
          />
          {errors.phone && errors.phone.type === "required" && (
            <p className={"m-error"}>Un numéro de téléphone est requis</p>
          )}
          {errors.phone && errors.phone.type === "pattern" && (
            <p className={"m-error"}>Le numéro de téléphone n'est pas valide</p>
          )}
        </div>
        <div>
          <Label htmlFor="negociation" isRequired={true}>
            Ne veut pas se faire contacter
          </Label>
          <div className={"m-select-box m-select-box--small"}>
            <select onChange={replaceDontWantContact}>
              <option selected={dontWantContact} value={1}>
                Oui
              </option>
              <option selected={!dontWantContact} value={0}>
                Non
              </option>
            </select>
            <ArrowDown />
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationsBar;
