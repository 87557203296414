const Eye = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <path
        d="m11.783 7.459 1.342 2.314M9.035 8.748l.416 2.361M5.959 8.742l-.416 2.367M3.21 7.459 1.87 9.785M1.875 6.146c.984 1.22 2.79 2.76 5.625 2.76 2.836 0 4.64-1.54 5.625-2.76"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Eye;
