import { setCookie } from "nookies";

import { userLogin, userType } from "account/types";
import {
  postRequest,
  postRequestWithoutToken,
  getRequest,
  putRequest,
  deleteRequest,
} from "utils/useApi";

export const handleAccountMe = () => {
  return getRequest("users/me").then((response) => {
    return response;
  });
};

export const handleLogin = (user: userLogin) => {
  const cookiesOptions = {
    maxAge: 31536000,
    path: "/",
  };

  return postRequestWithoutToken("login", user).then((response) => {
    setCookie(null, "token", response.data.data.token.token, cookiesOptions);
    return response.data;
  });
};

export const handleRegistration = (user: userType) => {
  return postRequest("users", user).then((response) => {
    return response.data;
  });
};

export const handleGetAllUsers = (form?) => {
  return getRequest("users", form).then((response) => {
    return response.data;
  });
};

export const handleGetUsersByFilters = (form) => {
  return getRequest(`users`, form).then((response) => {
    return response.data;
  });
};

export const handleGetUserById = (id: string) => {
  return getRequest(`users/${id}`).then((response) => {
    return response.data;
  });
};

export const handleModifyUser = (user: userType, id: string) => {
  return putRequest(`users/${id}`, user).then((response) => {
    return response.data;
  });
};

export const handleForgetPassword = (email: string) => {
  return postRequest(`forget-password`, email).then((response) => {
    return response.data;
  });
};

export const handleUpdatePassword = (form: string) => {
  return postRequest(`create-new-password`, form).then((response) => {
    return response.data;
  });
};

// GROUP USER LINK

export const handleaddGroupToUser = (groupUserLink: any) => {
  return postRequest(`groups-users-link`, groupUserLink).then((response) => {
    return response.data;
  });
};

export const handledeleteGroupToUser = (groupUserId: any) => {
  return deleteRequest(`groups-users-link/${groupUserId}`).then((response) => {
    return response.data;
  });
};

export const handleGetGroupByUser = (userId: any) => {
  return getRequest(`groups-users-link/user/${userId}`).then((response) => {
    return response.data;
  });
};

// BAR USER LINK

export const handleaddBarToUser = (barUserLink: any) => {
  return postRequest(`bars-users-link`, barUserLink).then((response) => {
    return response.data;
  });
};

export const handledeleteBarToUser = (barId: any) => {
  return deleteRequest(`bars-users-link/${barId}`).then((response) => {
    return response.data;
  });
};

export const handleGetBarByUser = (userId: any) => {
  return getRequest(`bars-users-link/user/${userId}`).then((response) => {
    return response.data;
  });
};

export const handleGetUsersExport = () => {
  return getRequest("users/csv").then((response) => {
    return response;
  });
};
