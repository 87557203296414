import { useEffect, useMemo, useState } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";
import OpenGroupForm from "form/components/openGroupForm/OpenGroupForm";
import GroupsItem from "musicians/components/groups/groupsItem/GroupsItem";
import Plus from "ui/icons/plus/Plus";

import styles from "./UserGroup.module.scss";

type Props = {
  groupsEdit?: any;
  groupsToDelete?: Function;
};

const UserGroup = ({ groupsEdit, groupsToDelete }: Props) => {
  const [modalGroup, setModalGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const { append, remove } = useFieldArray({
    name: "groups", // unique name for your Field Array
  });

  function hideModalGroup() {
    setModalGroup(false);
  }

  useEffect(() => {
    setGroups(groupsEdit);
  }, [groupsEdit]);

  function AppendGroupToForm(group) {
    setModalGroup(false);
    append({ group_id: group.id });
    setGroups([...groups, group]);
  }

  function deleteGroup(groupId) {
    const index = groups.findIndex((x) => x.id === groupId);
    const GroupId = groups[index].id;
    setGroups(groups.filter((x) => x.id !== groupId));
    groupsToDelete(GroupId);
    remove(index);
  }

  const renderGroups = useMemo(() => {
    return groups.map((group) => {
      return (
        <div className={styles.item}>
          <GroupsItem group={group} canDelete={true} onDelete={deleteGroup} />
        </div>
      );
    });
  }, [groups]);

  return (
    <>
      <div className={styles.list}>
        {renderGroups}
        <div className={styles.item}>
          <button
            className={styles.add}
            type={"button"}
            onClick={() => setModalGroup(true)}
          >
            <Plus />
            Ajouter un groupe
          </button>
        </div>
      </div>

      {modalGroup && (
        <OpenGroupForm
          closeModal={hideModalGroup}
          AppenGroup={AppendGroupToForm}
        />
      )}
    </>
  );
};

export default UserGroup;
