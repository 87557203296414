const BurgerIcon = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      d="M2.669 5.25a.437.437 0 0 1-.427-.53C2.592 3.035 4.59 1.75 7 1.75c2.412 0 4.408 1.285 4.758 2.97a.438.438 0 0 1-.426.53H2.668ZM11.375 9.188v.874a1.75 1.75 0 0 1-1.75 1.75h-5.25a1.75 1.75 0 0 1-1.75-1.75v-.874"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.531 9.625 3.72 8.75l2.187.875 2.188-.875 2.187.875 2.188-.875M1.313 7h11.374"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BurgerIcon;
