type Props = {
  color?: string;
};

const LogoMiniIcon = ({ color }: Props) => {
  if (color === "orange") {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 32">
        <g clipPath="url(#LogoMiniIcon)">
          <path
            d="M15.636 9.622a1.472 1.472 0 0 0-.585-1.15L4.258.29A1.414 1.414 0 0 0 2.36.457a1.438 1.438 0 0 0-.035 1.919L6.407 7.12.52 12.184c-.33.283-.521.695-.521 1.13 0 .434.185.85.515 1.136.019.016.035.032.054.045l6.71 5.234c-2.292.22-4.641.792-6.051 2.208a4.119 4.119 0 0 0-1.231 2.968c0 4.8 6.544 6.541 9.357 7.07a1.417 1.417 0 0 0 1.637-1.033 1.43 1.43 0 0 0-.889-1.73c-.856-.315-3.647-1.484-3.548-3.334a1.77 1.77 0 0 1 .626-1.307c1.286-1.143 4.275-1.375 6.442-1.224a1.475 1.475 0 0 0 1.445-.875 1.494 1.494 0 0 0-.326-1.668L9.348 15.58l5.748-4.79c.349-.29.544-.718.534-1.175"
            fill="#E08746"
          />
        </g>
        <defs>
          <clipPath id="LogoMiniIcon">
            <path fill="#fff" d="M0 0h15.636v32H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (color === "green") {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 32">
        <g clipPath="url(#LogoMiniIcon)">
          <path
            d="M15.636 9.622a1.472 1.472 0 0 0-.585-1.15L4.258.29A1.414 1.414 0 0 0 2.36.457a1.438 1.438 0 0 0-.035 1.919L6.407 7.12.52 12.184c-.33.283-.521.695-.521 1.13 0 .434.185.85.515 1.136.019.016.035.032.054.045l6.71 5.234c-2.292.22-4.641.792-6.051 2.208a4.119 4.119 0 0 0-1.231 2.968c0 4.8 6.544 6.541 9.357 7.07a1.417 1.417 0 0 0 1.637-1.033 1.43 1.43 0 0 0-.889-1.73c-.856-.315-3.647-1.484-3.548-3.334a1.77 1.77 0 0 1 .626-1.307c1.286-1.143 4.275-1.375 6.442-1.224a1.475 1.475 0 0 0 1.445-.875 1.494 1.494 0 0 0-.326-1.668L9.348 15.58l5.748-4.79c.349-.29.544-.718.534-1.175"
            fill="#12ED94"
          />
        </g>
        <defs>
          <clipPath id="LogoMiniIcon">
            <path fill="#fff" d="M0 0h15.636v32H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (color === "purple") {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 32">
        <g clipPath="url(#LogoMiniIcon)">
          <path
            d="M15.636 9.622a1.472 1.472 0 0 0-.585-1.15L4.258.29A1.414 1.414 0 0 0 2.36.457a1.438 1.438 0 0 0-.035 1.919L6.407 7.12.52 12.184c-.33.283-.521.695-.521 1.13 0 .434.185.85.515 1.136.019.016.035.032.054.045l6.71 5.234c-2.292.22-4.641.792-6.051 2.208a4.119 4.119 0 0 0-1.231 2.968c0 4.8 6.544 6.541 9.357 7.07a1.417 1.417 0 0 0 1.637-1.033 1.43 1.43 0 0 0-.889-1.73c-.856-.315-3.647-1.484-3.548-3.334a1.77 1.77 0 0 1 .626-1.307c1.286-1.143 4.275-1.375 6.442-1.224a1.475 1.475 0 0 0 1.445-.875 1.494 1.494 0 0 0-.326-1.668L9.348 15.58l5.748-4.79c.349-.29.544-.718.534-1.175"
            fill="#352545"
          />
        </g>
        <defs>
          <clipPath id="LogoMiniIcon">
            <path fill="#fff" d="M0 0h15.636v32H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (color === "white") {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 32">
        <g clipPath="url(#LogoMiniIcon)">
          <path
            d="M15.636 9.622a1.472 1.472 0 0 0-.585-1.15L4.258.29A1.414 1.414 0 0 0 2.36.457a1.438 1.438 0 0 0-.035 1.919L6.407 7.12.52 12.184c-.33.283-.521.695-.521 1.13 0 .434.185.85.515 1.136.019.016.035.032.054.045l6.71 5.234c-2.292.22-4.641.792-6.051 2.208a4.119 4.119 0 0 0-1.231 2.968c0 4.8 6.544 6.541 9.357 7.07a1.417 1.417 0 0 0 1.637-1.033 1.43 1.43 0 0 0-.889-1.73c-.856-.315-3.647-1.484-3.548-3.334a1.77 1.77 0 0 1 .626-1.307c1.286-1.143 4.275-1.375 6.442-1.224a1.475 1.475 0 0 0 1.445-.875 1.494 1.494 0 0 0-.326-1.668L9.348 15.58l5.748-4.79c.349-.29.544-.718.534-1.175"
            fill="#FFFFFF"
          />
        </g>
        <defs>
          <clipPath id="LogoMiniIcon">
            <path fill="#fff" d="M0 0h15.636v32H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return null;
};

LogoMiniIcon.defaultProps = {
  color: "green",
};

export default LogoMiniIcon;
