import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { RootState } from "app/redux/store";
import { handleGetBar, handleUpdateBar } from "bars/controllers";
import { showModal } from "app/actions/modal";

import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import HeaderBar from "bars/components/bar/headerBar/HeaderBar";
import InformationsBar from "bars/components/bar/informationsBar/InformationBar";
import DescriptionBar from "bars/components/bar/descriptionBar/DescriptionBar";
import HoursBar from "bars/components/bar/hoursBar/HoursBar";
import CharacteristicsBar from "bars/components/bar/characteristicsBar/CharacteristicsBar";
import Button from "ui/components/button/Button";
import { resetMedia } from "app/actions/media";
import { handleDeleteMedia } from "app/controllers/media";
import StylesForm from "form/components/stylesForm/StylesForm";

import Loading from "app/components/loading/Loading";
import VideoForm from "form/components/videoForm/VideoForm";
import MediaForm from "form/components/mediaForm/MediaForm";
import DocumentForm from "form/components/documentForm/DocumentForm";

import styles from "./EditBar.module.scss";
import { errorsAPI } from "app/constants/errors";

const EditBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [bar, setBar] = useState(null);
  const [barForForm, setBarForForm] = useState(null);
  const methods = useForm();
  const [documents, setDocuments] = useState([]);
  const [medias, setMedias] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const mediasToDelete = useSelector(
    (state: RootState) => state.medias.mediasToDelete
  );

  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = methods;

  useEffect(() => {
    setLoading(true);
    handleGetBar(id)
      .then(function (response: any) {
        setBar(response.data);
        setLoading(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        setLoading(false);
        dispatch(showModal(data));
      });
    return () => {
      dispatch(resetMedia());
    };
  }, []);

  useEffect(() => {
    if (bar) {
      if (bar.documents) {
        const ids = bar.documents.map((x) => {
          return { media_id: x.id };
        });
        setDocuments(bar.documents);

        setValue("documents", ids);
      }
      if (bar.medias) {
        const ids = bar.medias.map((x) => {
          return { media_id: x.id };
        });
        setMedias(bar.medias);
        setValue("medias", ids);
      }
      if (bar.videos) {
        setValue("videos", bar.videos);
        setVideos(bar.videos);
      }
      setValue("active", bar.active == 1 ? true : false);
      setValue("name", bar.name, { shouldValidate: true });
      setValue("siret", bar.siret);
      setValue("address", bar.address);
      setValue("town", bar.town);
      setValue("description", bar.description);
      setValue("postal", bar.postal);
      setValue("country", bar.country);
      setValue("phone", bar.phone);
      setValue("email", bar.email);
      setValue("open_hours", bar.open_hours);
      setValue("caracteristics", bar.caracteristics);
      setValue("negociation_open", bar.negociation_open == 1 ? true : false);
      setValue("styles", bar.styles);
      setValue("dont_want_contact", bar.dont_want_contact == 1 ? true : false);
    }
  }, [bar]);

  const addDocument = (data) => {
    setDocuments([...documents, data]);
  };

  const addMedia = (data) => {
    setMedias([...medias, data]);
  };

  const removeDocument = (documentId) => {
    setDocuments(documents.filter((x) => x.id !== documentId));
  };

  const removeMedia = (mediaId) => {
    setMedias(medias.filter((x) => x.id !== mediaId));
  };

  const onSubmit = (data) => {
    console.log(errors);
    setLoadingValidate(true);
    handleUpdateBar(data, id)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Bar modifié avec succes",
          error: false,
        };
        if (mediasToDelete && mediasToDelete.length > 0) {
          setLoadingValidate(true);
          mediasToDelete.map((media) => {
            handleDeleteMedia(media)
              .then(function (response: any) {
                setLoadingValidate(false);
              })
              .catch((err) => {
                setLoadingValidate(false);
              });
          });
        }

        setTimeout(() => {
          setLoadingValidate(false);
          navigate("/bars-et-lieux");
          dispatch(showModal(data));
        }, 2000);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoadingValidate(false);
      });
  };

  return (
    <div className={styles.main}>
      <Link to={`/bars-et-lieux/${id}`} className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Annuler
      </Link>
      <FormProvider {...methods}>
        <form id="editbar" onSubmit={handleSubmit(onSubmit)}>
          <HeaderBar title={"Modifier le lieu"} />
          <div className={styles.section}>
            <div className={styles.information}>
              <InformationsBar />
            </div>
            <div className={styles.description}>
              <DescriptionBar />
            </div>
            <div className={styles.hours}>
              <HoursBar />
            </div>
            <div className={styles.characteristics}>
              <CharacteristicsBar />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.styles}>
              <StylesForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <MediaForm
                mediasFiles={medias}
                addMediasFiles={addMedia}
                deleteMediasFiles={removeMedia}
                typeFile={"bars"}
              />
              <VideoForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <DocumentForm
                documentsFiles={documents}
                addDocumentsFiles={addDocument}
                deleteDocumentsFiles={removeDocument}
                typeFile={"bars"}
              />
            </div>
          </div>
          <div className={styles.bottom}>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="purple"
              className={styles.save_button}
            >
              {loadingValidate ? <Loading /> : "Enregistrer les modifications"}
            </Button>
          </div>
        </form>
      </FormProvider>
      {loading && (
        <div className={styles.loading}>
          <Loading rounded={true} />
        </div>
      )}
    </div>
  );
};

export default EditBar;
