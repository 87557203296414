import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Logo from "ui/icons/logo/Logo";
import Martini from "ui/icons/martini/Martini";
import MusicTone from "ui/icons/music-tone/MusicTone";
import Users from "ui/icons/users/Users";
import Calendar from "ui/icons/calendar/Calendar";
import SignOut from "ui/icons/sign-out/SignOut";

import styles from "./Menu.module.scss";
import Button from "../button/Button";
import { destroyCookie } from "nookies";

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.split("/");
  const [isOpen, setIsOpen] = useState(false);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  function logout(e) {
    e.stopPropagation();
    destroyCookie(null, "token", { path: "/" });
    navigate("/compte/connexion", { replace: true });
  }

  if (paths[1] === "compte" || paths[1] === "reset-password") {
    return null;
  } else {
    return (
      <MenuWrapper>
        <Logo className={styles.logo} />
        <Button
          bgColor="green"
          textColor="purple"
          type="button"
          onClick={handleMenu}
          className={styles.menu_button}
        >
          {isOpen ? "Fermer le menu" : "Ouvrir le menu"}
        </Button>
        <Nav className={isOpen ? "menu__open" : "menu__close"}>
          <menu>
            <MenuItem>
              <Link
                to="/bars-et-lieux"
                className={`${styles.link} ${
                  paths[1] === "bars-et-lieux" && styles.link__active
                }`}
              >
                <>
                  <Martini
                    className={styles.icon}
                    active={paths[1] === "bars-et-lieux"}
                  />
                  Bars et lieux
                </>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/groupes-et-musiciens"
                className={`${styles.link} ${
                  paths[1] === "groupes-et-musiciens" && styles.link__active
                }`}
              >
                <>
                  <MusicTone
                    className={styles.icon}
                    active={paths[1] === "groupes-et-musiciens"}
                  />
                  Groupes et musiciens
                </>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/utilisateurs"
                className={`${styles.link} ${
                  paths[1] === "utilisateurs" && styles.link__active
                }`}
              >
                <>
                  <Users
                    className={styles.icon}
                    active={paths[1] === "utilisateurs"}
                  />
                  Utilisateurs
                </>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/evenements"
                className={`${styles.link} ${
                  paths[1] === "evenements" && styles.link__active
                }`}
              >
                <>
                  <Calendar
                    className={styles.icon}
                    state={paths[1] === "evenements" ? "active" : ""}
                  />
                  Évènements
                </>
              </Link>
            </MenuItem>
            <MenuItem>
              <button onClick={logout} className={styles.link} type={"button"}>
                <SignOut className={styles.icon} />
                Déconnexion
              </button>
            </MenuItem>
          </menu>
        </Nav>
      </MenuWrapper>
    );
  }
};

export default Menu;

const MenuWrapper = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 425px) {
    padding: 24px;
  }
  @media screen and (min-width: 768px) {
    display: block;
    padding-top: 80px;
  }
`;

const Nav = styled.nav`
  &.menu__close {
    display: none;
  }
  &.menu__open {
    position: fixed;
    display: block;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    top: 10px;
    padding: 24px;
    z-index: 100;
    border-radius: 8px;
    @media screen and (min-width: 425px) {
    }
  }
  @media screen and (min-width: 768px) {
    display: block !important;
    position: sticky;
    top: 20px;
  }
`;

const MenuItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
