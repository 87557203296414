function getIDfromRegEx(src, regExpy) {
  return src.match(regExpy) ? RegExp.$1 : null;
}
export function getSpotifyUrl(url) {
  var spRegEx =
    /https?:\/\/(?:embed\.|open\.)(?:spotify\.com\/)(?:track\/|\?uri=spotify:track:)((\w|-){22})/;
  const Id = getIDfromRegEx(url, spRegEx);
  return (
    <iframe
      src={`https://open.spotify.com/embed/track/${Id}?utm_source=generator" width="100%" height="90"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture`}
    ></iframe>
  );
}

export function getDeezerUrl(url) {
  const lastSlash = url.lastIndexOf("/");
  const newUrl = url.substring(lastSlash + 1);

  return (
    <iframe
      title="deezer-widget"
      src={`https://widget.deezer.com/widget/dark/track/${newUrl}`}
      width="100%"
      height="300"
      allow="encrypted-media; clipboard-write"
    ></iframe>
  );
}

export function getSoundCloudUrl(url) {
  var scRegEx =
    /https?:\/\/(?:w\.|www\.|)(?:soundcloud\.com\/)(?:(?:player\/\?url=https\%3A\/\/api.soundcloud.com\/tracks\/)|)(((\w|-)[^A-z]{7})|([A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*(?!\/sets(?:\/|$))(?:\/[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*){1,2}))/;

  return getIDfromRegEx(url, scRegEx);
}
