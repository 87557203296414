const Person = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="M6.995 3.938a1.531 1.531 0 1 0 0-3.063 1.531 1.531 0 0 0 0 3.063ZM8.416 7.366l1.903 1.477a.656.656 0 0 0 .925-.924L9.078 5.135a1.088 1.088 0 0 0-.771-.322H5.682a1.11 1.11 0 0 0-.777.322L2.74 7.92a.656.656 0 0 0 .93.924l1.903-1.477-1.52 4.791a.658.658 0 1 0 1.192.558l1.75-2.756 1.75 2.756a.656.656 0 1 0 1.186-.558l-1.515-4.79Z"
      stroke="#1A202C"
      strokeWidth=".9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Person;
