import styles from "./EventSingleStyles.module.scss";
import MusicTone from "ui/icons/music-tone/MusicTone";
import StyleSingle from "app/components/stylesSingle/StyleSingle";

type Props = {
  stylesList: string;
};

const EventSingleStyles = ({ stylesList }: Props) => {
  return (
    <>
      <div className={styles.subtitle}>
        <MusicTone className={styles.subtitle_icon} />
        <p>Styles de musique</p>
      </div>
      <StyleSingle stylesList={JSON.parse(stylesList)} />
    </>
  );
};

export default EventSingleStyles;
