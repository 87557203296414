import moment from "moment";

export const removeExtension = (url: string, sizes) => {
  const apiurl = process.env.REACT_APP_API;
  const extension = url.split(".").pop();
  const urlWithoutExtension = url.replace(`.${extension}`, "");
  const newExtenstion =
    apiurl +
    urlWithoutExtension +
    `-${sizes}.${extension}?` +
    moment().format("HHmmss");

  return newExtenstion;
};

export const getExternalUrl = (url: string) => {
  const apiurl = process.env.REACT_APP_API;
  return apiurl + url;
};
