import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import Plus from "ui/icons/plus/Plus";
import OpenBarForm from "form/components/openBarForm/OpenBarForm";
import BarItem from "bars/components/bars/barItem/BarItem";
import Label from "ui/components/label/Label";

import styles from "./EventBar.module.scss";

type Props = {
  barEdit?: any;
};
const EventBar = ({ barEdit }: Props) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [modalBar, setModalBar] = useState(false);
  const [bar, setBar] = useState(barEdit ? barEdit : null);

  useEffect(() => {
    setBar(barEdit);
  }, [barEdit]);

  function hideModalBar() {
    setModalBar(false);
  }

  function AppendBarToForm(bar) {
    setModalBar(false);
    setValue("bars", { bar_id: bar.id });
    setBar(bar);
  }

  function deleteBar() {
    setBar(null);
    setValue("bars", null);
  }

  const renderBars = useMemo(() => {
    if (bar) {
      return (
        <div className={styles.item}>
          <BarItem bar={bar} canDelete={true} onDelete={deleteBar} />
        </div>
      );
    }
  }, [bar]);

  return (
    <>
      <div className={styles.list}>
        {renderBars}

        <input type={"hidden"} {...register("bars", { required: true })} />
        {!bar && (
          <div className={styles.item}>
            <button
              className={styles.add}
              type={"button"}
              onClick={() => setModalBar(true)}
            >
              <Plus />
              Ajouter un lieu
            </button>
          </div>
        )}
        <div className={styles.transport}>
          <Label htmlFor="transport">Comment s’y rendre ?</Label>
          <textarea
            className={"m-input m-input--textarea"}
            {...register("transport")}
          />
        </div>
      </div>
      {errors.bars && <p className="m-error">Un bar est requis</p>}
      {modalBar && (
        <OpenBarForm closeModal={hideModalBar} AppendBar={AppendBarToForm} />
      )}
    </>
  );
};

export default EventBar;
