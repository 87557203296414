import { getRequest, postRequest, putRequest } from "utils/useApi";

export const handleCreateEvent = (event: any) => {
  return postRequest("events", event).then((response) => {
    return response.data;
  });
};

export const handleGetEvent = (id) => {
  return getRequest("events/" + id).then((response) => {
    return response.data;
  });
};

export const handleGetAllEvents = (form?) => {
  return getRequest("events", form).then((response) => {
    return response.data;
  });
};

export const handleUpdateEvent = (event: any, id) => {
  return putRequest("events/" + id, event).then((response) => {
    return response.data;
  });
};

export const handleGetEventsExport = () => {
  return getRequest("events/csv").then((response) => {
    return response;
  });
};
