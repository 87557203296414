import { useMemo } from "react";

import TagIcon from "ui/icons/tag-icon/TagIcon";

import styles from "./BarSingleDocuments.module.scss";
import MediaSingle from "app/components/mediaSingle/MediaSingle";

type Props = {
  documents: any;
};

const BarSingleDocuments = ({ documents }: Props) => {
  const renderdocuments = useMemo(() => {
    if (documents && documents.length == 0)
      return <p className={"m-empty"}>Aucun document</p>;
    return documents.map((document, index) => {
      return (
        <li className={styles.item} key={index}>
          <MediaSingle media={document} sizes={"205x110"} />
        </li>
      );
    });
  }, [documents]);

  return (
    <div className={styles.main}>
      <ul className={styles.list}>{renderdocuments}</ul>
    </div>
  );
};

export default BarSingleDocuments;
