import { useMemo } from "react";

import Media from "app/components/media/Media";
import TagIcon from "ui/icons/tag-icon/TagIcon";

import styles from "./BarSingleVideos.module.scss";
import YoutubeMediaSingle from "app/components/youtubeMediaSingle/YoutubeMediaSingle";
import Youtube from "ui/icons/youtube/Youtube";

type Props = {
  videos: any;
};

const BarSingleVideos = ({ videos }: Props) => {
  const renderVideos = useMemo(() => {
    if (videos && videos.length == 0)
      return <p className={"m-empty"}>Aucune vidéo</p>;
    return videos.map((video, index) => {
      return (
        <li key={index} className={styles.item}>
          <YoutubeMediaSingle youtubeId={video.youtube_id} index={index} />
        </li>
      );
    });
  }, [videos]);

  return (
    <div className={styles.main}>
      <div className={styles.subtitle}>
        <Youtube className={styles.subtitle_icon} />
        <p>Vidéos Youtube</p>
      </div>
      <div className={styles.list}>{renderVideos}</div>
    </div>
  );
};

export default BarSingleVideos;
