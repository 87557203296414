import UserStroke from "ui/icons/user-stroke/UserStroke";
import styles from "./MusicianSingleCompo.module.scss";

type Props = {
  composition: any;
};

const MusicianSingleCompo = ({ composition }: Props) => {
  if (composition.length === 0)
    return <p className={"m-empty"}>Aucune formation</p>;
  return (
    <div className={styles.main}>
      <div className={styles.list}>
        {composition.map((compo) => (
          <div className={styles.item}>
            <p className={styles.name}>{compo.name}</p>
            <p className={styles.text}>{compo.description}</p>
            <p className={styles.number}>
              <UserStroke />
              {compo.nb_musicians} musicien
              {compo.nb_musicians > 1 ? "s" : ""}
            </p>
            {compo.prices && (
              <div className={styles.hours}>
                {compo.prices.map((price) => (
                  <p className={styles.hourItem}>
                    {price.duration / 60}h - {price.price} €
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicianSingleCompo;
