import styled, { css } from "styled-components";

const Label = ({
  children,
  isRequired,
  htmlFor,
  className,
}: LabelStyledProps) => {
  return (
    <LabelStyled
      className={className}
      isRequired={isRequired}
      htmlFor={htmlFor}
    >
      {children}
    </LabelStyled>
  );
};

export default Label;

interface LabelStyledProps {
  readonly children: React.ReactNode;
  readonly isRequired?: boolean;
  readonly htmlFor: string;
  readonly className?: string;
}

const LabelStyled = styled.label<LabelStyledProps>`
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  margin-bottom: 6px;
  color: #000000;
  ${(props) => {
    if (props.isRequired) {
      return css`
        &::after {
          content: "*";
          color: var(--red);
          margin-left: 2px;
        }
      `;
    }
  }}
`;
