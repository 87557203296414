import SubtitleForm from "form/components/subtitleForm/SubtitleForm";
import { useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import TextAlign from "ui/icons/text-align/TextAlign";

const DescriptionMusician = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <SubtitleForm text={"Description"} icon={<TextAlign />} />
      <div>
        <Label htmlFor="description" isRequired={true}>
          Description du groupe/musicien
        </Label>
        <textarea
          className={
            errors.description
              ? "m-input m-input--textarea m-input--red"
              : "m-input m-input--textarea"
          }
          {...register("description", { required: true })}
        />

        {errors.description && (
          <p className={"m-error"}>Une description est requise</p>
        )}
      </div>
    </>
  );
};

export default DescriptionMusician;
