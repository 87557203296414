const SignOut = (props: any) => {
  if (props.active) {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <path
          d="M10.875 5.375 13.5 8l-2.625 2.625M6.5 8h7M6.5 13.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h3.5"
          stroke="#079F62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path
          d="M10.875 5.375 13.5 8l-2.625 2.625M6.5 8h7M6.5 13.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};

export default SignOut;
