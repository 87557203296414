import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import PriceFormation from "../priceFormation/PriceFormation";

import styles from "./CompositionMusician.module.scss";

type Props = {
  indexFormation: number;
  removeComposition?: Function;
  composition?: any;
};

const CompositionMusician = ({
  indexFormation,
  removeComposition,
  composition,
}: Props) => {
  const { register, watch, getValues } = useFormContext();

  const [prices, setPrices] = useState(composition.prices);
  const { fields, append, remove } = useFieldArray({
    name: `composition.${indexFormation}.prices`, // unique name for your Field Array
  });

  const addPrice = () => {
    const data = {
      duration: 60,
      price: 81.94,
    };
    append(data);
    setPrices([...prices, data]);
  };

  const removePrice = (index) => {
    setPrices(prices.filter((x, i) => i !== index));
    remove(index);
  };

  const deleteFormation = () => {
    removeComposition(indexFormation);
  };

  const renderdPrices = useMemo(() => {
    return fields.map((price, i) => {
      return (
        <div key={Math.random().toString(36)} className={styles.pricesItem}>
          <PriceFormation
            price={price}
            indexFormation={indexFormation}
            index={i}
            removePrice={removePrice}
          />
        </div>
      );
    });
  }, [fields, indexFormation]);

  return (
    <div className={styles.main}>
      <div className={styles.top}>
        <div className={styles.name}>
          <Label htmlFor="name" isRequired={true}>
            Nom de la formation
          </Label>
          <input
            {...register(`composition.${indexFormation}.name`)}
            type={"text"}
            className={"m-input"}
          />
        </div>
        <div className={styles.number}>
          <Label htmlFor="nb_musicians" isRequired={true}>
            Nombre de musicien(s)
          </Label>
          <input
            {...register(`composition.${indexFormation}.nb_musicians`, {
              valueAsNumber: true,
            })}
            min={1}
            type={"number"}
            className={"m-input"}
          />
        </div>
      </div>
      <div className={styles.description}>
        <Label htmlFor="description" isRequired={true}>
          Description de la formation
        </Label>
        <textarea
          className={"m-input m-input--textarea"}
          {...register(`composition.${indexFormation}.description`)}
        >
          {" "}
        </textarea>
      </div>
      <div className={styles.prices}>
        <p className={styles.pricesLabel}>Cachet net par musicien</p>
        <div className={styles.pricesList}>{renderdPrices}</div>
        <p>
          <button type={"button"} onClick={addPrice} className={styles.add}>
            Ajouter un tarif
          </button>
        </p>
        <p>
          <button
            type={"button"}
            onClick={deleteFormation}
            className={styles.delete}
          >
            Supprimer la formation
          </button>
        </p>
      </div>
    </div>
  );
};

export default CompositionMusician;
