import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import Button from "ui/components/button/Button";
import Label from "ui/components/label/Label";
import Spotify from "ui/icons/spotify/Spotify";
import { getSpotifyUrl } from "utils/getSpotifyId";

import styles from "./SpotifyForm.module.scss";

const SpotifyForm = () => {
  const { watch } = useFormContext();

  const [sounds, setSounds] = useState([]);
  const [soundPopupOpen, setSoundPopupOpen] = useState(false);
  const [soundUrl, setSoundUrl] = useState("");
  const [error, setError] = useState(false);

  const { append, remove } = useFieldArray({
    name: "spotify",
  });

  useEffect(() => {
    watch((value, { name, type }) => {
      setSounds(value.spotify);
    });
  }, [watch]);

  const openSoundModal = () => {
    setSoundPopupOpen(true);
    setSoundUrl("");
  };
  function CloseYoutubeModal() {
    setSoundPopupOpen(false);
  }

  function updateSoundUrl(e) {
    setSoundUrl(e.target.value);
  }

  function ConfirmSoundUrl() {
    if (soundUrl == "") {
      setError(true);
    } else {
      append({ url: soundUrl });
      setSounds([...sounds, { url: soundUrl }]);
      setSoundPopupOpen(false);
    }
  }

  function deleteSound(soundUrl) {
    const index = sounds.findIndex((x) => x.url === soundUrl);
    remove(index);
    setSounds(sounds.filter((x) => x.url !== soundUrl));
  }

  const renderSounds = useMemo(() => {
    if ((sounds && sounds.length == 0) || sounds == undefined) return;
    return sounds.map((sound, index) => {
      return (
        <li key={index}>
          {getSpotifyUrl(sound.url)}
          <button
            type={"button"}
            onClick={() => deleteSound(sound.url)}
            className={styles.delete}
          >
            Supprimer le son
          </button>
        </li>
      );
    });
  }, [sounds, error]);

  return (
    <div className={styles.main}>
      <p className={styles.subtitle}>
        <Spotify className={styles.subtitle_icon} />
        Spotify
      </p>
      <ul className={styles.list}>
        {renderSounds}
        {sounds && sounds.length < 3 && (
          <li>
            <button
              onClick={openSoundModal}
              className={styles.add}
              type={"button"}
            >
              Ajouter un son depuis Spotify
            </button>
          </li>
        )}
      </ul>
      {soundPopupOpen && (
        <div className={styles.popup}>
          <div className={styles.box}>
            <p className={styles.popupTitle}>
              <Spotify />
              Ajouter un son depuis Spotify
            </p>
            <p className={styles.popupText}>
              <Label isRequired={true} htmlFor="confirm-password">
                Lien Spotify
              </Label>
              <input
                type={"text"}
                onChange={updateSoundUrl}
                className={error ? "m-input m-input--red" : "m-input"}
                placeholder={"Lien du son sur Spotify"}
              />
              {error && <p className="m-error">Une url est requis</p>}
            </p>
            <div className={styles.popupButtons}>
              <Button
                type={"button"}
                onClick={CloseYoutubeModal}
                bgColor="grey300"
                textColor="grey900"
              >
                Annuler
              </Button>
              <Button
                type={"button"}
                onClick={ConfirmSoundUrl}
                bgColor="green"
                textColor="purple"
              >
                Ajouter
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpotifyForm;
