import moment from "moment";
import { useMemo } from "react";
import SingleListItem from "ui/components/single-list-item/SingleListItem";
import Info from "ui/icons/info/Info";

import styles from "./MusicianSingleInformation.module.scss";

type Props = {
  information: any;
};

const MusicianSingleInformation = ({ information }: Props) => {
  const renderActiveClass = useMemo(() => {
    if (information.active !== "1") return styles.active;
    return styles.active + " " + styles.green;
  }, [information.active]);
  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Profil du groupe/musicien</p>
      </div>
      <div className={styles.subtitle}>
        <Info className={styles.subtitle_icon} />
        <p>Informations</p>
      </div>
      <ul className={styles.list}>
        <li>
          <p className={styles.label}>État</p>
          <div className={renderActiveClass}>
            <p>{information.active == 1 ? "Activé" : "Inactif"}</p>
          </div>
        </li>
        <li>
          <p className={styles.label}>Date d’inscription</p>
          <p className={styles.text}>
            {moment(information.created_at).format("DD/MM/YYYY")}
          </p>
        </li>
        {information.music_type && (
          <li>
            <p className={styles.label}>Type de musique</p>
            <p className={styles.text}>
              {information.music_type.composition && <span>Compositions</span>}
              {information.music_type.reprise && <span>Reprises</span>}
            </p>
          </li>
        )}

        <li>
          <p className={styles.label}>Négociation</p>
          <p className={styles.text}>
            {information.negociation_open
              ? "Ouvert à la négociation"
              : "Fermé à la négociation"}
          </p>
        </li>
        {information.email && (
          <li>
            <p className={styles.label}>Email</p>
            <p className={styles.text}>{information.email}</p>
          </li>
        )}
        {information.phone && (
          <li>
            <p className={styles.label}>Téléphone</p>
            <p className={styles.text}>{information.phone}</p>
          </li>
        )}
      </ul>
    </>
  );
};

export default MusicianSingleInformation;
