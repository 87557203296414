import { useMemo } from "react";
import ArrowRightComplete from "ui/icons/arrow-right-complete/ArrowRightComplete";
import Minus from "ui/icons/minus/Minus";
import styles from "./BarSingleHour.module.scss";

type Props = {
  hours: any;
};

const BarSingleHour = ({ hours }: Props) => {
  const renderHours = useMemo(() => {
    if (hours.is_closed) return "Fermé";

    if (hours.pm_end != "" || hours.pm_start != "") {
      return (
        <>
          <p>{hours.am_start !== "" ? hours.am_start : "-"}</p>

          <ArrowRightComplete className={styles.arrow} />

          <p>{hours.am_end !== "" ? hours.am_end : "-"}</p>
          <Minus className={styles.minus} />

          <p>{hours.pm_start !== "" ? hours.pm_start : "-"}</p>

          <ArrowRightComplete className={styles.arrow} />

          <p>{hours.pm_end !== "" ? hours.pm_end : "-"}</p>
        </>
      );
    }

    if (hours.am_end == "" && hours.pm_start == "" && hours.pm_end == "") {
      return <p>{hours.am_start !== "" ? hours.am_start : "-"}</p>;
    }

    if (hours.am_start != "" && hours.am_end != "") {
      return (
        <>
          <p>{hours.am_start}</p>
          <ArrowRightComplete className={styles.arrow} />
          <p>{hours.am_end}</p>
        </>
      );
    }
  }, [hours]);

  return <div className={styles.main}>{renderHours}</div>;
};

export default BarSingleHour;
