const ArrowOUtIcon = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      d="M10 3h3v3M9.5 6.5 13 3M6 13H3v-3M6.5 9.5 3 13"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowOUtIcon;
