import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import Geocode from "react-geocode";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import Label from "ui/components/label/Label";
import styles from "./LocationCity.module.scss";
import useOutsideClick from "utils/useOutsideClick";

type Props = {
  index?: number;
  onDeletePlace: Function;
};

const LocationCity = ({ index, onDeletePlace }: Props) => {
  const citiesRef = useRef(null);
  const { register, setValue, getValues } = useFormContext();
  const { remove } = useFieldArray({
    name: "places",
  });

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE);
  Geocode.setLanguage("fr");

  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    language: "fr",
  });

  const [selectedLocation, setSelectedLocation] = useState<string>(null);
  const [citiesOpen, setCitiesOpen] = useState<boolean>(false);
  const [results, setResults] = useState<any>(null);

  const pushInSearchGoogleMapUrl = (label) => {
    setCitiesOpen(false);
    Geocode.fromAddress(label).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setSelectedLocation(label);
        setValue(`places.${index}.town`, label);
        setValue(`places.${index}.lat`, lat.toString());
        setValue(`places.${index}.lng`, lng.toString());
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const renderGoogleMapResult = useCallback(
    (result) => {
      return (
        <li key={result.place_id}>
          <button
            type={"button"}
            onClick={() =>
              pushInSearchGoogleMapUrl(result.structured_formatting.main_text)
            }
          >
            {result.structured_formatting.main_text}
          </button>
        </li>
      );
    },
    [citiesOpen]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(`places.${index}.lat`, "");
      setValue(`places.${index}.lng`, "");
      const {
        target: { value: q },
      } = event;
      setCitiesOpen(true);
      getPlacePredictions({
        input: q,
        types: ["(cities)"],
        componentRestrictions: { country: "fr" },
      });
      setSelectedLocation(q);
    },
    [setSelectedLocation]
  );

  const renderInput = useMemo(
    () => (
      <input
        {...register(`places.${index}.town`)}
        className={"m-input"}
        type={"text"}
        value={selectedLocation || ""}
        onChange={handleChange}
        required
        placeholder={""}
        autoComplete="off"
      />
    ),
    [selectedLocation, results]
  );

  const onClickOutsideCities = useCallback(() => {
    const lat = getValues(`places.${index}.lat`);
    const lng = getValues(`places.${index}.lng`);

    if (lat == "" || lng == "" || lat === null || lng === null) {
      setSelectedLocation("");
      setCitiesOpen(false);
    }
  }, [getValues, index]);

  useOutsideClick(citiesRef, onClickOutsideCities);

  const removePlace = () => {
    onDeletePlace(index);
  };

  return (
    <>
      <div className={styles.box}>
        <div ref={citiesRef} className={styles.city}>
          <Label htmlFor="town" isRequired={true}>
            Ville
          </Label>
          {renderInput}
          {citiesOpen && (
            <ul className={styles.cities}>
              {placePredictions.map(renderGoogleMapResult)}
            </ul>
          )}
        </div>
        <div className={styles.rayon}>
          <Label htmlFor="rayon" isRequired={true}>
            Rayon (km)
          </Label>
          <input
            type={"number"}
            {...register(`places.${index}.rayon`, { valueAsNumber: true })}
            required
            className={"m-input"}
          />
        </div>
      </div>
      <button type={"button"} className={styles.delete} onClick={removePlace}>
        Supprimer
      </button>
    </>
  );
};

export default LocationCity;
