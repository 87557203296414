import styled from "styled-components";
import { useState } from "react";

import Check from "ui/icons/check/Check";

import styles from "./Input.module.scss";

const Input = ({
  type = "text",
  id,
  name,
  required,
  className,
  placeholder,
  children,
  disabled = false,
  initialValue,
  onChange,
}: InputStyledProps) => {
  const [checked, setChecked] = useState(false);

  switch (type) {
    case "checkbox":
      return (
        <CheckboxContainer>
          <InputCheckbox
            type={type}
            id={id}
            name={name}
            required={required}
            placeholder={placeholder}
            className={className}
            onChange={() => setChecked(!checked)}
            checked={checked}
          />
          {checked ? <Check className={styles.check_icon} /> : ""}
        </CheckboxContainer>
      );
    default:
      return (
        <InputContainer>
          <InputStyled
            initialValue={initialValue}
            type={type}
            id={id}
            name={name}
            required={required}
            placeholder={placeholder}
            className={className}
            disabled={disabled}
            onChange={onChange}
          />
          {children}
        </InputContainer>
      );
  }
};

export default Input;

interface InputStyledProps {
  readonly type?: string;
  readonly id: string;
  readonly name: string;
  readonly required?: boolean;
  readonly placeholder?: string;
  readonly className?: string;
  readonly children?: React.ReactNode;
  readonly disabled?: boolean;
  readonly onChange?: (e: any) => void;
  initialValue?: any;
}

const InputContainer = styled.div`
  position: relative;
`;

const InputStyled = styled.input<InputStyledProps>`
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: var(--grey900);
  background-color: #fff;
  border: 1px solid var(--grey400);
  padding: 12px 38px 12px 16px;
  border-radius: 8px;
  &::placeholder {
    color: var(--grey600);
  }
  &:disabled {
    background-color: var(--grey200);
    cursor: not-allowed;
  }
  &:focus {
    border: 1px solid var(--grey500);
  }
`;

const InputCheckbox = styled.input`
  cursor: pointer;
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 5px;
  border: 1px solid var(--grey400);
  &:checked {
    background-color: var(--green);
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: var(--grey900);
`;
