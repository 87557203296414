const SortAscending = (props: any) => {
  if (props.active) {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <path
          d="m8.438 9.844 2.343 2.344 2.344-2.344M10.781 6.563v5.625M2.813 7.5H7.03M2.813 3.75h7.968M2.813 11.25h3.28"
          stroke="#079F62"
          strokeWidth=".938"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path
          d="m8.438 9.844 2.343 2.344 2.344-2.344M10.781 6.563v5.625M2.813 7.5H7.03M2.813 3.75h7.968M2.813 11.25h3.28"
          stroke-width=".938"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};

export default SortAscending;
