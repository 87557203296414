import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Martini from "ui/icons/martini/Martini";
import Trash from "ui/icons/trash/Trash";
import { removeExtension } from "utils/mediaSizes";
import Button from "ui/components/button/Button";

import styles from "./BarItem.module.scss";
import moment from "moment";

type Props = {
  bar: any;
  onDelete?: Function;
  canDelete?: Boolean;
  target?: Boolean;
};

const BarItem = ({ bar, onDelete, canDelete, target }: Props) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const renderPicture = useMemo(() => {
    if (!bar.medias || bar.medias.length === 0) {
      return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
          <path
            opacity=".2"
            d="M8.75 11.25h22.5L20 22.5 8.75 11.25Z"
            fill="#A0AEC0"
          />
          <path
            d="M3.75 6.25h32.5L20 22.5 3.75 6.25ZM20 22.5v11.25M13.75 33.75h12.5M8.75 11.25h22.5"
            stroke="#A0AEC0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    } else {
      return (
        <img
          src={removeExtension(bar.medias[0].url, "205x110")}
          alt={`${bar.name} ${bar.town} lieu de concert gratuit sur Zicozinc`}
        />
      );
    }
  }, [bar]);

  function deleteGroup() {
    onDelete(bar.id);
  }

  function showDeleteModal() {
    setDeleteModal(true);
  }

  function hideDeleteModal() {
    setDeleteModal(false);
  }

  const renderStatus = useMemo(() => {
    if (bar.active === 0 && bar.updated_at === null) {
      return (
        <p className={styles.waiting}>
          <span></span>En attente d'activation
        </p>
      );
    }
    if (bar.active === 0 && bar.updated_at !== null) {
      return (
        <p className={styles.desactive}>
          <span></span>Inactif
        </p>
      );
    }
    if (bar.active === 1) {
      return (
        <p className={styles.active}>
          <span></span>Actif
        </p>
      );
    }
  }, [bar]);

  const renderLink = useMemo(() => {
    if (bar.id) {
      return (
        <Link target={target ? "_blank" : ""} to={`/bars-et-lieux/${bar.id}`}>
          {renderStatus}
          <div className={styles.picture}>{renderPicture}</div>
          <div className={styles.infos}>
            {bar.name && <p className={styles.title}>{bar.name}</p>}
            {(bar.address || bar.postal) && (
              <p className={styles.location}>
                {bar.address} {bar.postal && `(${bar.postal})`}
              </p>
            )}
            <p className={styles.createdAt}>
              Inscrit le {moment(bar.created_at).format("DD/MM/YYYY")}
            </p>
          </div>
        </Link>
      );
    } else {
      return (
        <>
          {renderStatus}
          <div className={styles.picture}>{renderPicture}</div>
          <div className={styles.infos}>
            {bar.name && <p className={styles.title}>{bar.name}</p>}
            {(bar.address || bar.postal) && (
              <p className={styles.location}>
                {bar.address} {bar.postal && `(${bar.postal})`}
              </p>
            )}
            <p className={styles.createdAt}>
              Inscrit le {moment(bar.created_at).format("DD/MM/YYYY")}
            </p>
          </div>
        </>
      );
    }
  }, [
    bar.address,
    bar.created_at,
    bar.id,
    bar.name,
    bar.postal,
    renderPicture,
    renderStatus,
    target,
  ]);

  return (
    <div className={styles.main}>
      {canDelete && (
        <>
          <button
            onClick={showDeleteModal}
            type={"button"}
            className={styles.delete}
          >
            <Trash />
          </button>
          {deleteModal && (
            <div className={styles.popup}>
              <div className={styles.box}>
                <p className={styles.popupTitle}>
                  Détacher l’utilisateur du bar
                </p>
                <p className={styles.popupText}>
                  Souhaitez-vous vraiment détacher cet utilisateur du lieu{" "}
                  <b>{bar.name}</b> ?
                </p>
                {/* <p className={styles.popupWarning}>
                  Attention, cet utilisateur étant le seul administrateur du
                  bar, le détacher du bar rendra le bar inactif tant qu’un
                  nouvel administrateur n’a pas été attribué.
                </p> */}
                <div className={styles.popupButtons}>
                  <Button
                    type={"button"}
                    onClick={hideDeleteModal}
                    bgColor="grey300"
                    textColor="grey900"
                  >
                    Annuler
                  </Button>
                  <Button
                    type={"button"}
                    onClick={deleteGroup}
                    bgColor="dark-red"
                    textColor="grey0"
                  >
                    Supprimer
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {renderLink}
    </div>
  );
};

export default BarItem;
