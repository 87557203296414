import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import styles from "./SeeButton.module.scss";

import SelectButton from "../select-button/SelectButton";
import EyeFill from "ui/icons/eye-fill/EyeFill";

const SeeButton = () => {
  const [seePopupOpen, setSeePopupOpen] = useState(false);
  const seePopupOpenRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        seePopupOpenRef.current &&
        !seePopupOpenRef.current.contains(e.target)
      ) {
        setSeePopupOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [seePopupOpenRef]);
  return (
    <SelectButton onClick={() => setSeePopupOpen(true)}>
      <EyeFill className={styles.eye_icon} />
      <p>Voir</p>
      {seePopupOpen ? (
        <SeePopup ref={seePopupOpenRef}>
          <SeePopupButton>
            <EyeFill className={styles.eye_icon} />
            Voir en mode public
          </SeePopupButton>
          <SeePopupButton>
            <EyeFill className={styles.eye_icon} />
            Voir en mode lieu
          </SeePopupButton>
        </SeePopup>
      ) : (
        ""
      )}
    </SelectButton>
  );
};
export default SeeButton;

const SeePopup = styled.div`
  width: max-content;
  top: 50px;
  left: -40px;
  cursor: auto;
  z-index: 100;
  position: absolute;
  padding: 24px 29px 24px 16px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const SeePopupButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: var(--grey900);
  &:first-of-type {
    margin-bottom: 16px;
  }
`;
