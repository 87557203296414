import GroupsItem from "musicians/components/groups/groupsItem/GroupsItem";
import { useMemo } from "react";
import styles from "./EventSingleGroups.module.scss";

type Props = {
  groups: any;
};

const EventSingleGroups = ({ groups }: Props) => {
  const renderGroups = useMemo(() => {
    return groups.map((group) => {
      return (
        <div className={styles.item}>
          <GroupsItem target={true} group={group} />
        </div>
      );
    });
  }, [groups]);

  return <div className={styles.list}>{renderGroups}</div>;
};

export default EventSingleGroups;
