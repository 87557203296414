const Download = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="M5.04 6.445 7.5 8.906l2.46-2.46M7.5 2.344v6.562M12.656 8.906v3.281a.469.469 0 0 1-.469.47H2.813a.469.469 0 0 1-.47-.47v-3.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Download;
