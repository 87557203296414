import SingleListItem from "ui/components/single-list-item/SingleListItem";
import MapPin from "ui/icons/map-pin/MapPin";

import styles from "./MusicianSingleLocation.module.scss";

type Props = {
  places: any;
};

const MusicianSingleLocation = ({ places }: Props) => {
  return (
    <>
      <div className={styles.subtitle}>
        <MapPin className={styles.subtitle_icon} />
        <p>Lieux</p>
      </div>
      <div className={styles.places}>
        {places.map((place) => (
          <div className={styles.item}>
            <p className={styles.label}>{place.town}</p>
            <p className={styles.text}>Dans un rayon de {place.rayon} km</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default MusicianSingleLocation;
