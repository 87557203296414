import { useFormContext } from "react-hook-form";
import Switch from "ui/components/switch/Switch";

import styles from "./HeaderBar.module.scss";

type Props = {
    title: any;
};

const HeaderBar = ({title} : Props) => {
    return (
        <div className={styles.main}>
            <p className={styles.title}>{title}</p>
            <div className={styles.buttons}>
                <label htmlFor="active">Profil actif</label>
                <Switch id="active" name="active" />
            </div>
        </div>
    )
}

export default HeaderBar;