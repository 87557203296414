import { Link } from "react-router-dom";
import { handleGetUserById } from "account/controllers/users";
import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";
import moment from "moment";
import { internationalToPhoneNumber } from "utils/formatPhoneNumber";
import { useDispatch } from "react-redux";

import Button from "ui/components/button/Button";
import SingleListItem from "ui/components/single-list-item/SingleListItem";
import State from "ui/components/state/State";
import WhiteSection from "ui/components/white-section/WhiteSection";
import UserSingleGroups from "users/components/singleUser/userSingleGroups/UserSingleGroups";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import Pen from "ui/icons/pen/Pen";
import styles from "./SingleUser.module.scss";
import { showModal } from "app/actions/modal";
import UserSingleBars from "users/components/singleUser/userSingleBars/UserSingleBars";
import Loading from "app/components/loading/Loading";
import { errorsAPI } from "app/constants/errors";

const genders = {
  Mr: "Monsieur",
  Mlle: "Mademoiselle",
  Mme: "Madame",
};

const SingleUser = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const id = query.get("id");

  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetUserById(id)
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
  }, [id]);

  return (
    <main className={styles.main}>
      <Link to="/utilisateurs" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Retour
      </Link>
      {user && (
        <>
          <header className={styles.header}>
            <h1 className={styles.title}>
              {user.firstname} {user.lastname}
            </h1>
            <div>
              <Link to={`/utilisateurs/edit?id=${id}`}>
                <Button bgColor="green" textColor="purple">
                  <Pen className={styles.pen_icon} />
                  <p>Modifier</p>
                </Button>
              </Link>
            </div>
          </header>
          <WhiteSection title="Profil">
            <ul className={styles.list}>
              <li className={styles.list_item}>
                <SingleListItem listItemLabel="État">
                  <State active={user.active} />
                </SingleListItem>
              </li>
              <li className={styles.list_item}>
                <SingleListItem listItemLabel="Date d’inscription">
                  <p className={styles.list_text}>
                    {moment(user.created_at).format("DD/MM/YYYY")}
                  </p>
                </SingleListItem>
              </li>
              <li className={styles.list_item}>
                <SingleListItem listItemLabel="Civilité">
                  <p className={styles.list_text}>{genders[user.genre]}</p>
                </SingleListItem>
              </li>
              {user.birthdate && (
                <li className={styles.list_item}>
                  <SingleListItem listItemLabel="Date de naissance">
                    <p className={styles.list_text}>
                      {moment(user.birthdate).format("DD/MM/YYYY")}
                    </p>
                  </SingleListItem>
                </li>
              )}

              <li className={styles.list_item}>
                <SingleListItem listItemLabel="Adresse email">
                  <p className={styles.list_text}>{user.email}</p>
                </SingleListItem>
              </li>
              <li className={styles.list_item}>
                <SingleListItem listItemLabel="Mobile">
                  <p className={styles.list_text}>
                    {internationalToPhoneNumber(user.phone)}
                  </p>
                </SingleListItem>
              </li>
              <li className={styles.list_item}>
                <SingleListItem listItemLabel="Role">
                  <p className={styles.list_text}>
                    {user.role_name === "USER"
                      ? "Utilisateur"
                      : "Administrateur"}
                  </p>
                </SingleListItem>
              </li>
            </ul>
          </WhiteSection>
          <div className={styles.bottom}>
            {user.groups && user.groups.length > 0 && (
              <div className={styles.bottomBlock}>
                <p className={styles.bottomTitle}>Groupe(s)</p>
                <UserSingleGroups groups={user.groups} />
              </div>
            )}
            {user.bars && user.bars.length > 0 && (
              <div className={styles.bottomBlock}>
                <p className={styles.bottomTitle}>Bar(s) et lieu(x)</p>
                <UserSingleBars bars={user.bars} />
              </div>
            )}
          </div>
        </>
      )}

      {loading && (
        <div className={styles.loading}>
          <Loading rounded={true} />
        </div>
      )}
    </main>
  );
};

export default SingleUser;
