import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import TextAlign from "ui/icons/text-align/TextAlign";
import SubtitleForm from "form/components/subtitleForm/SubtitleForm";
import LocationCity from "./locationCity/LocationCity";

import styles from "./LocationMusician.module.scss";

const LocationMusician = () => {
  const { watch } = useFormContext();
  const [places, sePlaces] = useState([]);
  const { append, remove } = useFieldArray({
    name: "places",
  });

  useEffect(() => {
    watch((value) => {
      sePlaces(value.places);
    });
  }, [watch]);

  const addPlace = () => {
    const data = {
      town: "",
      rayon: 10,
      lat: "0",
      lng: "0",
    };
    append(data);
  };

  const removePlace = (index) => {
    remove(index);
    sePlaces(places.filter((x, i) => i !== index));
  };

  const renderPlaces = useMemo(() => {
    if ((places && places.length == 0) || places == undefined) return;
    return places.map((place, index) => {
      return (
        <div key={Math.random().toString(36)} className={styles.item}>
          <LocationCity index={index} onDeletePlace={removePlace} />
        </div>
      );
    });
  }, [places]);

  return (
    <>
      <SubtitleForm text={"Lieux"} icon={<TextAlign />} />
      <p className={styles.text}>
        Indiquez les villes où vous pouvez vous déplacer
      </p>
      <div className={styles.list}>{renderPlaces}</div>
      <button onClick={addPlace} type={"button"} className={styles.add}>
        Ajouter un lieu
      </button>
    </>
  );
};

export default LocationMusician;
