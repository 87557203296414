import Loading from "app/components/loading/Loading";
import Media from "app/components/media/Media";
import { handleCreateMedia } from "app/controllers/media";
import { MediaType } from "app/types/media";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import File from "ui/icons/file/File";

import styles from "./DocumentForm.module.scss";

type Props = {
  documentsFiles?: any;
  addDocumentsFiles?: Function;
  deleteDocumentsFiles?: Function;
  typeFile?: string;
};

const DocumentForm = ({
  documentsFiles,
  addDocumentsFiles,
  deleteDocumentsFiles,
  typeFile,
}: Props) => {
  const { watch } = useFormContext();
  const [media, setMedia] = useState<MediaType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState([]);
  const [mediaErrors, setMediaErrors] = useState({
    size: false,
    type: false,
  });
  const { append, remove } = useFieldArray({
    name: "documents", // unique name for your Field Array
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setDocuments(value.documents);
    });
  }, [watch]);

  useEffect(() => {
    if (media) {
      setLoading(true);
      handleCreateMedia(media)
        .then(function (response: any) {
          setTimeout(() => {
            append({ media_id: response.data.id });
            setDocuments([...documents, response.data]);
            setLoading(false);
            addDocumentsFiles(response.data);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    }
  }, [media]);

  function deleteMedia(mediaId) {
    const index = documents.findIndex((x) => x.id === mediaId);
    remove(index);
    setDocuments(documents.filter((x) => x.id !== mediaId));
    deleteDocumentsFiles(mediaId);
  }

  function uploadMedia(event) {
    setLoading(false);
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    const size = files[0].size;
    const type = files[0].type;

    if (size > 2000000 || type != "application/pdf") {
      setMediaErrors((mediaErrors) => ({
        ...mediaErrors,
        size: size > 2000000,
        type: type != "application/pdf",
      }));
      setLoading(false);
    } else {
      reader.onload = (e) => {
        let updatedValue = {
          name: files[0].name,
          type: typeFile,
          format: [
            { size: "1920x1080" },
            { size: "205x110" },
            { size: "366x162" },
          ],
          subtype: "documents",
          file_type: "pdf",
          fileBase64: e.target.result,
        };

        setMedia((media) => ({
          ...media,
          ...updatedValue,
        }));
      };
    }
  }

  const renderdocuments = useMemo(() => {
    if (documents && documents.length == 0) return null;

    return documentsFiles.map((document, index) => {
      return (
        <li key={index}>
          <Media
            index={index}
            media={document}
            sizes={"205x110"}
            onDelete={deleteMedia}
            type={"documents"}
          />
        </li>
      );
    });
  }, [documents]);

  const renderLoading = useMemo(() => {
    if (!loading) return null;
    return (
      <li>
        <p className={styles.loading}>
          <Loading />
        </p>
      </li>
    );
  }, [loading]);

  return (
    <div className={styles.main}>
      <div className={styles.head}>
        <p className={styles.title}>Documentation technique</p>
      </div>

      <ul>
        {renderdocuments}
        {renderLoading}
        <li>
          <button className={styles.add} type={"button"}>
            <input disabled={loading} onChange={uploadMedia} type={"file"} />
            <File />
            <p>Ajouter un fichier</p>
          </button>
        </li>
      </ul>
      <p className={styles.formats}>
        <b>Formats autorisés :</b> PDF - <b>Poids max :</b> 2mo
      </p>
      {mediaErrors.size && (
        <p className={styles.errors}>
          Le poids de l’image doit être inférieur à 2 Mo
        </p>
      )}
      {mediaErrors.type && (
        <p className={styles.errors}>L'image doit être un PDF ou DOC</p>
      )}
    </div>
  );
};

export default DocumentForm;
