import styled from "styled-components";

const State = ({
  active = false,
  className,
  texts = ["Actif", "Inactif"],
}: StateProps) => {
  return (
    <Active className={`${active ? "active" : ""} ${className}`}>
      <div></div>
      <p>{active ? texts[0] : texts[1]}</p>
    </Active>
  );
};

interface StateProps {
  readonly active?: boolean;
  readonly className?: string;
  readonly texts?: string[];
}

export default State;

const Active = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #ffdedc;
  div {
    width: 5px;
    height: 5px;
    border-radius: 24px;
    margin-right: 4px;
    background-color: #b53e35;
  }
  p {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    color: #b53e35;
  }
  &.active {
    background-color: #ddffd4;
    div {
      background-color: #28900d;
    }
    p {
      color: #28900d;
    }
  }
`;
