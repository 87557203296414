import moment from "moment";
import "moment/locale/fr";
import { useMemo } from "react";
import Calendar from "ui/icons/calendar/Calendar";
import { removeExtension } from "utils/mediaSizes";
import styles from "./EventItem.module.scss";

type Props = {
  event: any;
};

const EventItem = ({ event }: Props) => {
  moment.locale("fr");
  const renderPicture = useMemo(() => {
    if (event.medias && event.medias.length > 0) {
      return (
        <div className={styles.picture}>
          <img
            src={removeExtension(event.medias[0].url, "205x110")}
            alt={`concert gratuit ${
              event.groups.length > 0 ? event.groups[0].name : ""
            } à ${event.bars.name} le ${moment(event.event_date).format(
              "DD/MM/YYYY"
            )}sur Zicozinc`}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.picture}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
          >
            <path
              d="M32.5 6.25h-25c-.69 0-1.25.56-1.25 1.25v25c0 .69.56 1.25 1.25 1.25h25c.69 0 1.25-.56 1.25-1.25v-25c0-.69-.56-1.25-1.25-1.25ZM27.5 3.75v5M12.5 3.75v5M6.25 13.75h27.5"
              stroke="#A0AEC0"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      );
    }
  }, [event]);

  const renderStatus = useMemo(() => {
    if (event.active === 0 && event.updated_at === null) {
      return (
        <p className={styles.waiting}>
          <span></span>En attente d'activation
        </p>
      );
    }
    if (event.active === 0 && event.updated_at !== null) {
      return (
        <p className={styles.desactive}>
          <span></span>Inactif
        </p>
      );
    }
    if (event.active === 1) {
      return (
        <p className={styles.active}>
          <span></span>Actif
        </p>
      );
    }
  }, [event]);

  return (
    <div className={styles.main}>
      {renderStatus}
      {renderPicture}
      <div className={styles.infos}>
        <p className={styles.name}>{event.name}</p>
        <p className={styles.date}>
          {moment(event.event_date).format("dddd DD MMMM YYYY")}
        </p>
        <p className={styles.createdAt}>
          Créé le {moment(event.created_at).format("DD/MM/YYYY")}
        </p>
      </div>
    </div>
  );
};

export default EventItem;
