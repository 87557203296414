import { useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import Button from "ui/components/button/Button";
import Trash from "ui/icons/trash/Trash";
import { extractThumbnail } from "utils/getYoutubeId";
import styles from "./YoutubeMedia.module.scss";

type Props = {
  video: any;
  index: number;
  onDelete: Function;
};

const YoutubeMedia = ({ video, index, onDelete }: Props) => {
  const { remove } = useFieldArray({
    name: "videos", // unique name for your Field Array
  });

  const [deleteModal, setDeleteModal] = useState(false);

  function openDeleteModal() {
    setDeleteModal(true);
  }
  function closeDeleteModal() {
    setDeleteModal(false);
  }

  function confirmDelete() {
    remove(index);
    onDelete(video.youtube_id);
  }

  return (
    <>
      <div className={styles.main}>
        <button
          type={"button"}
          onClick={openDeleteModal}
          className={styles.delete}
        >
          <Trash />
        </button>
        <img src={extractThumbnail(video.youtube_id)} />
      </div>
      {deleteModal && (
        <div className={styles.popup}>
          <div className={styles.box}>
            <p className={styles.popupTitle}>Supprimer la vidéo</p>
            <p className={styles.popupText}>
              Souhaitez-vous vraiment supprimer cette vidéo ?<br /> Cette action
              est irréversible.
            </p>
            <div className={styles.popupButtons}>
              <Button
                type={"button"}
                onClick={closeDeleteModal}
                bgColor="grey300"
                textColor="grey900"
              >
                Annuler
              </Button>
              <Button
                type={"button"}
                onClick={confirmDelete}
                bgColor="red"
                textColor="purple"
              >
                Supprimer
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default YoutubeMedia;
