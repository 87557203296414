import { configureStore } from "@reduxjs/toolkit";
import userReducer from "users/reducers/index";
import modalReducer from "app/reducers/modal";
import mediaReducer from "app/reducers/media";

export const store = configureStore({
  reducer: {
    user: userReducer,
    toast: modalReducer,
    medias: mediaReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
