import { useEffect, useMemo, useState } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";
import OpenGroupForm from "form/components/openGroupForm/OpenGroupForm";
import GroupsItem from "musicians/components/groups/groupsItem/GroupsItem";
import Plus from "ui/icons/plus/Plus";

import styles from "./EventGroup.module.scss";

type Props = {
  groupsEdit?: any;
  groupsOthers?: any;
};

const EventGroup = ({ groupsEdit, groupsOthers }: Props) => {
  const [modalGroup, setModalGroup] = useState(false);
  const [groupsFinal, setGroupsFinal] = useState([]);
  const [groupOtherFinal, setGroupOtherFinal] = useState([]);
  const { append, remove, fields } = useFieldArray({
    name: "groups", // unique name for your Field Array
  });

  function hideModalGroup() {
    setModalGroup(false);
  }

  useEffect(() => {
    if (groupsEdit?.length > 0 || groupsOthers?.length > 0) {
      let copyGroups = [...groupsEdit];

      if (groupsOthers && groupsOthers.length > 0) {
        copyGroups = copyGroups.concat(groupsOthers);
      }
      setGroupsFinal(copyGroups);
    }
  }, [groupsEdit, groupsOthers]);

  function AppendGroupToForm(group) {
    append({ group_id: group.id });
    setGroupsFinal([...groupsFinal, group]);
    setModalGroup(false);
  }

  function AppendNoGroupToForm(group) {
    append(group);
    setGroupsFinal([...groupsFinal, group]);
    setModalGroup(false);
  }

  function deleteGroup(groupId, status) {
    if (status) {
      const index = groupsFinal.findIndex((x) => x.id === groupId);
      setGroupsFinal(groupsFinal.filter((x) => x.id !== groupId));
      remove(index);
    } else {
      let newOtherGroups = [...groupsFinal];
      newOtherGroups.splice(groupId, 1);
      setGroupsFinal(newOtherGroups);
      remove(groupId);
    }
  }

  const renderGroups = useMemo(() => {
    if (!groupsFinal || (groupsFinal && groupsFinal.length == 0)) return null;
    return groupsFinal.map((group, index) => {
      return (
        <div key={index} className={styles.item}>
          <GroupsItem
            index={index}
            group={group}
            canDelete={true}
            onDelete={deleteGroup}
          />
        </div>
      );
    });
  }, [groupsFinal]);

  return (
    <>
      <div className={styles.list}>
        {renderGroups}
        <div className={styles.item}>
          <button
            className={styles.add}
            type={"button"}
            onClick={() => setModalGroup(true)}
          >
            <Plus />
            Ajouter un groupe
          </button>
        </div>
      </div>

      {modalGroup && (
        <OpenGroupForm
          otherGroup={true}
          AppendNoGroup={AppendNoGroupToForm}
          closeModal={hideModalGroup}
          AppenGroup={AppendGroupToForm}
        />
      )}
    </>
  );
};

export default EventGroup;
