import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";

import { handleEditMusicians, handleGetMusician } from "musicians/controllers";

import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import { showModal } from "app/actions/modal";
import HeaderForm from "form/components/headerForm/HeaderForm";
import InformationMusician from "musicians/components/informationMusician/InformationMusician";
import DescriptionMusician from "musicians/components/descriptionMusician/DescriptionMusician";
import LocationMusician from "musicians/components/locationMusician/LocationMusician";
import Button from "ui/components/button/Button";
import FormationMusician from "musicians/components/formationMusician/FormationMusician";
import StylesForm from "form/components/stylesForm/StylesForm";
import MediaForm from "form/components/mediaForm/MediaForm";
import VideoForm from "form/components/videoForm/VideoForm";
import DocumentForm from "form/components/documentForm/DocumentForm";

import styles from "./EditMusician.module.scss";
import { resetMedia } from "app/actions/media";
import { RootState } from "app/redux/store";
import { handleDeleteMedia } from "app/controllers/media";
import Loading from "app/components/loading/Loading";
import SpotifyForm from "form/components/spotifyForm/SpotifyForm";
import SoundCloudForm from "form/components/soundCloudForm/SoundCloudForm";
import { errorsAPI } from "app/constants/errors";
import DeezerForm from "form/components/deezerForm/DeezerForm";

const EditMusician = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const methods = useForm();

  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [medias, setMedias] = useState([]);
  const [videos, setVideos] = useState([]);
  const [documents, setDocuments] = useState([]);
  const mediasToDelete = useSelector(
    (state: RootState) => state.medias.mediasToDelete
  );

  const { register, handleSubmit, setValue } = methods;

  useEffect(() => {
    setLoading(true);
    handleGetMusician(id)
      .then(function (response: any) {
        setGroup(response.data);
        setLoading(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
    return () => {
      dispatch(resetMedia());
    };
  }, []);

  useEffect(() => {
    if (group) {
      if (group.documents) {
        const ids = group.documents.map((x) => {
          return { media_id: x.id };
        });
        setDocuments(group.documents);
        setValue("documents", ids);
      }
      if (group.medias) {
        const ids = group.medias.map((x) => {
          return { media_id: x.id };
        });
        setMedias(group.medias);
        setValue("medias", ids);
      }

      if (group.videos) {
        setValue("videos", group.videos);
        setVideos(group.videos);
      }

      if (group.soundcloud) {
        setValue("soundcloud", group.soundcloud);
      }

      if (group.soundcloud) {
        setValue("spotify", group.spotify);
      }
      if (group.deezer) {
        setValue("deezer", group.deezer);
      }

      setValue("active", group.active == 1 ? true : false);
      setValue("places", group.places);
      let compositions: any = [];
      if(group.composition) {
        group.composition.map((composition: any, index: number) => {
          compositions.push(composition);
          if(composition.members) {
            composition.members.map((member: any, indexMember: number) => {
              if (member.user) {
                delete compositions[index].members[indexMember].user;
              }
            });
          }
          
        });
      }
    

      setValue("composition", compositions);
      setValue("name", group.name);
      setValue("description", group.description);
      setValue("email", group.email);
      setValue("phone", group.phone);
      setValue("music_type", group.music_type);
      setValue("negociation_open", group.negociation_open == 1 ? true : false);
      setValue("styles", group.styles);
    }
  }, [group]);

  const onSubmit = (data) => {
    setLoadingValidate(true);

    handleEditMusicians(data, id)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Groupe/Musicien modifié avec succès",
          error: false,
        };
        if (mediasToDelete && mediasToDelete.length > 0) {
          mediasToDelete.map((media) => {
            setLoadingValidate(true);
            handleDeleteMedia(media)
              .then(function (response: any) {
                setLoadingValidate(false);
              })
              .catch((err) => {
                const data = {
                  status: true,
                  message: errorsAPI[err.response.data.message],
                  error: true,
                };
                dispatch(showModal(data));
              });
          });
        }

        setTimeout(() => {
          setLoadingValidate(false);
          dispatch(showModal(data));
          navigate("/groupes-et-musiciens");
        }, 2000);
      })
      .catch((err) => {
        setLoadingValidate(false);
      });
  };

  const addMedia = (data) => {
    setMedias([...medias, data]);
  };

  const removeMedia = (mediaId) => {
    setMedias(medias.filter((x) => x.id !== mediaId));
  };

  const addDocument = (data) => {
    setDocuments([...documents, data]);
  };

  const removeDocument = (documentId) => {
    setDocuments(documents.filter((x) => x.id !== documentId));
  };

  return (
    <div className={styles.main}>
      <Link to="/groupes-et-musiciens" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Annuler
      </Link>
      <FormProvider {...methods}>
        <HeaderForm title={"Modifier le groupe/musicien"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.head}>
              <p className={styles.title}>Profil du groupe/musicien</p>
            </div>
            <div className={styles.information}>
              <InformationMusician />
            </div>
            <div className={styles.description}>
              <DescriptionMusician />
            </div>
            <div className={styles.location}>
              <LocationMusician />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.head}>
              <p className={styles.title}>Formations</p>
            </div>
            <div className={styles.formations}>
              <FormationMusician />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.styles}>
              <StylesForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <MediaForm
                mediasFiles={medias}
                addMediasFiles={addMedia}
                deleteMediasFiles={removeMedia}
                typeFile={"groups"}
              />
              <VideoForm />
              <SpotifyForm />
              <SoundCloudForm />
              <DeezerForm />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.media}>
              <DocumentForm
                documentsFiles={documents}
                addDocumentsFiles={addDocument}
                deleteDocumentsFiles={removeDocument}
                typeFile={"groups"}
              />
            </div>
          </div>

          <div className={styles.bottom}>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="purple"
              className={styles.save_button}
            >
              {loadingValidate ? <Loading /> : " Enregistrer les modifications"}
            </Button>
          </div>
        </form>
      </FormProvider>
      {loading && (
        <div className={styles.loading}>
          <Loading rounded={true} />
        </div>
      )}
    </div>
  );
};

export default EditMusician;
