

import { getRequest, postRequest, putRequest} from "utils/useApi";


export const handleGetAllMusicians = (form?) => {
  return getRequest("groups", form).then((response) => {
    return response.data;
  });
}

export const handleGetMusician = (id) => {
  return getRequest("groups/" + id).then((response) => {
    return response.data;
  });
}


export const handleCreateMusicians = (groups: any) => {
  return postRequest("groups", groups).then((response) => {
    return response.data; 
  });
};



export const handleEditMusicians = (groups: any, id) => {
  return putRequest("groups/" + id, groups).then((response) => {
    return response.data;
  });
};



export const handleGetBarsExport = () => {
  return getRequest("groups/csv").then((response) => {
    return response;
  });
};
