import SubtitleForm from "form/components/subtitleForm/SubtitleForm";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import Info from "ui/icons/info/Info";

import styles from "./EventInformation.module.scss";

const EventInformation = () => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <SubtitleForm text={"Informations"} icon={<Info />} />
      <div className={styles.main}>
        <div>
          <Label htmlFor="siret" isRequired={true}>
            Nom de l'évènement
          </Label>
          <input
            type={"text"}
            className={errors.name ? "m-input m-input--red" : "m-input"}
            {...register("name", { required: true })}
          />
          {errors.name && <p className={"m-error"}>Un nom est requis</p>}
        </div>
        <div>
          <Label isRequired={true} htmlFor="event_date">
            Date de l’évènement
          </Label>
          <input
            className={errors.event_date ? "m-input m-input--red" : "m-input"}
            type={"date"}
            {...register("event_date", { required: true })}
          />
          {errors.event_date && (
            <p className={"m-error"}>Une date est requise</p>
          )}
        </div>
      </div>
    </>
  );
};

export default EventInformation;
