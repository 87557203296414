const ArrowLeft = ({ color, className }: ArrowLeftProps) => {
  if (color === "blue") {
    return (
      <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <path
          d="M9.375 12.188 4.687 7.5l4.688-4.688"
          stroke="#3974EB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <path
          d="M9.375 12.188 4.687 7.5l4.688-4.688"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

interface ArrowLeftProps {
  color?: string;
  className?: string;
}

export default ArrowLeft;
