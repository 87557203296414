import styled from "styled-components";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const Switch = ({ id, name, onChange, className }: SwitchProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  const { register, setValue, watch } = useFormContext();

  const selectActive = watch("active");

  const checkActive = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: inputValue },
    } = event;
    if (inputValue) {
      setValue("active", true);
      setChecked(true);
    } else {
      setValue("active", false);
      setChecked(false);
    }
  };

  useEffect(() => {
    if (selectActive) {
      setChecked(true);
      setValue("active", true);
    } else {
      setChecked(false);
      setValue("active", false);
    }
  }, [selectActive]);

  return (
    <SwitchContainer>
      <Input
        checked={checked}
        type="checkbox"
        id={id}
        onChange={checkActive}
        className={className}
      />
      <Circle checked={checked} />
    </SwitchContainer>
  );
};

interface SwitchProps {
  readonly id: string;
  readonly name: string;
  readonly onChange?: (e: any) => void;
  readonly className?: string;
}

interface CircleProps {
  readonly checked: boolean;
}

export default Switch;

const SwitchContainer = styled.div`
  display: flex;
  position: relative;
`;

const Input = styled.input`
  cursor: pointer;
  width: 52px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--grey300);
  &:checked {
    background-color: var(--green);
  }
`;

const Circle = styled.div<CircleProps>`
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04);
  transition: all 0.1s ease-in-out;
  ${(props) => {
    if (props.checked) {
      return `
            left: 26px;
            `;
    } else {
      return `
            left: 4px;
            `;
    }
  }}
`;
