import Loading from "app/components/loading/Loading";
import { handleGetAllBars } from "bars/controllers";
import useOutsideClick from "hooks/useOutsideClick";
import { handleGetAllMusicians } from "musicians/controllers";
import { useCallback, useMemo, useRef, useState } from "react";
import Button from "ui/components/button/Button";
import Label from "ui/components/label/Label";
import Search from "ui/icons/search/Search";
import { removeExtension } from "utils/mediaSizes";
import styles from "./OpenBarForm.module.scss";

type Props = {
  closeModal: Function;
  AppendBar: Function;
};

const OpenBarForm = ({ closeModal, AppendBar }: Props) => {
  const listMusiciansRef = useRef(null);

  const [addBarExist, setAddBarExist] = useState(false);
  const [bars, setBars] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [search, setSearch] = useState(null);
  const [barSelected, setBarSelected] = useState(null);

  const onClickOutsideIframe = useCallback(() => {
    setAddBarExist(false);
  }, []);

  useOutsideClick(listMusiciansRef, onClickOutsideIframe);

  function opengroupExist() {
    setAddBarExist(true);
  }

  function hideModal() {
    closeModal();
  }

  function selectBar(bar) {
    setBarSelected(bar);
    setSearch(bar.name);
  }

  function AppendBarToForm() {
    AppendBar(barSelected);
  }

  function getSearch(e) {
    const value = e.target.value;
    setSearch(value);
    setLoadingSearch(true);
    const filtersFinal = {
      search,
    };
    handleGetAllBars(filtersFinal)
      .then(function (response: any) {
        setBars(response.data);
        setAddBarExist(true);
        setLoadingSearch(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: "aie",
          error: true,
        };
      });
  }

  const renderBars = useMemo(() => {
    if (!bars || bars.length == 0) return null;
    const barsActive = bars.filter((x) => x.active == "1");
    return (
      <div className={styles.musicians}>
        {bars.map((bar) => {
          let picture;

          if (bar.medias.length > 0) {
            picture = (
              <div className={styles.picture}>
                <img src={removeExtension(bar.medias[0].url, "205x110")} />
              </div>
            );
          } else {
            picture = (
              <div className={styles.picture}>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                >
                  <path
                    opacity=".2"
                    d="M8.75 11.25h22.5L20 22.5 8.75 11.25Z"
                    fill="#A0AEC0"
                  />
                  <path
                    d="M3.75 6.25h32.5L20 22.5 3.75 6.25ZM20 22.5v11.25M13.75 33.75h12.5M8.75 11.25h22.5"
                    stroke="#A0AEC0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          }

          return (
            <li
              className={styles.musicianitem}
              key={Math.random().toString(36)}
            >
              <button
                className={styles.musicianButton}
                type={"button"}
                onClick={() => selectBar(bar)}
              >
                {picture}

                <div className={styles.infos}>
                  <p className={styles.name}>{bar.name}</p>
                  <p className={styles.location}>
                    {bar.town} ({bar.postal})
                  </p>
                </div>
              </button>
            </li>
          );
        })}
      </div>
    );
  }, [bars]);

  return (
    <>
      <div className={styles.popup}>
        <div className={styles.box}>
          <p className={styles.popupTitle}>Ajouter un bar</p>
          <div>
            <div className={styles.relative}>
              <Label isRequired={true} htmlFor="password">
                Nom du bar
              </Label>
              <div ref={listMusiciansRef}>
                <div className={styles.search}>
                  <input
                    value={search}
                    onChange={getSearch}
                    type={"text"}
                    placeholder={"Rechercher un bar ou un lieu"}
                  />
                  {loadingSearch ? (
                    <div className={styles.searchLoading}>
                      <Loading color={"green"} />
                    </div>
                  ) : (
                    <Search />
                  )}
                </div>
              </div>
              {addBarExist && renderBars}
            </div>

            <div className={styles.popupButtons}>
              <Button
                type={"button"}
                onClick={hideModal}
                bgColor="grey300"
                textColor="grey900"
              >
                Annuler
              </Button>
              <Button
                type={"button"}
                onClick={AppendBarToForm}
                bgColor="green"
                textColor="#fff"
              >
                Ajouter
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenBarForm;
