const Sofa = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="M4.375 7.438h5.25M2.625 5.25V3.937a1.75 1.75 0 0 1 1.75-1.75h5.25a1.75 1.75 0 0 1 1.75 1.75V5.25"
      stroke="#1A202C"
      strokeWidth=".875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 9.188V7a1.75 1.75 0 1 0-1.75 1.75v2.188a.438.438 0 0 0 .438.437h7.874a.438.438 0 0 0 .438-.438V8.75A1.75 1.75 0 1 0 9.625 7v2.188"
      stroke="#1A202C"
      strokeWidth=".875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Sofa;
