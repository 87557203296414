const Play = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="m21.778 10.716-13.5-8.241a1.453 1.453 0 0 0-1.51-.028A1.481 1.481 0 0 0 6 3.75v16.5a1.481 1.481 0 0 0 .769 1.303 1.453 1.453 0 0 0 1.51-.028l13.5-8.24a1.499 1.499 0 0 0 0-2.57Z"
      fill="#12ED94"
    />
  </svg>
);

export default Play;
