import styles from "./StyleSingle.module.scss";

type Props = {
  stylesList: any;
};

const StyleSingle = ({ stylesList }: Props) => {
  if (stylesList.length === 0)
    return <p className={"m-empty m-empty--nopadding"}>Aucun style</p>;
  return (
    <ul className={styles.main}>
      {stylesList.map((style, index) => (
        <li key={index} className={styles.item}>
          {style.name}
        </li>
      ))}
    </ul>
  );
};

export default StyleSingle;
