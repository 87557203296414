import Switch from "ui/components/switch/Switch";

import styles from "./HeaderForm.module.scss";

type Props = {
  title: any;
  label?: string;
};

const HeaderForm = ({ title, label }: Props) => {
  return (
    <div className={styles.main}>
      <p className={styles.title}>{title}</p>
      <div className={styles.buttons}>
        <label htmlFor="active">{label ? label : "Profil actif"}</label>
        <Switch id="active" name="active" />
      </div>
    </div>
  );
};

export default HeaderForm;
