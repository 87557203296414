import YoutubeMedia from "app/components/youtubeMedia/YoutubeMedia";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Button from "ui/components/button/Button";
import Label from "ui/components/label/Label";
import Camera from "ui/icons/camera/Camera";
import Youtube from "ui/icons/youtube/Youtube";
import { getVimeoId, getYoutubeId } from "utils/getYoutubeId";
import styles from "./VideoForm.module.scss";

const VideoForm = () => {
  const { watch } = useFormContext();
  const [videos, setVideos] = useState([]);
  const [youtubePopupOpen, setYoutubePopupOpen] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const { append, remove } = useFieldArray({
    name: "videos",
  });
  useEffect(() => {
    watch((value, { name, type }) => {
      setVideos(value.videos);
    });
  }, [watch]);

  const openYoutubeModal = () => {
    setYoutubePopupOpen(true);
    setYoutubeUrl("null");
  };
  function CloseYoutubeModal() {
    setYoutubePopupOpen(false);
  }

  function updateYoutubeUrl(e) {
    setYoutubeUrl(e.target.value);
  }

  function ConfirmYoutubeId() {
    if (youtubeUrl.includes("youtube")) {
      append({ youtube_id: "youtube_" + getYoutubeId(youtubeUrl) });
    } else {
      append({ youtube_id: "vimeo_" + getVimeoId(youtubeUrl) });
    }

    setVideos([...videos, getYoutubeId(youtubeUrl)]);
    setYoutubePopupOpen(false);
  }

  function deleteVideo(youtubeId) {
    const index = videos.findIndex((x) => x.youtube_id === youtubeId);
    remove(index);
    setVideos(videos.filter((x) => x !== youtubeId));
  }

  const renderVideos = useMemo(() => {
    if ((videos && videos.length == 0) || videos == undefined) return;
    return videos.map((video, index) => {
      return (
        <li key={index}>
          <YoutubeMedia video={video} index={index} onDelete={deleteVideo} />
        </li>
      );
    });
  }, [videos]);

  return (
    <div className={styles.main}>
      <p className={styles.subtitle}>
        <Youtube className={styles.subtitle_icon} />
        Vidéos Youtube
      </p>
      <ul className={styles.list}>
        {renderVideos}
        <li>
          <button
            onClick={openYoutubeModal}
            className={styles.add}
            type={"button"}
          >
            <Camera />
            <p>Ajouter une vidéo</p>
          </button>
        </li>
      </ul>
      {youtubePopupOpen && (
        <div className={styles.popup}>
          <div className={styles.box}>
            <p className={styles.popupTitle}>Ajouter une vidéo Youtube</p>
            <p className={styles.popupText}>
              <Label isRequired={true} htmlFor="confirm-password">
                Lien Youtube
              </Label>
              <input
                type={"text"}
                onChange={updateYoutubeUrl}
                className={"m-input"}
                placeholder={"Lien de la vidéo Youtube"}
              />
            </p>
            <div className={styles.popupButtons}>
              <Button
                type={"button"}
                onClick={CloseYoutubeModal}
                bgColor="grey300"
                textColor="grey900"
              >
                Annuler
              </Button>
              <Button
                type={"button"}
                onClick={ConfirmYoutubeId}
                bgColor="green"
                textColor="purple"
              >
                Ajouter
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoForm;
