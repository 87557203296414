const ToggleIcon = (props) => {
  if (props.active) {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <g
          clip-path="url(#a)"
          stroke="#079F62"
          stroke-width=".938"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M10.313 3.75H4.686a3.75 3.75 0 1 0 0 7.5h5.625a3.75 3.75 0 1 0 0-7.5Z" />
          <path d="M4.688 9.375a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h15v15H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <g
          clip-path="url(#a)"
          stroke-width=".938"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M10.313 3.75H4.686a3.75 3.75 0 1 0 0 7.5h5.625a3.75 3.75 0 1 0 0-7.5Z" />
          <path d="M4.688 9.375a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h15v15H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};

export default ToggleIcon;
