import { useMemo } from "react";
import ArrowOUtIcon from "ui/icons/ArrowOutIcon";

import File from "ui/icons/file/File";
import { getExternalUrl, removeExtension } from "utils/mediaSizes";

import styles from "./MediaSingle.module.scss";

type Props = {
  media: any;
  sizes: any;
};

const MediaSingle = ({ media, sizes }: Props) => {
  const renderMedia = useMemo(() => {
    if (media.subtype === "documents") {
      return (
        <>
          <a target="_blank" href={getExternalUrl(media.url)}>
            <File />
          </a>
        </>
      );
    } else {
      return (
        <a target="_blank" href={getExternalUrl(media.url)}>
          <img src={removeExtension(media.url, sizes)} />
        </a>
      );
    }
  }, [media]);

  return (
    <>
      <div className={styles.main}>
        <button type={"button"} className={styles.see}>
          <ArrowOUtIcon />
        </button>
        {renderMedia}
      </div>
      {media.subtype === "documents" && (
        <p className={styles.name}>{media.name}</p>
      )}
    </>
  );
};

export default MediaSingle;
