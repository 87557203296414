import styles from "./SubtitleForm.module.scss";

type Props = {
  text: any;
  icon: any;
};

const SubtitleForm = ({ text, icon }: Props) => {
  return (
    <p className={styles.subtitle}>
      {icon}
      {text}
    </p>
  );
};

export default SubtitleForm;
