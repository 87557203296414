
import { useEffect, useMemo, useState } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";

import Plus from "ui/icons/plus/Plus";


import OpenBarForm from "form/components/openBarForm/OpenBarForm";
import BarItem from "bars/components/bars/barItem/BarItem";

import styles from './UserBar.module.scss'


type Props = {
    barsEdit?: any;
    barsToDelete?: Function;
};

const UserBar = ({barsEdit, barsToDelete}: Props) => {
       
    const [modalBar, setModalBar] = useState(false);
    const [bars, setBars] = useState([]);
    const { append, remove } = useFieldArray({
        name: "bars", // unique name for your Field Array
    });

    function hideModalGroup() {
        setModalBar(false)
    }

    useEffect(() => {
        setBars(barsEdit)
    }, [barsEdit]);

    function AppendBarToForm(bar) {
        setModalBar(false)
        append({bar_id: bar.id});
        setBars([...bars, bar]);
    }

    function deleteBar(barId) {
        const index = bars.findIndex(x => x.id === barId);
        setBars(bars.filter(x => x.id !== barId));
        barsToDelete(barId);
        remove(index)
    }

    const renderGroups  = useMemo(() => {
        return bars.map(bar => {
            return (
                <div className={styles.item}>
                    <BarItem bar={bar} canDelete={true} onDelete={deleteBar}/>
                </div>
            )
        })
    }, [bars]);
   
    return (
        <>
            <div className={styles.list}>
                {renderGroups}
                <div className={styles.item}>
                <button className={styles.add} type={'button'} onClick={() => setModalBar(true)}><Plus/>Ajouter un bar</button>
                </div>
            </div>

            {modalBar &&
                <OpenBarForm closeModal={hideModalGroup} AppendBar={AppendBarToForm} />
            }   
        </>
    )
}

export default UserBar;