const Search = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="M6.797 11.719a4.922 4.922 0 1 0 0-9.844 4.922 4.922 0 0 0 0 9.844ZM10.277 10.277l2.848 2.848"
      stroke="#2D3748"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Search;
