const Cutlery = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="M4.594 1.75v2.188M4.594 6.344v5.906M6.125 1.75l.438 2.625a1.969 1.969 0 0 1-3.938 0l.438-2.625M11.156 8.75H8.094s.656-6.125 3.062-7v10.5"
      stroke="#1A202C"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Cutlery;
