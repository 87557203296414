import { Link, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import { handleCreateMusicians } from "musicians/controllers";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import { showModal } from "app/actions/modal";
import HeaderForm from "form/components/headerForm/HeaderForm";
import InformationMusician from "musicians/components/informationMusician/InformationMusician";
import DescriptionMusician from "musicians/components/descriptionMusician/DescriptionMusician";
import LocationMusician from "musicians/components/locationMusician/LocationMusician";
import Button from "ui/components/button/Button";
import FormationMusician from "musicians/components/formationMusician/FormationMusician";
import StylesForm from "form/components/stylesForm/StylesForm";
import MediaForm from "form/components/mediaForm/MediaForm";
import VideoForm from "form/components/videoForm/VideoForm";
import DocumentForm from "form/components/documentForm/DocumentForm";

import styles from "./CreateMusician.module.scss";
import Loading from "app/components/loading/Loading";
import SpotifyForm from "form/components/spotifyForm/SpotifyForm";
import SoundCloudForm from "form/components/soundCloudForm/SoundCloudForm";
import { errorsAPI } from "app/constants/errors";
import DeezerForm from "form/components/deezerForm/DeezerForm";

const CreateMusician = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [medias, setMedias] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return {
        active: false,
        negociation_open: false,
        places: [],
        composition: [],
        spotify: [],
        soundcloud: [],
        deezer: [],
        videos: [],
        medias: [],
        documents: [],
      };
    }, []),
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    setLoading(true);
    handleCreateMusicians(data)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Groupe/Musician créé avec succes",
          error: false,
        };

        setTimeout(() => {
          setLoading(false);
          dispatch(showModal(data));
          navigate("/groupes-et-musiciens");
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
  };

  const addMedia = (data) => {
    setMedias([...medias, data]);
  };

  const removeMedia = (mediaId) => {
    setMedias(medias.filter((x) => x.id !== mediaId));
  };

  const addDocument = (data) => {
    setDocuments([...documents, data]);
  };

  const removeDocument = (documentId) => {
    setDocuments(documents.filter((x) => x.id !== documentId));
  };

  return (
    <div className={styles.main}>
      <Link to="/groupes-et-musiciens" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Annuler
      </Link>
      <FormProvider {...methods}>
        <HeaderForm title={"Créer un groupe/musicien"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.head}>
              <p className={styles.title}>Profil du groupe/musicien</p>
            </div>
            <div className={styles.information}>
              <InformationMusician />
            </div>
            <div className={styles.description}>
              <DescriptionMusician />
            </div>
            <div className={styles.location}>
              <LocationMusician />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.head}>
              <p className={styles.title}>Formations</p>
            </div>
            <div className={styles.formations}>
              <FormationMusician />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.styles}>
              <StylesForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <MediaForm
                mediasFiles={medias}
                addMediasFiles={addMedia}
                deleteMediasFiles={removeMedia}
                typeFile={"groups"}
              />
              <VideoForm />
              <SpotifyForm />
              <SoundCloudForm />
              <DeezerForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <DocumentForm
                documentsFiles={documents}
                addDocumentsFiles={addDocument}
                deleteDocumentsFiles={removeDocument}
                typeFile={"groups"}
              />
            </div>
          </div>

          {/* https://open.spotify.com/track/1opwgKhF0hFFL5Hqb5lA8s?si=17d9ec8b84fa447f */}

          {/* <iframe src="https://open.spotify.com/embed/track/1opwgKhF0hFFL5Hqb5lA8s?si=17d9ec8b84fa447f?utm_source=generator&theme=0" width="100%" height="90" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe> */}

          {/* https://soundcloud.com/91666/romance?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing */}

          {/* <iframe width="100%" height="300" scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/91666&color=%23091218&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe> */}

          {/* https://www.deezer.com/us/track/1001004232 */}

          {/* <iframe title="deezer-widget" src="https://widget.deezer.com/widget/dark/track/1001004232" width="100%" height="300" allow="encrypted-media; clipboard-write"></iframe> */}

          <div className={styles.bottom}>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="purple"
              className={styles.save_button}
            >
              {loading ? <Loading /> : "Créer le groupe/musicien"}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateMusician;
