import { BarTypeSingleInformation } from "bars/types/single";
import moment from "moment";
import { useMemo } from "react";

import SingleListItem from "ui/components/single-list-item/SingleListItem";
import State from "ui/components/state/State";
import Switch from "ui/components/switch/Switch";
import Info from "ui/icons/info/Info";

import styles from "./BarSingleInformation.module.scss";

type Props = {
  information: BarTypeSingleInformation;
};

const BarSingleInformation = ({ information }: Props) => {
  const renderActiveClass = useMemo(() => {
    if (!information.active) return styles.active;
    return styles.active + " " + styles.green;
  }, [information.active]);

  //        "company_types": "collectivity",
  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Profil du lieu</p>
      </div>
      <div className={styles.subtitle}>
        <Info className={styles.subtitle_icon} />
        <p>Profil du lieu</p>
      </div>
      <ul className={styles.list}>
        <li>
          <SingleListItem listItemLabel="État">
            <p className={renderActiveClass}>
              <span></span>
              {information.active == 1 ? "Activé" : "Inactif"}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Date d’inscription">
            <p className={styles.text}>
              {moment(information.created_at).format("DD/MM/YYYY")}
            </p>
          </SingleListItem>
        </li>
        {information.company_types && (
          <li>
            <SingleListItem listItemLabel="Type d'établissement">
              <p className={styles.text}>{information.company_types}</p>
            </SingleListItem>
          </li>
        )}
        {information.address && (
          <li>
            <SingleListItem listItemLabel="Adresse">
              <p className={styles.text}>{information.address}</p>
            </SingleListItem>
          </li>
        )}
        {information.siret && (
          <li>
            <SingleListItem listItemLabel="Numéro de SIRET">
              <p className={styles.text}>{information.siret}</p>
            </SingleListItem>
          </li>
        )}

        <li>
          <SingleListItem listItemLabel="Négociation">
            <p className={styles.text}>
              {information.negociation_open
                ? "Ouvert à la négociation"
                : "Fermé à la négociation"}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Adresse e-mail">
            <p className={styles.text}>{information.email}</p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Numéro de téléphone">
            <p className={styles.text}>{information.phone}</p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Ne veut pas se faire contacter">
            <p className={styles.text}>
              {information.dont_want_contact ? "Oui" : "Non"}
            </p>
          </SingleListItem>
        </li>
      </ul>
    </>
  );
};

export default BarSingleInformation;
