const TextAlign = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M3.125 5.313h13.75M3.125 8.438h10M3.125 11.563h13.75M3.125 14.688h10"
      stroke="#718096"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TextAlign;
