import { useEffect, useMemo, useState } from "react";
import Spotify from "ui/icons/spotify/Spotify";
import { getSpotifyUrl } from "utils/getSpotifyId";

import { DeezerGenerator } from "embedgenerator";

import styles from "./MusicianSingleDeezer.module.scss";
import Deezer from "ui/icons/deezer/Deezer";

type Props = {
  sounds: any;
};

const MusicianSingleDeezer = ({ sounds }: Props) => {
  const [finalSound, setFinalSound] = useState([]);

  // useEffect(() => {
  //   sounds.map((sound) => {
  //     DeezerGenerator.embed(sound.url).then((url) => {
  //       console.log(url);
  //       const cloneSounds = [...finalSound, url];
  //       setFinalSound(cloneSounds);
  //     });
  //   });
  // }, [sounds]);

  // const renderSounds = useMemo(() => {
  //   if (finalSound && finalSound.length == 0)
  //     return <p className={"m-empty"}>Aucun son Deezer</p>;
  //   return finalSound.map((sound, index) => {
  //     return (
  //       <li key={index}>
  //         <iframe src={sound} />
  //       </li>
  //     );
  //   });
  // }, [finalSound]);
  const renderSounds = useMemo(() => {
    if ((sounds && sounds.length === 0) || sounds === undefined) {
      return <p className={"m-empty"}>Aucun son Deezer</p>;
    }
    return sounds.map((sound, index) => {
      const type = sound.url.split("/")[4];
      const id = sound.url.split("/")[5];
      const url = `https://widget.deezer.com/widget/dark/${type}/${id}`;
      console.log(url);
      return (
        <li key={index}>
          <iframe src={url} title={`${sound}-${index}`} height="200" />
        </li>
      );
    });
  }, [sounds]);

  return (
    <div className={styles.main}>
      <div className={styles.subtitle}>
        <Deezer className={styles.subtitle_icon} />
        <p>Deezer</p>
      </div>
      <div className={styles.list}>{renderSounds}</div>
    </div>
  );
};

export default MusicianSingleDeezer;
