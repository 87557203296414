const TagIcon = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M15.188 15.344 18.75 10l-3.563-5.344a.617.617 0 0 0-.523-.281H3.125A.625.625 0 0 0 2.5 5v10a.625.625 0 0 0 .625.625h11.54a.616.616 0 0 0 .523-.281v0Z"
      stroke="#718096"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TagIcon;
