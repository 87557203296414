import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import Login from "account/components/login/Login";
import ForgotPassword from "account/components/forgot-password/ForgotPassword";
import NewPassword from "account/components/new-password/NewPassword";
import Menu from "ui/components/menu/Menu";
import AllBars from "pages/bars/all-bars/Bars";
import AllMusicians from "pages/musicians/all-musicians/AllMusicians";
import AllUsers from "pages/user/all-users/AllUsers";

import SingleUser from "pages/user/single-user/SingleUser";
import EditUser from "pages/user/edit-user/EditUser";
import CreateUser from "pages/user/create-user/CreateUser";

import { ProtectedRoute } from "app/components/protectedRoutes/ProtectedRoutes";

import CreateBar from "pages/bars/create-bar/CreateBar";
import Bar from "pages/bars/single-bars/Bar";
import EditBar from "pages/bars/edit-bar/EditBar";

import Registration from "pages/user/registration/Registration";

import CreateMusician from "pages/musicians/create-musician/CreateMusician";
import SingleMusician from "pages/musicians/single-musician/SingleMusician";
import EditMusician from "pages/musicians/edit-musician/EditMusician";

import CreateEvent from "pages/events/create-events/CreateEvents";
import EditEvents from "pages/events/edit-events/EditEvents";
import SingleEvents from "pages/events/single-events/SingleEvents";
import AllEvents from "pages/events/all-events/AllEvents";
import { RedirectedRoutes } from "./redirectedRoutes/RedirectedRoutes";

const Layout = () => {
  return (
    <Router>
      <div className={"container"}>
        <Menu />
        <Routes>
          <Route
            path="/bars-et-lieux"
            element={
              <ProtectedRoute>
                <AllBars />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bars-et-lieux/ajouter"
            element={
              <ProtectedRoute>
                <CreateBar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bars-et-lieux/:id"
            element={
              <ProtectedRoute>
                <Bar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bars-et-lieux/:id/edit"
            element={
              <ProtectedRoute>
                <EditBar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groupes-et-musiciens"
            element={
              <ProtectedRoute>
                <AllMusicians />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groupes-et-musiciens/:id"
            element={
              <ProtectedRoute>
                <SingleMusician />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groupes-et-musiciens/:id/edit"
            element={
              <ProtectedRoute>
                <EditMusician />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groupes-et-musiciens/ajouter"
            element={
              <ProtectedRoute>
                <CreateMusician />
              </ProtectedRoute>
            }
          />
          <Route
            path="/utilisateurs"
            element={
              <ProtectedRoute>
                <AllUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/utilisateurs/single"
            element={
              <ProtectedRoute>
                <SingleUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/utilisateurs/edit"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/utilisateurs/nouvel-utilisateur"
            element={
              <ProtectedRoute>
                <CreateUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/evenements"
            element={
              <ProtectedRoute>
                <AllEvents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/evenements/ajouter"
            element={
              <ProtectedRoute>
                <CreateEvent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/evenements/:id"
            element={
              <ProtectedRoute>
                <SingleEvents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/evenements/:id/edit"
            element={
              <ProtectedRoute>
                <EditEvents />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      <Routes>
        <Route
          path="/compte/connexion"
          element={
            <RedirectedRoutes>
              <Login />
            </RedirectedRoutes>
          }
        />
        <Route path="/compte/inscription" element={<Registration />} />
        <Route
          path="/compte/mot-de-passe-oublie"
          element={<ForgotPassword />}
        />
        <Route path="/reset-password/:token" element={<NewPassword />} />

        <Route path="/" element={<Navigate to="/compte/connexion" replace />} />
      </Routes>
    </Router>
  );
};

export default Layout;
