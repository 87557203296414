import { MediaType } from "app/types/media";
import { deleteRequest, getExternalRequest, postRequest } from "utils/useApi";

export const handleCreateMedia = (media: MediaType) => {
  return postRequest("medias", media).then((response) => {
    return response.data;
  });
};

export const handleDeleteMedia = (mediaId: number) => {
  return deleteRequest("medias/" + mediaId).then((response) => {
    return response.data;
  });
};

export const handleCheckVideoExist = (youtubeId) => {
  return getExternalRequest(
    `https://gdata.youtube.com/feeds/api/videos/${youtubeId}?v=2&alt=json`
  ).then((response) => {
    return response;
  });
};
