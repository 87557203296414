import moment from "moment";
import { useMemo } from "react";

import { Link } from "react-router-dom";
import UserFill from "ui/icons/user-fill/UserFill";

import styles from "./MembersSingle.module.scss";

type Props = {
  members: any;
};

const MembersSingle = ({ members }: Props) => {
  const renderMembers = useMemo(() => {
    return members.map((member) => {
      const roleClass =
        member.role_name === "SUPER_ADMIN"
          ? styles["role"] + " " + styles["role--admin"]
          : styles["role"];
      return (
        <div key={Math.random().toString(36)} className={styles.item}>
          <div>
            <p className={roleClass}>
              {member.role_name === "SUPER_ADMIN" ? (
                <>
                  <UserFill /> Administrateur
                </>
              ) : (
                <>Membre</>
              )}
            </p>
            <p className={styles.name}>
              {member.genre} {member.firstname || member.user_firstname}{" "}
              {member.lastname}{" "}
            </p>
            <p className={styles.date}>
              {member.genre == "Mme" ? "Née " : "Né"} le{" "}
              {moment(member.birthdate).format("DD/MM/YYYY")}
            </p>
            <p className={styles.email}>{member.email || member.user_email}</p>
            <p className={styles.phone}>{member.phone}</p>
          </div>
          {member.user_id !== 0 && (
            <Link
              className={styles.link}
              target="_blank"
              to={`/utilisateurs/single?id=${member.id}`}
            >
              Voir le membre
            </Link>
          )}
        </div>
      );
    });
  }, [members]);

  return <div className={styles.main}>{renderMembers}</div>;
};

export default MembersSingle;
