import TextAlign from "ui/icons/text-align/TextAlign";

import styles from "./BarSingleDescription.module.scss";

type Props = {
    description: string;
};

const BarSingleDescription = ( {description}: Props) => {
   
    return (
        <>
           
            <div className={styles.subtitle}>
                <TextAlign className={styles.subtitle_icon} />
                <p>Description du lieu</p>
            </div>
            <div className={styles.text}> 
                {description}
            </div>
            
        </>
    )
}

export default BarSingleDescription;