import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";

import styles from "./PriceFormation.module.scss";

type Props = {
  index?: number;
  indexFormation: number;
  removePrice?: Function;
  price: any;
};

const PriceFormation = ({
  index,
  indexFormation,
  removePrice,
  price,
}: Props) => {
  const { register, setValue, watch } = useFormContext();

  const [duration, setDuration] = useState(price.duration);
  const [newPrice, setNewPrice] = useState(price.price);
  const remove = () => {
    removePrice(index);
  };

  function handleChangeTime(e) {
    setDuration(parseFloat(e.target.value));
    setValue(
      `composition.${indexFormation}.prices.${index}.duration`,
      parseFloat(e.target.value)
    );
  }

  function handleChangePrice(e) {
    setNewPrice(e.target.value.replace(/,/g, "."));
    setValue(
      `composition.${indexFormation}.prices.${index}.price`,
      parseFloat(e.target.value)
    );
  }

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.duration}>
          <Label htmlFor="duration" isRequired={true}>
            Durée (heure)
          </Label>

          <div className="m-select-box m-select-box--small">
            {" "}
            <select onChange={handleChangeTime}>
              {[...Array(24)].map((x, i) => {
                const index = i * 0.5;
                if (index > 0) {
                  return (
                    <option
                      selected={duration / 60 === index}
                      value={index * 60}
                    >
                      {index == 0.5 ? "" : index + " heure"}
                      {index >= 1.5 ? "s" : ""}{" "}
                      {index - Math.floor(index) !== 0 ? " 30 minutes" : ""}
                    </option>
                  );
                }
              })}
            </select>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15 15"
            >
              <path
                d="M12.188 5.625 7.5 10.313 2.812 5.625"
                stroke="#718096"
                stroke-width="1.1"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
        <div className={styles.price}>
          <Label htmlFor="price" isRequired={true}>
            Tarif (€)
          </Label>
          <input
            onChange={handleChangePrice}
            type={"text"}
            className={"m-input"}
            value={newPrice}
          />
        </div>
      </div>

      <button type={"button"} onClick={remove} className={styles.delete}>
        Supprimer
      </button>
    </div>
  );
};

export default PriceFormation;
