
import { getTokenFromCookie } from "middlewares/authorizations";
import { Navigate } from "react-router-dom";

export const RedirectedRoutes = ({ children }) => {
  const token = getTokenFromCookie();
  if (token) {
    return <Navigate to={"/bars-et-lieux"}  />;
  }
  return children;
};
