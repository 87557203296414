import BottleIcon from "ui/icons/BottleIcon";
import BurgerIcon from "ui/icons/BurgerIcon";
import Calendar from "ui/icons/calendar/Calendar";
import CheerIcon from "ui/icons/CheerIcon";
import Cutlery from "ui/icons/cutlery/Cutlery";
import PaiementCardIcon from "ui/icons/PaiementCardIcon";
import Person from "ui/icons/person/Person";
import Sofa from "ui/icons/sofa/Sofa";
import TagIcon from "ui/icons/tag-icon/TagIcon";

import styles from "./BarSingleCharacteristics.module.scss";

type Props = {
  caracteristics: any;
};

const BarSingleCharacteristics = ({ caracteristics }: Props) => {
  return (
    <div className={styles.main}>
      <div className={styles.subtitle}>
        <TagIcon className={styles.subtitle_icon} />
        <p>Caractéristiques</p>
      </div>
      <div className={styles.list}>
        {caracteristics.restaurant_snack && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <BurgerIcon />
              Restauration - Snacks
            </p>
          </li>
        )}
        {caracteristics.restaurant && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <Cutlery className={styles.tag_icon} />
              Restauration - Repas
            </p>
          </li>
        )}
        {caracteristics.seating_places && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <Sofa className={styles.tag_icon} />
              Places assises
            </p>
          </li>
        )}
        {caracteristics.standing_places && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <Person className={styles.tag_icon} />
              Places debout
            </p>
          </li>
        )}
        {caracteristics.free_concert && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <TagIcon className={styles.tag_icon} />
              Concerts gratuits
            </p>
          </li>
        )}
        {caracteristics.pmr && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <CheerIcon />
              Accès PMR (Personnes à mobilité réduite)
            </p>
          </li>
        )}
        {caracteristics.paiement && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <PaiementCardIcon />
              Paiement par carte bancaire
            </p>
          </li>
        )}
        {caracteristics.happy_hour && (
          <li className={styles.item}>
            <p className={styles.tag}>
              <BottleIcon />
              Happy Hour
            </p>
          </li>
        )}
        {/* {caracteristics.reservation_required && 
                    <li className={styles.item}>
                        <p className={styles.tag}>
                            <Calendar className={styles.tag_icon} />
                            Réservation obligatoire
                        </p>
                    </li>
                } */}
      </div>
    </div>
  );
};

export default BarSingleCharacteristics;
