const UserStroke = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="M7.5 9.375a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      stroke="#718096"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M1.816 12.656a6.562 6.562 0 0 1 11.368 0"
      stroke="#718096"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserStroke;
