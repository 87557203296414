import SingleListItem from "ui/components/single-list-item/SingleListItem";
import styles from "./BarSingleSubscription.module.scss";
import Info from "ui/icons/info/Info";
import { BarTypeSingleSubscription } from "bars/types/single";
import LogoMiniIcon from "ui/icons/LogoMiniIcon";
import { formatDateToUser } from "utils/formatDate";
import { timestampFormat } from "utils/timestampFormat";
import { Link } from "react-router-dom";
import { ArrowUpRight } from "phosphor-react";

type Props = {
  subscription: BarTypeSingleSubscription | null;
};

const BarSingleSubscription = ({ subscription }: Props) => {
  console.log(subscription);
  if (!subscription)
    return (
      <>
        <div className={styles.header}>
          <p className={styles.title}>Abonnement</p>
        </div>
        <div className={styles.subtitle}>
          <Info className={styles.subtitle_icon} />
          <p>Aucun abonnement</p>
        </div>
      </>
    );

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Abonnement</p>
        <a
          href={subscription.linkToStripePage}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Voir l’abonnement sur Stripe
          <ArrowUpRight />
        </a>
      </div>
      <ul className={styles.list}>
        <li>
          <SingleListItem listItemLabel="Statut">
            <p className={styles.text}>
              {subscription.active ? (
                <div className={styles.badge}>
                  <LogoMiniIcon color="purple" />
                  <p>Boost</p>
                </div>
              ) : (
                <div className={styles.badge}>
                  <LogoMiniIcon color="purple" />
                  <p>Pas Boost</p>
                </div>
              )}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Date d’abonnement">
            <p className={styles.text}>
              {timestampFormat(subscription.dateCreated)}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Périodicité">
            <p className={styles.text}>
              {subscription.periodicity === "yearly" ? "Annuelle" : "Mensuelle"}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Renouvellement automatique">
            <p className={styles.text}>
              {subscription.renewActive ? "Oui" : "Non"}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Prochain renouvellement">
            <p className={styles.text}>
              {timestampFormat(subscription.nextRenewal)}
            </p>
          </SingleListItem>
        </li>
      </ul>
    </>
  );
};

export default BarSingleSubscription;
