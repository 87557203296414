import MembersSingle from "app/components/membersSingle/MembersSingle";
import StyleSingle from "app/components/stylesSingle/StyleSingle";
import BarSingleCharacteristics from "bars/components/barSingle/barSingleCharacteristics/BarSingleCharacteristics";
import BarSingleDescription from "bars/components/barSingle/barSingleDescription/BarSingleDescription";
import BarSingleDocuments from "bars/components/barSingle/barSingleDocuments/BarSingleDocuments";
import BarSingleHours from "bars/components/barSingle/barSingleHours/BarSingleHours";
import BarSingleInformation from "bars/components/barSingle/barSingleInformation/BarSingleInformation";
import BarSingleMedia from "bars/components/barSingle/barSingleMedia/BarSingleMedia";
import BarSingleVideos from "bars/components/barSingle/barSingleVideos/BarSingleVideos";
import { handleGetBar } from "bars/controllers";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "ui/components/button/Button";
import SeeButton from "ui/components/see-button/SeeButton";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import LinkOut from "ui/icons/link-out/LinkOut";
import Pen from "ui/icons/pen/Pen";
import styles from "./Bar.module.scss";
import BarSingleSubscription from "bars/components/barSingle/barSingleSubscription/BarSingleSubscription";
import { ArrowUpRight } from "phosphor-react";

const Bar = () => {
  const { id } = useParams();
  const [bar, setBar] = useState(null);

  useEffect(() => {
    handleGetBar(id)
      .then(function (response: any) {
        setBar(response.data);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: "aie",
          error: true,
        };
      });
  }, []);

  if (!bar) return null;

  return (
    <div className={styles.main}>
      <Link to="/bars-et-lieux/" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Retour
      </Link>
      <div className={styles.header}>
        <p className={styles.title}>{bar.name}</p>
        <div className={styles.headerButtons}>
          {/* <Button bgColor="grey300" textColor="grey900">
                        <p>Afficher sur Zicozinc</p>
                        <LinkOut className={styles.linkout_icon} />
                    </Button> */}
          {/* <SeeButton /> */}
          <Link to={`/bars-et-lieux/${id}/edit`}>
            <Button bgColor="green" textColor="purple">
              <Pen className={styles.pen_icon} />
              <p>Modifier</p>
            </Button>
          </Link>
        </div>
      </div>
      <section className={styles.section}>
        <div className={styles.information}>
          <BarSingleInformation
            information={{
              active: bar.active,
              created_at: bar.created_at,
              address: `${bar.address}, ${bar.postal} ${bar.town}`,
              phone: bar.phone,
              siret: bar.siret,
              negociation_open: bar.negociation_open,
              email: bar.email,
              dont_want_contact: bar.dont_want_contact,
              company_types: bar.company_types,
            }}
          />
        </div>
        <div className={styles.description}>
          <BarSingleDescription description={bar.description} />
        </div>
        <div className={styles.hour}>
          <BarSingleHours hours={bar.open_hours[0]} />
        </div>
        <div className={styles.characteristics}>
          <BarSingleCharacteristics caracteristics={bar.caracteristics} />
        </div>
      </section>
      <section className={styles.section}>
        <BarSingleSubscription subscription={bar.subscriptionStripe} />
      </section>
      {bar.administrators && bar.administrators.length > 0 && (
        <section className={styles.section}>
          <div className={styles.subheader}>
            <p className={styles.subtitle}>Membres du staff</p>
          </div>
          <div className={styles.members}>
            <MembersSingle members={bar.administrators} />
          </div>
        </section>
      )}

      <section className={styles.section}>
        <div className={styles.subheader}>
          <p className={styles.subtitle}>Style et ligne éditoriale</p>
        </div>
        <div className={styles.medias}>
          <StyleSingle stylesList={bar.styles} />
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.subheader}>
          <p className={styles.subtitle}>Médias</p>
        </div>
        <div className={styles.medias}>
          <BarSingleMedia medias={bar.medias} />
        </div>
        <div className={styles.mvideosedias}>
          <BarSingleVideos videos={bar.videos} />
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.subheader}>
          <p className={styles.subtitle}>Documentation technique</p>
        </div>
        <div className={styles.medias}>
          <BarSingleDocuments documents={bar.documents} />
        </div>
      </section>
    </div>
  );
};

export default Bar;
