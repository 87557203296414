export function getYoutubeId(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length == 11 ? match[2] : false;
}

export function getVimeoId(url: string) {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url);
  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1];
  }
}

export function extractThumbnail(url: string) {
  if (url) {
    if (url.includes("youtube")) {
      const thumbnail = url.replace("youtube_", "");
      return `http://img.youtube.com/vi/${thumbnail}/sddefault.jpg`;
    } else if (url.includes("vimeo")) {
      const thumbnail = url.replace("vimeo_", "");
      return `https://vumbnail.com/${thumbnail}.jpg`;
    }
  } else {
    return "";
  }
}

export function getVideoIframeUrl(url: string) {
  if (url) {
    if (url.includes("youtube")) {
      const id = url.replace("youtube_", "");
      return `https://www.youtube.com/embed/${id}`;
    } else if (url.includes("vimeo")) {
      const id = url.replace("vimeo_", "");
      return `http://player.vimeo.com/video/${id}`;
    }
  } else {
    return "";
  }
}
