export const BLUES = "Blues";
export const FOLK = "Folk";
export const COUNTRY = "Country";
export const FUNK_DISCO = "Funk/Disco";
export const POP = "Pop";
export const RNB_SOUL = "R'n'B/Soul";
export const CHANSON = "Chanson française";
export const JAZZ = "Jazz";
export const METAL = "Metal";
export const CLASSIC_MUSIC = "Musique classique";
export const WORLDS_MUSIC = "Musique du monde";
export const ELECTRO_MUSIC = "Musique électronique";
export const LATINO_MUSIC = "Musique latine";
export const TRADITIONNAL_MUSIC = "Musiques traditionnelles";
export const RAP_MUSIC = "Rap/Hip-Hop";
export const REGGAE_MUSIC = "Reggae";
export const ROCK_MUSIC = "Rock";

export const CHANSON_ARRAY = ["Variété", "Musette"];

export const JAZZ_ARRAY = [
  "Classic jazz",
  "Free jazz",
  "Jazz manouche",
  "Jazz-rock",
  "Swing",
];

export const METAL_ARRAY = [
  "Black metal",
  "Death metal",
  "Heavy metal",
  "Metalcore",
  "Stoner rock",
];

export const CLASSIC_ARRAY = [
  "Chorale",
  "Musique de chambre",
  "Opéra",
  "Orchestre",
];

export const WORLDS_ARRAY = [
  "Musiques africaines",
  "Musiques antillaises",
  "Musiques arabes",
  "Musiques celtiques",
  "Musiques slaves/tziganes",
];

export const ELECTRO_ARRAY = [
  "Ambient",
  "Drum and bass",
  "Dub",
  "Electro",
  "House/Groove",
  "Techno",
  "Techno hardcore",
  "Trance",
  "UK Garage",
];

export const LATINO_ARRAY = [
  "Bossa nova",
  "Flamenco",
  "Reggaeton",
  "Salsa/Musiques cubaines",
  "Samba/Musiques brésiliennes",
  "Tango/Musiques argentines",
];

export const TRADITIONNAL_ARRAY = [
  "Musique alsacienne",
  "Musique auvergnate",
  "Musique basque",
  "Musique bretonne",
  "Musique corse",
  "Musique flamande",
  "Musique landaise",
  "Musique occitane",
];

export const RAP_ARRAY = [
  "Boom-bap",
  "Cloud rap",
  "Drill",
  "Grime",
  "Rap",
  "Trap",
];

export const REGGAE_ARRAY = ["Dub", "Ska"];

export const ROCK_ARRAY = [
  "Grunge/Punk",
  "Hard rock",
  "New wave/Post-punk",
  "Rock alternatif",
  "Rock indépendant",
  "Rock  progressif",
  "Rock’n’Roll",
];
