const Youtube = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M18.305 5.453a1.836 1.836 0 0 0-1.133-1.273C14.5 3.148 10.234 3.156 10 3.156c-.234 0-4.5-.008-7.172 1.024a1.836 1.836 0 0 0-1.133 1.273C1.492 6.227 1.25 7.648 1.25 10s.242 3.773.445 4.547a1.836 1.836 0 0 0 1.133 1.273c2.563.992 6.578 1.024 7.117 1.024h.11c.539 0 4.554-.032 7.117-1.024a1.836 1.836 0 0 0 1.133-1.273c.203-.774.445-2.195.445-4.547s-.242-3.773-.445-4.547Zm-5.633 4.805-3.75 2.5a.282.282 0 0 1-.172.055.351.351 0 0 1-.148-.04.304.304 0 0 1-.165-.273v-5a.305.305 0 0 1 .165-.273.313.313 0 0 1 .32.015l3.75 2.5a.304.304 0 0 1 0 .516Z"
      fill="#EB3223"
    />
  </svg>
);

export default Youtube;
