import Clock from "ui/icons/clock/Clock";
import HourBar from "./HourBar/HourBar";

import styles from "./HoursBar.module.scss";

const HoursBar = () => {
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  return (
    <>
      <p className={styles.subtitle}>
        <Clock className={styles.subtitle_icon} />
        Horaires d’ouverture
      </p>
      <div className={styles.hours}>
        <div className={styles.hoursWrapper}>
          {days.map((day) => (
            <HourBar key={Math.random().toString(36)} day={day} />
          ))}
        </div>
      </div>
    </>
  );
};

export default HoursBar;
