import {
  BLUES,
  CHANSON,
  CHANSON_ARRAY,
  CLASSIC_ARRAY,
  CLASSIC_MUSIC,
  ELECTRO_ARRAY,
  ELECTRO_MUSIC,
  FOLK,
  COUNTRY,
  FUNK_DISCO,
  JAZZ,
  JAZZ_ARRAY,
  LATINO_ARRAY,
  LATINO_MUSIC,
  METAL,
  METAL_ARRAY,
  POP,
  RAP_ARRAY,
  RAP_MUSIC,
  REGGAE_ARRAY,
  REGGAE_MUSIC,
  RNB_SOUL,
  ROCK_ARRAY,
  ROCK_MUSIC,
  TRADITIONNAL_ARRAY,
  TRADITIONNAL_MUSIC,
  WORLDS_ARRAY,
  WORLDS_MUSIC,
} from "bars/constants";
import { ChangeEvent, useState } from "react";
import Button from "ui/components/button/Button";
import Check from "ui/icons/check/Check";
import CloseIcon from "ui/icons/CloseIcon";

import styles from "./OpenStyleForm.module.scss";

type Props = {
  stylesForm?: any;
  closeModal?: Function;
  appendStyles: Function;
};

const OpenStyleForm = ({ stylesForm, closeModal, appendStyles }: Props) => {
  const [stylesSelected, setStylesSelected] = useState(stylesForm);

  function handleClose() {
    closeModal();
  }

  const checkActiveStyle0 = (event: ChangeEvent<HTMLInputElement>, name) => {
    const {
      target: { checked: inputValue },
    } = event;
    if (inputValue) {
      setStylesSelected([...stylesSelected, { name: name }]);
    } else {
      setStylesSelected(stylesSelected.filter((item) => item.name !== name));
    }
  };

  function checkInEdit(data) {
    return stylesSelected.filter((e) => e.name === data).length > 0;
  }

  function addStyles() {
    appendStyles(stylesSelected);
  }

  return (
    <div className={styles.popup}>
      <div className={styles.box}>
        <button onClick={handleClose} type={"button"} className={styles.close}>
          <CloseIcon />
        </button>
        <div className={styles.head}>
          <p className={styles.title}>Style et ligne éditoriale</p>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(BLUES)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, BLUES)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{BLUES}</p>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(FOLK)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, FOLK)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{FOLK}</p>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(COUNTRY)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, COUNTRY)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{COUNTRY}</p>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(FUNK_DISCO)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, FUNK_DISCO)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{FUNK_DISCO}</p>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(POP)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, POP)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{POP}</p>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(RNB_SOUL)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, RNB_SOUL)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{RNB_SOUL}</p>
        </div>

        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(CHANSON)}
            value={stylesForm}
            onChange={(e) => checkActiveStyle0(e, CHANSON)}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{CHANSON}</p>
        </div>
        <div className={styles.grid}>
          <>
            {CHANSON_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor="blues">{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(JAZZ)}
            onChange={(e) => checkActiveStyle0(e, JAZZ)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{JAZZ}</p>
        </div>
        <div className={styles.grid}>
          <>
            {JAZZ_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(METAL)}
            onChange={(e) => checkActiveStyle0(e, METAL)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{METAL}</p>
        </div>
        <div className={styles.grid}>
          <>
            {METAL_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(CLASSIC_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, CLASSIC_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{CLASSIC_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {CLASSIC_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(WORLDS_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, WORLDS_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{WORLDS_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {WORLDS_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(ELECTRO_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, ELECTRO_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{ELECTRO_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {ELECTRO_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>

        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(LATINO_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, LATINO_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{LATINO_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {LATINO_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(TRADITIONNAL_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, TRADITIONNAL_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{TRADITIONNAL_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {TRADITIONNAL_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(RAP_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, RAP_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{RAP_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {RAP_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>

        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(REGGAE_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, REGGAE_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{REGGAE_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {REGGAE_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>

        <div className={styles.checkboxsSingle}>
          <input
            checked={checkInEdit(ROCK_MUSIC)}
            onChange={(e) => checkActiveStyle0(e, ROCK_MUSIC)}
            value={stylesForm}
            type={"checkbox"}
          />
          <div className={styles.checkmark}>
            <Check className={styles.check_icon} />
          </div>
          <p>{ROCK_MUSIC}</p>
        </div>
        <div className={styles.grid}>
          <>
            {ROCK_ARRAY.map((style) => {
              return (
                <div className={styles.subCategory}>
                  <div className={styles.subCategoryLabel}>
                    <label htmlFor={style}>{style}</label>
                  </div>

                  <div className={styles.checkboxsItem}>
                    <input
                      checked={checkInEdit(style)}
                      onChange={(e) => checkActiveStyle0(e, style)}
                      value={stylesForm}
                      type={"checkbox"}
                    />
                    <div className={styles.checkmark}>
                      <Check className={styles.check_icon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className={styles.popupButtons}>
          <Button
            onClick={handleClose}
            type={"button"}
            bgColor="grey300"
            textColor="grey900"
          >
            Annuler
          </Button>
          <Button
            onClick={addStyles}
            type={"button"}
            bgColor="green"
            textColor="#fff"
          >
            Ajouter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OpenStyleForm;
