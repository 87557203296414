const BottleIcon = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      d="m11.375.875 1.75 1.75M11.813 1.313l-3.5 2.625-2.188.437-4.632 4.632a.88.88 0 0 0 0 1.236l2.264 2.264a.88.88 0 0 0 1.236 0l4.632-4.632.438-2.188 2.625-3.5M5.25 5.25l3.5 3.5M5.688 11.813l-3.5-3.5"
      stroke-width="1.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BottleIcon;
