import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PasswordChecklist from "react-password-checklist";

import Button from "ui/components/button/Button";
import Label from "ui/components/label/Label";

import styles from "./NewPassword.module.scss";
import Loading from "app/components/loading/Loading";
import { handleUpdatePassword } from "account/controllers/users";
import { passwordTrad } from "app/constants/traductions";

import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import Logo from "ui/icons/logo/Logo";

const NewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const password = useRef({});
  const { token } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  password.current = watch("password", "");
  register("token", { value: token });

  const onSubmit = (data) => {
    setLoading(true);
    handleUpdatePassword(data)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Votre mot de passe a bien été changé",
          error: false,
        };
        dispatch(showModal(data));
        setLoading(false);
        navigate("/compte/connexion");
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoading(false);
      });
  };
  return (
    <Wrapper>
      <ContentContainer>
        <div className={styles.logo}>
          <Logo />
        </div>

        <FormContainer>
          <Title>Créez un nouveau mot de passe</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.item}>
              <Label isRequired={true} htmlFor="new-password">
                Mot de passe
              </Label>
              <input
                type={"password"}
                {...register("password", {
                  required: true,
                  pattern:
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[~`¿¡!#$%\^&é*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])[A-Za-z\d~`¿¡!#$%\^&é*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,}$/,
                })}
                placeholder="Votre nouveau mot de passe"
                className={errors.password ? "m-input m-input--red" : "m-input"}
              />
              {watch("password") === "" ||
              watch("password") === undefined ||
              watch("password") === null ? (
                ""
              ) : (
                <PasswordChecklist
                  rules={["minLength", "specialChar", "number", "capital"]}
                  minLength={8}
                  value={watch("password")}
                  messages={passwordTrad}
                />
              )}
              {errors.password && errors.password.type === "required" && (
                <p className={"m-error"}>Un mot de passe est requis</p>
              )}
              {errors.password && errors.password.type === "pattern" && (
                <p className={"m-error"}>
                  Le mot de passe ne respecte pas les conditions
                </p>
              )}
            </div>
            <div className={styles.item}>
              <Label isRequired={true} htmlFor="confirm-password">
                Confirmer le mot de passe
              </Label>
              <input
                type={"password"}
                {...register("password_confirm", {
                  required: true,
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
                placeholder="Votre nouveau mot de passe"
                className={
                  errors.password_confirm ? "m-input m-input--red" : "m-input"
                }
              />
              {errors.password_confirm && (
                <p className={"m-error"}>
                  Vos mots de passe ne correspondent pas
                </p>
              )}
            </div>
            <Button bgColor="green" textColor="grey900" widthFull={true}>
              {loading ? <Loading /> : "Connexion"}
            </Button>
          </form>
        </FormContainer>
      </ContentContainer>
      <Image
        src="https://res.cloudinary.com/dvi4nelfa/image/upload/v1655470045/uxer/zicozinc/reset_password.png"
        alt=""
        aria-hidden="true"
      />
    </Wrapper>
  );
};

export default NewPassword;

const Wrapper = styled.main`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const ContentContainer = styled.div`
  position: relative;
  padding: 48px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    padding: 0;
    width: 50%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    max-width: 384px;
  }
`;

const Image = styled.img`
  display: none;
  width: 50%;
  height: 100vh;
  object-fit: cover;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--grey900);
  margin-bottom: 32px;
`;
