import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { formatPhoneNumber } from "utils/formatPhoneNumber";

import { handleRegistration } from "account/controllers/users";
import { showModal } from "app/actions/modal";

import fr from "date-fns/locale/fr";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import Label from "ui/components/label/Label";
import Button from "ui/components/button/Button";
import Eye from "ui/icons/eye/Eye";
import { formatDateToApi } from "utils/formatDate";
import { errorsAPI } from "app/constants/errors";

import styles from "./Registration.module.scss";

const Registration = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const { register, handleSubmit, watch, reset, setValue } = methods;

  registerLocale("fr", fr);

  const [passwordInputType, setPasswordInputType] = useState("password");
  const [birthdate, setBirthdate] = useState<Date>();
  const [phoneNumber, setPhoneNumber] = useState("");

  const changePasswordInputType = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const onSubmit = (data) => {
    handleRegistration(data)
      .then(function (response: any) {
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
  };

  useEffect(() => {
    const dateFormat = formatDateToApi(birthdate);
    setValue("birthdate", dateFormat);
  }, [birthdate, setValue, watch]);

  useEffect(() => {
    let defaultValues: any = {};
    defaultValues.role_name = "USER";
    defaultValues.active = true;
    reset({ ...defaultValues });
  }, []);

  useEffect(() => {
    setValue("phone", phoneNumber);
  }, [phoneNumber, setValue]);

  return (
    <main className={styles.main}>
      <div className={styles.content_wrapper}>
        <div className={styles.form_container}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.input_wrapper}>
              <Label isRequired={true} htmlFor="email">
                Adresse email
              </Label>
              <input
                id="email"
                type="email"
                name="email"
                required={true}
                placeholder="Votre adresse email"
                className={"m-input"}
                {...register("email")}
              />
            </div>
            <div className={styles.input_wrapper}>
              <Label isRequired={true} htmlFor="password">
                Mot de passe
              </Label>
              <div className={styles.password_wrapper}>
                <input
                  id="password"
                  type={passwordInputType}
                  name="password"
                  required={true}
                  placeholder="Votre mot de passe"
                  className={"m-input"}
                  {...register("password")}
                />
                <button
                  type="button"
                  className={styles.eye_icon}
                  onClick={changePasswordInputType}
                ></button>
              </div>
            </div>
            <div className={styles.input_flex}>
              <div className={styles.input_wrapper}>
                <Label isRequired={true} htmlFor="genre">
                  Genre
                </Label>
                <select
                  id="genre"
                  name="genre"
                  required={true}
                  className={"m-select"}
                  {...register("genre")}
                >
                  <option value="Mr">Monsieur</option>
                  <option value="Mme">Madame</option>
                  <option value="Mlle">Mademoiselle</option>
                </select>
              </div>
              <div className={styles.input_wrapper}>
                <Label isRequired={true} htmlFor="birthdate">
                  Date de naissance
                </Label>
                <ReactDatePicker
                  dateFormat={"dd/MM/yyyy"}
                  locale="fr"
                  selected={birthdate}
                  required={true}
                  onChange={(value) => {
                    setBirthdate(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.input_wrapper}>
              <Label isRequired={true} htmlFor="firstname">
                Prénom
              </Label>
              <input
                id="firstname"
                type="text"
                name="firstname"
                required={true}
                placeholder="Votre prénom"
                className={"m-input"}
                {...register("firstname")}
              />
            </div>
            <div className={styles.input_wrapper}>
              <Label isRequired={true} htmlFor="lastname">
                Nom
              </Label>
              <input
                id="lastname"
                type="text"
                name="lastname"
                required={true}
                placeholder="Votre nom"
                className={"m-input"}
                {...register("lastname")}
              />
            </div>
            <div className={styles.input_wrapper}>
              <Label isRequired={true} htmlFor="phone">
                Numéro de téléphone
              </Label>
              <input
                id="phone"
                type="tel"
                name="phone"
                required={true}
                placeholder="Votre Numéro de téléphone"
                className={"m-input"}
                onChange={(e) => {
                  setPhoneNumber(formatPhoneNumber(e.target.value));
                }}
                value={phoneNumber}
              />
            </div>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="grey900"
              widthFull={true}
            >
              S'inscrire
            </Button>
          </form>
        </div>
      </div>
      <img
        src="https://res.cloudinary.com/dvi4nelfa/image/upload/v1655408410/uxer/zicozinc/login.png"
        alt=""
        aria-hidden="true"
        className={styles.illustration}
      />
    </main>
  );
};

export default Registration;
