const Deezer = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d="M20 3.744h-4.325v2.53H20v-2.53Z" fill="#40AB5D" />
    <path d="M20 7.252h-4.325v2.53H20v-2.53Z" fill="url(#a)" />
    <path d="M20 10.76h-4.325v2.53H20v-2.53Z" fill="url(#b)" />
    <path d="M4.325 14.268H0v2.53h4.325v-2.53Z" fill="url(#c)" />
    <path d="M9.546 14.268H5.222v2.53h4.324v-2.53Z" fill="url(#d)" />
    <path d="M14.778 14.268h-4.324v2.53h4.324v-2.53Z" fill="url(#e)" />
    <path d="M20 14.268h-4.325v2.53H20v-2.53Z" fill="url(#f)" />
    <path d="M14.778 10.76h-4.324v2.53h4.324v-2.53Z" fill="url(#g)" />
    <path d="M9.546 10.76H5.222v2.53h4.324v-2.53Z" fill="url(#h)" />
    <path d="M9.546 7.252H5.222v2.53h4.324v-2.53Z" fill="url(#i)" />
    <defs>
      <linearGradient
        id="a"
        x1="17.859"
        y1="9.804"
        x2="17.818"
        y2="7.215"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#358C7B" />
        <stop offset=".526" stopColor="#33A65E" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="15.612"
        y1="13.16"
        x2="20.065"
        y2="10.891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222B90" />
        <stop offset="1" stopColor="#367B99" />
      </linearGradient>
      <linearGradient
        id="c"
        x1=".001"
        y1="15.534"
        x2="4.322"
        y2="15.534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F90" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="5.226"
        y1="15.534"
        x2="9.547"
        y2="15.534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8000" />
        <stop offset="1" stopColor="#CC1953" />
      </linearGradient>
      <linearGradient
        id="e"
        x1="10.452"
        y1="15.534"
        x2="14.773"
        y2="15.534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC1953" />
        <stop offset="1" stopColor="#241284" />
      </linearGradient>
      <linearGradient
        id="f"
        x1="15.673"
        y1="15.534"
        x2="19.994"
        y2="15.534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222B90" />
        <stop offset="1" stopColor="#3559A6" />
      </linearGradient>
      <linearGradient
        id="g"
        x1="10.282"
        y1="12.707"
        x2="14.944"
        y2="11.344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC1953" />
        <stop offset="1" stopColor="#241284" />
      </linearGradient>
      <linearGradient
        id="h"
        x1="5.073"
        y1="11.183"
        x2="9.701"
        y2="12.868"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".003" stopColor="#FC0" />
        <stop offset="1" stopColor="#CE1938" />
      </linearGradient>
      <linearGradient
        id="i"
        x1="5.59"
        y1="6.859"
        x2="9.184"
        y2="10.176"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".003" stopColor="#FFD100" />
        <stop offset="1" stopColor="#FD5A22" />
      </linearGradient>
    </defs>
  </svg>
);

export default Deezer;
