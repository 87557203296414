import moment from "moment";

export const formatDateToApi = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

export const formatDateToUser = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

export const stringToDateFormat = (value): Date => {
  //   return moment(value, "dd/MM/yyyy").toDate();
  return new Date(value);
};
