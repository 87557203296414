import styled from "styled-components";

import ArrowDown from "ui/icons/arrow-down/ArrowDown";

import styles from "./SelectButton.module.scss";

const SelectButton = ({
  children,
  onClick,
  className,
  styleType = "fill",
}: SelectButtonProps) => {
  return (
    <Button onClick={onClick} className={className} styleType={styleType}>
      {children}
      <ArrowDown className={styles.icon} />
    </Button>
  );
};

interface SelectButtonProps {
  readonly children: React.ReactNode;
  readonly onClick: () => void;
  readonly className?: string;
  readonly styleType?: "fill" | "outline";
}

interface ButtonProps {
  readonly styleType: "fill" | "outline";
}

export default SelectButton;

const Button = styled.button<ButtonProps>`
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--grey900);
  background-color: ${({ styleType }) =>
    styleType === "fill" ? "var(--grey300)" : "#fff"};
  border: ${({ styleType }) =>
    styleType === "outline" ? "1px solid var(--grey400)" : "none"};
`;
