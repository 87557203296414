const Info = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M10 17.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
      stroke="#718096"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 9.375H10v4.375h.625"
      stroke="#718096"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.844 7.5a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z"
      fill="#718096"
    />
  </svg>
);

export default Info;
