import { useDispatch } from "react-redux";

import Download from "ui/icons/download/Dowload";
import { handleGetExport } from "form/controllers";

import { showModal } from "app/actions/modal";

import styles from "./ExportsForm.module.scss";
import { errorsAPI } from "app/constants/errors";

type Props = {
    type: string;
};

const ExportsForm = ({type} : Props) => {
    const dispatch = useDispatch();
    function exportCSV() {
        handleGetExport(type).then(function (response: any) {
            window.location.href = process.env.REACT_APP_API + '/' + response.data.data;
            if(response) {
                const data = {
                status: true,
                message: "CSV généré",
                error: false
                }
                dispatch(showModal(data));
            }
        }).catch(err => {
            const data = {
                status: true,
                message:  errorsAPI[err.response.data.message],
                error: true
            }
            dispatch(showModal(data));
       });
    }
 
    return (
        <button onClick={exportCSV} type={'button'} className={styles.main}>
            <Download className={styles.download_icon} />
            <p>Exporter la liste en .CSV</p>
        </button>
    )
}

export default ExportsForm;