const Check = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
  >
    <path
      d="m13 5.677-5.646 5.646L4.531 8.5"
      stroke="#352545"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
