import { handleAccountMe } from "account/controllers/users";
import { getTokenFromCookie } from "middlewares/authorizations";
import { destroyCookie } from "nookies";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = getTokenFromCookie();

  useEffect(() => {
    handleAccountMe().then((res) => {
      if (res.data.data.role_name !== "SUPER_ADMIN") {
        destroyCookie(null, "token", { path: "/" });
        window.location.href = "https://zicozinc.com";
      }
    });
  }, [navigate, token]);

  if (!token) {
    return <Navigate to={"/compte/connexion"} />;
  }
  return children;
};
