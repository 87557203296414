import { useEffect, useMemo } from "react";

import styles from "./Toast.module.scss";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "app/redux/store";

import { showModal } from "app/actions/modal";
import WarningCircle from "ui/icons/warning-circle/WarningCircle";
import CheckCircle from "ui/icons/check-circle/CheckCircle";
const Toast = () => {
  const toast = useSelector((state: RootState) => state.toast.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toast && toast.status)
      var timer = setTimeout(() => {
        dispatch(showModal(null));
      }, 3000);

    return () => clearTimeout(timer);
  }, [toast]);

  const renderIcon = useMemo(() => {
    if (toast && toast.error) return <WarningCircle />;
    return <CheckCircle />;
  }, [toast]);

  const renderToast = useMemo(() => {
    if (!toast) return null;
    return (
      <div className={styles.main}>
        {renderIcon}
        {toast.message}
      </div>
    );
  }, [toast]);

  return <>{renderToast}</>;
};

export default Toast;
