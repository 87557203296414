import { Link } from "react-router-dom";
import {
  handleGetAllUsers,
  handleGetUsersByFilters,
} from "account/controllers/users";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import State from "ui/components/state/State";
import ArrowRight from "ui/icons/arrow-right/ArrowRight";
import Download from "ui/icons/download/Dowload";
import styles from "./AllUsers.module.scss";
import Plus from "ui/icons/plus/Plus";
import Search from "ui/icons/search/Search";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import { showModal } from "app/actions/modal";
import Loading from "app/components/loading/Loading";
import ExportsForm from "form/components/exportsForm/ExportsForm";
import { errorsAPI } from "app/constants/errors";
import useOutsideClick from "hooks/useOutsideClick";
import Calendar from "ui/icons/calendar/Calendar";
import SortAscending from "ui/icons/sort-ascending/SortAscending";
import Button from "ui/components/button/Button";
import moment from "moment";
import { XCircle } from "phosphor-react";
import AllEventsDatepicker from "events/components/allEventsDatepicker/AllEventsDatepicker";

const AllUsers = () => {
  const dispatch = useDispatch();
  const sortRef = useRef(null);
  const dateFilterRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [sort, setSort] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOpen, setSortOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [createdStartDate, setCreatedStartDate] = useState<Date | null>(null);
  const [createdEndDate, setCreatedEndDate] = useState<Date | null>(null);

  useEffect(() => {
    console.log(users);
  }, [users]);

  const onClickOutsideSort = useCallback(() => {
    setSortOpen(false);
  }, []);

  const onClickOutsideDateFilter = useCallback(() => {
    setDateFilterOpen(false);
  }, []);

  useOutsideClick(dateFilterRef, onClickOutsideDateFilter);
  useOutsideClick(sortRef, onClickOutsideSort);

  const openDateFilter = () => {
    setDateFilterOpen(true);
  };

  const resetDateFilter = () => {
    setCreatedStartDate(null);
    setCreatedEndDate(null);
    setPage(1);
  };

  useEffect(() => {
    if (
      searchValue !== "" ||
      sort !== "" ||
      createdEndDate ||
      createdStartDate
    ) {
      const form = {
        search: searchValue,
        orderBy: sort,
        page,
        limit,
        createdStartDate,
        createdEndDate,
      };
      handleGetUsersByFilters(form)
        .then(function (response: any) {
          setLoading(false);
          setLoadingSearch(false);
          if (page > 1) {
            setUsers((users) => [...users, ...response.data]);
          } else {
            setUsers(response.data);
          }
          if (response.data.length < limit) {
            setShowMore(false);
          } else {
            setShowMore(true);
          }
        })
        .catch((err) => {
          const data = {
            status: true,
            message: errorsAPI[err.response.data.message],
            error: true,
          };
          dispatch(showModal(data));
        });
    } else {
      const form = {
        page,
        limit,
      };
      handleGetAllUsers(form)
        .then(function (response: any) {
          setLoading(false);
          setLoadingSearch(false);
          if (page > 1) {
            setUsers((users) => [...users, ...response.data]);
          } else {
            setUsers(response.data);
          }
          if (response.data.length < limit) {
            setShowMore(false);
          } else {
            setShowMore(true);
          }
        })
        .catch((err) => {
          const data = {
            status: true,
            message: errorsAPI[err.response.data.message],
            error: true,
          };
          dispatch(showModal(data));
        });
    }
  }, [
    searchValue,
    sort,
    page,
    limit,
    createdStartDate,
    createdEndDate,
    dispatch,
  ]);

  function handleMore() {
    setPage((page) => page + 1);
  }

  function getSearch(e) {
    const value = e.target.value;
    setSearchValue(value);
    setLoadingSearch(true);
    setPage(1);
  }

  function openSort() {
    setSortOpen(true);
  }

  const renderSort = useMemo(() => {
    if (sort == "") return "Trier par";
    if (sort == "date") return "Trier par date d'inscription";
    if (sort == "alphabetic") return "Trier par ordre alphabétique";
  }, [sort]);

  function sortBy(type) {
    if (type == sort) {
      setSort("");
    } else {
      setSort(type);
    }
    setPage(1);
  }

  const renderMore = useMemo(() => {
    if (!showMore) return null;
    return (
      <div onClick={handleMore} className={styles.more}>
        <Button bgColor="grey300" textColor="purple">
          Charger plus d'utilisateurs
        </Button>
      </div>
    );
  }, [showMore]);

  const renderDateFilter = () => {
    if (createdStartDate === null || createdEndDate === null) {
      return "Date d’inscription";
    } else {
      const formattedStartDate = moment(createdStartDate).format("DD/MM/YYYY");
      const formattedEndDate = moment(createdEndDate).format("DD/MM/YYYY");
      return `${formattedStartDate} au ${formattedEndDate}`;
    }
  };

  return (
    <main className={styles.main}>
      <header className={styles.header}>
        <h1 className={styles.title}>Liste des utilisateurs</h1>
        <ExportsForm type={"users"} />
      </header>
      <div className={styles.filters_wrapper}>
        <div ref={sortRef} className={styles.sort}>
          <button className={styles.filterButton} onClick={openSort}>
            {renderSort}
            <ArrowDown />
          </button>
          {sortOpen && (
            <ul>
              <li>
                <button
                  className={sort === "date" ? styles.active : ""}
                  onClick={() => sortBy("date")}
                  type={"button"}
                >
                  <Calendar />
                  Par date d’inscription
                </button>
              </li>
              <li>
                <button
                  className={sort === "alphabetic" ? styles.active : ""}
                  onClick={() => sortBy("alphabetic")}
                  type={"button"}
                >
                  <SortAscending />
                  Par ordre alphabétique
                </button>
              </li>
            </ul>
          )}
          <div className={styles.dateFilter} ref={dateFilterRef}>
            <button
              type={"button"}
              className={styles.dateFilterButton}
              style={
                createdStartDate !== null && createdEndDate !== null
                  ? { paddingRight: "39px" }
                  : {}
              }
              onClick={openDateFilter}
            >
              <Calendar />
              {renderDateFilter()}
            </button>
            {createdStartDate !== null && createdEndDate !== null && (
              <button
                className={styles.resetDateFilter}
                onClick={resetDateFilter}
              >
                <XCircle weight="fill" />
              </button>
            )}
            {dateFilterOpen && (
              <div className={styles.dateFilterBox}>
                <AllEventsDatepicker
                  startDate={createdStartDate}
                  setStartDate={setCreatedStartDate}
                  endDate={createdEndDate}
                  setEndDate={setCreatedEndDate}
                  setDateFilterOpen={setDateFilterOpen}
                  setPage={setPage}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.search_wrapper}>
          <div className={styles.search_input_wrapper}>
            <input
              id="text"
              type="text"
              name="search-bar"
              placeholder="Rechercher"
              className={"m-input m-input--grey"}
              onChange={getSearch}
              value={searchValue}
            />
            {loadingSearch ? (
              <div className={styles.searchLoading}>
                <Loading color={"green"} />
              </div>
            ) : (
              <Search />
            )}
          </div>
          <Link to="/utilisateurs/nouvel-utilisateur" className={styles.add}>
            <Plus className={styles.add__icon} />
            <p>Ajouter un utilisateur</p>
          </Link>
        </div>
      </div>
      <div>
        <div className={styles.table_head}>
          <p>Nom</p>
          <p>Prénom</p>
          <p>Adresse email</p>
          <p>Date d'inscription</p>
          <p>État</p>
        </div>
        <div className={styles.table_body}>
          {users &&
            users.map((user) => (
              <Link key={user.id} to={`/utilisateurs/single?id=${user.id}`}>
                <div className={styles.table_item}>
                  <p className={styles.table_text}>{user.lastname}</p>
                  <p className={styles.table_text}>{user.firstname}</p>
                  <p className={styles.table_text}>{user.email}</p>
                  <p className={styles.table_text}>
                    {moment(user.created_at).format("DD/MM/YYYY")}
                  </p>
                  <State className={styles.user_state} active={user.active} />
                  <ArrowRight className={styles.table_arrow} />
                </div>
              </Link>
            ))}
        </div>
        {loading && (
          <div className={styles.loading}>
            <Loading rounded={true} />
          </div>
        )}
      </div>
      {renderMore}
    </main>
  );
};

export default AllUsers;
