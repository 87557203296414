import { useFormContext } from "react-hook-form";
import BottleIcon from "ui/icons/BottleIcon";
import BurgerIcon from "ui/icons/BurgerIcon";
import Calendar from "ui/icons/calendar/Calendar";
import Check from "ui/icons/check/Check";
import CheerIcon from "ui/icons/CheerIcon";
import Cutlery from "ui/icons/cutlery/Cutlery";
import LabelIcon from "ui/icons/label-icon/LabelIcon";
import PaiementCardIcon from "ui/icons/PaiementCardIcon";
import Person from "ui/icons/person/Person";
import Sofa from "ui/icons/sofa/Sofa";
import TagIcon from "ui/icons/tag-icon/TagIcon";

import styles from "./CharacteristicsBar.module.scss";

const CharacteristicsBar = () => {
  const { register } = useFormContext();

  return (
    <>
      <p className={styles.subtitle}>
        <TagIcon className={styles.subtitle_icon} />
        Caractéristiques
      </p>
      <div className={styles.grid}>
        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <span className={styles.stroke}>
              <BurgerIcon />
            </span>

            <label htmlFor="restaurant_snack">Restauration - Snack</label>
          </div>

          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.restaurant_snack")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>

        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <Cutlery />
            <label htmlFor="restaurant">Restauration - Repas</label>
          </div>

          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.restaurant")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>

        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <Person />
            <label htmlFor="standing_places">Places debout</label>
          </div>
          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.standing_places")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>
        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <Sofa />
            <label htmlFor="seating_places">Places assises</label>
          </div>
          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.seating_places")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>
        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <LabelIcon />
            <label htmlFor="free_concert">Concerts gratuits</label>
          </div>
          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.free_concert")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>
        {/* <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <Calendar />
            <label htmlFor="reservation_required">
              Réservation obligatoire
            </label>
          </div>
          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.reservation_required")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div> */}
        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <span className={styles.stroke}>
              <CheerIcon />
            </span>

            <label htmlFor="pmr">
              Accès PMR (Personnes à mobilité réduite)
            </label>
          </div>
          <div className={styles.checkboxsItem}>
            <input {...register("caracteristics.pmr")} type={"checkbox"} />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>
        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <span className={styles.stroke}>
              <PaiementCardIcon />
            </span>
            <label htmlFor="paiement">Paiement par carte bancaire</label>
          </div>
          <div className={styles.checkboxsItem}>
            <input {...register("caracteristics.paiement")} type={"checkbox"} />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>
        <div className={styles.subCategory}>
          <div className={styles.subCategoryLabel}>
            <span className={styles.stroke}>
              <BottleIcon />
            </span>
            <label htmlFor="happy_hour">Happy Hour</label>
          </div>
          <div className={styles.checkboxsItem}>
            <input
              {...register("caracteristics.happy_hour")}
              type={"checkbox"}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CharacteristicsBar;
