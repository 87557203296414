const UserFill = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 11"
  >
    <path
      d="M9.964 9.11a5.186 5.186 0 0 0-2.883-2.33 3.094 3.094 0 1 0-3.162 0 5.186 5.186 0 0 0-2.884 2.33.33.33 0 0 0 0 .343.335.335 0 0 0 .297.172h8.336a.335.335 0 0 0 .296-.172.33.33 0 0 0 0-.344Z"
      fill="#352545"
    />
  </svg>
);

export default UserFill;
