import { hoursFr } from "app/constants/hours";
import { useMemo } from "react";

import Clock from "ui/icons/clock/Clock";
import BarSingleHour from "./barSingleHour/BarSingleHour";
import styles from "./BarSingleHours.module.scss";

type Props = {
  hours: any;
};

const BarSingleHours = ({ hours }: Props) => {
  const renderDays = useMemo(() => {
    return Object.keys(hoursFr).map((key, i) => {
      return (
        <div key={Math.random().toString(36)} className={styles.item}>
          <p className={styles.label}>{hoursFr[key].label}</p>
          <BarSingleHour hours={hours[key]} />
        </div>
      );
    });
  }, [hours]);

  return (
    <>
      <div className={styles.subtitle}>
        <Clock className={styles.subtitle_icon} />
        <p>Horaires d’ouverture</p>
      </div>
      <div className={styles.list}>{renderDays}</div>
    </>
  );
};

export default BarSingleHours;
