import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from "react";
import moment from "moment";

import { showModal } from "app/actions/modal";
import {
  handleModifyUser,
  handleGetUserById,
  handleaddGroupToUser,
  handledeleteGroupToUser,
  handleGetGroupByUser,
  handleaddBarToUser,
  handledeleteBarToUser,
} from "account/controllers/users";
import useQuery from "hooks/useQuery";

import WhiteSection from "ui/components/white-section/WhiteSection";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import Label from "ui/components/label/Label";
import Button from "ui/components/button/Button";
import Switch from "ui/components/switch/Switch";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import UserGroup from "users/components/userGroup/UserGroup";
import UserBar from "users/components/userBar/UserBar";
import Loading from "app/components/loading/Loading";

import styles from "./EditUser.module.scss";
import { errorsAPI } from "app/constants/errors";
import HeaderForm from "form/components/headerForm/HeaderForm";

const EditUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);

  const [groups, setGroups] = useState([]);
  const [bars, setBars] = useState([]);
  const [groupsTeDelete, setGroupsToDelete] = useState([]);
  const [barsToDelete, setBarsToDelete] = useState([]);

  const [barsLink, setBarsLink] = useState([]);
  const [groupsLink, setGroupsLink] = useState([]);

  const query = useQuery();
  const id = query.get("id");
  const dispatch = useDispatch();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const onSubmit = (data) => {
    setLoadingValidate(true);
    let finalData = {
      email: data.email,
      password: data.password,
      role_name: data.role_name,
      genre: data.genre,
      birthdate: data.birthdate != "" ? data.birthdate : null,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      active: data.active,
    };

    handleModifyUser(finalData, id)
      .then(function (response: any) {
        const dataModal = {
          status: true,
          message: "Utilisateur modifié avec succès",
          error: false,
        };

        if (data.groups && data.groups.length > 0) {
          data.groups.map((group) => {
            let groupData = {
              group_id: group.group_id,
              user_id: response.data.id,
              role: "ADMIN",
            };
            if (group.group_id) {
              setLoadingValidate(true);
              handleaddGroupToUser(groupData).then(function (response: any) {
                setLoadingValidate(false);
              });
            }
          });
        }
        if (data.bars && data.bars.length > 0) {
          data.bars.map((bar) => {
            let barData = {
              bar_id: bar.bar_id,
              user_id: response.data.id,
              role: "ADMIN",
            };
            if (bar.bar_id) {
              setLoadingValidate(true);
              handleaddBarToUser(barData).then(function (response: any) {
                setLoadingValidate(false);
              });
            }
          });
        }

        if (groupsTeDelete && groupsTeDelete.length > 0) {
          groupsTeDelete.map((group) => {
            const groupUserId = groupsLink.find((x) => x.group_id === group);
            if (groupUserId) {
              setLoadingValidate(true);
              handledeleteGroupToUser(groupUserId.id).then(function (
                response: any
              ) {
                setLoadingValidate(false);
              });
            }
          });
        }

        if (barsToDelete && barsToDelete.length > 0) {
          barsToDelete.map((bar) => {
            const barUserId = barsLink.find((x) => x.bar_id === bar);
            if (barUserId) {
              setLoadingValidate(true);
              handledeleteBarToUser(barUserId.id).then(function (
                response: any
              ) {
                setLoadingValidate(false);
              });
            }
          });
        }

        setTimeout(() => {
          dispatch(showModal(dataModal));
          setLoadingValidate(false);
          navigate("/utilisateurs");
        }, 2000);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoadingValidate(false);
      });
  };

  const handleGroupToDelete = (id) => {
    setGroupsToDelete([...groupsTeDelete, id]);
  };

  const handleBarToDelete = (id) => {
    setBarsToDelete([...barsToDelete, id]);
  };

  useEffect(() => {
    setLoading(true);
    handleGetUserById(id)
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoading(false);
      });
  }, [id, dispatch, watch]);

  useEffect(() => {
    if (user) {
      console.log(user);
      let defaultValues: any = {};
      if (user.active) {
        defaultValues.active = user.active === 1 ? true : false;
      }

      defaultValues.lastname = user.lastname;
      defaultValues.firstname = user.firstname;
      defaultValues.email = user.email;
      defaultValues.genre = user.genre;
      defaultValues.birthdate = user.birthdate
        ? moment(user.birthdate).format("YYYY-MM-DD")
        : null;
      defaultValues.phone = user.phone;
      defaultValues.role_name = user.role_name;
      if (user.groups) {
        defaultValues.groups = user.groups;
        setGroups(user.groups);
      }
      if (user.bars) {
        defaultValues.bars = user.bars;
        setBars(user.bars);
      }

      if (user.groupsUsersLinks) {
        setGroupsLink(user.groupsUsersLinks);
      }
      if (user.barsUsersLinks) {
        setBarsLink(user.barsUsersLinks);
      }

      reset({ ...defaultValues });
    }
  }, [user, reset]);

  return (
    <main className={styles.main}>
      {loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <FormProvider {...methods}>
          <Link to="/utilisateurs" className={styles.back_link}>
            <ArrowLeft className={styles.back_icon} />
            Annuler
          </Link>
          {user && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <HeaderForm title={"Modifier l’utilisateur"} />

              <WhiteSection title="Profil">
                <div className={styles.form_container}>
                  <div>
                    <Label isRequired={true} htmlFor="firstname">
                      Prénom
                    </Label>
                    <input
                      type="text"
                      id="firstname"
                      className={
                        errors.firstname ? "m-input m-input--red" : "m-input"
                      }
                      {...register("firstname", {
                        required: "Un prénom requis",
                      })}
                    />
                    {errors.firstname && (
                      <p className={"m-error"}>Un prénom est requis</p>
                    )}
                  </div>
                  <div>
                    <Label isRequired={true} htmlFor="lastname">
                      Nom
                    </Label>
                    <input
                      type="text"
                      id="lastname"
                      className={
                        errors.lastname ? "m-input m-input--red" : "m-input"
                      }
                      {...register("lastname", {
                        required: "Un nom est requis",
                      })}
                    />
                    {errors.lastname && (
                      <p className={"m-error"}>Un nom est requis</p>
                    )}
                  </div>
                  <div>
                    <Label isRequired={true} htmlFor="gender">
                      Civilité
                    </Label>
                    <div className={"m-select-box "}>
                      <select id="gender" {...register("genre")}>
                        <option value="Mme">Madame</option>
                        <option value="Mr">Monsieur</option>
                      </select>
                      <ArrowDown />
                    </div>
                  </div>
                  <div>
                    <Label isRequired={false} htmlFor="birthdate">
                      Date de naissance
                    </Label>
                    <input
                      className={
                        errors.birthdate
                          ? "m-input m-input--small115 m-input--red"
                          : "m-input m-input--small115"
                      }
                      type={"date"}
                      {...register("birthdate")}
                    />
                    {errors.birthdate && (
                      <p className={"m-error"}>Une date est requis</p>
                    )}
                  </div>
                  <div>
                    <Label isRequired={true} htmlFor="email">
                      Email
                    </Label>
                    <input
                      type="text"
                      id="email"
                      className={
                        errors.email ? "m-input m-input--red" : "m-input"
                      }
                      {...register("email", {
                        required: "Un email est requis",
                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <p className={"m-error"}>Un email est requis</p>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <p className={"m-error"}>L'email n'est pas valide</p>
                    )}
                  </div>

                  <div>
                    <Label isRequired={true} htmlFor="phone">
                      Numéro de téléphone
                    </Label>
                    <input
                      type="tel"
                      id="phone"
                      className={
                        errors.phone ? "m-input m-input--red" : "m-input"
                      }
                      {...register("phone", {
                        required: true,
                        pattern: /^\d{10}$/im,
                      })}
                    />
                    {errors.phone && errors.phone.type === "required" && (
                      <p className={"m-error"}>Un téléphone est requis</p>
                    )}
                    {errors.phone && errors.phone.type === "pattern" && (
                      <p className={"m-error"}>Le téléphone n'est pas valide</p>
                    )}
                  </div>
                  <div>
                    <Label isRequired={true} htmlFor="role">
                      Rôle
                    </Label>
                    <div className={"m-select-box"}>
                      <select
                        id="role_name"
                        {...register("role_name", {
                          required: "Un role est requis",
                        })}
                      >
                        <option value="USER">Utilisateur</option>
                        <option value="SUPER_ADMIN">Administrateur</option>
                      </select>
                      <ArrowDown />
                    </div>
                  </div>
                </div>
              </WhiteSection>
              <div className={styles.bottom}>
                <div className={styles.bottomBlock}>
                  <p className={styles.bottomTitle}>Groupe(s)</p>
                  <UserGroup
                    groupsEdit={groups}
                    groupsToDelete={handleGroupToDelete}
                  />
                </div>
                <div className={styles.bottomBlock}>
                  <p className={styles.bottomTitle}>Bar(s) et lieu(x)</p>
                  <UserBar barsEdit={bars} barsToDelete={handleBarToDelete} />
                </div>
              </div>

              <Button
                type="submit"
                bgColor="green"
                textColor="purple"
                className={styles.create_button}
              >
                {loadingValidate ? (
                  <Loading />
                ) : (
                  "Enregistrer les modifications"
                )}
              </Button>
            </form>
          )}
        </FormProvider>
      )}
    </main>
  );
};

export default EditUser;
