const MusicTone = ({ className, active }: MusicToneProps) => {
  if (active) {
    return (
      <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <path
          opacity=".2"
          d="M11.25 12.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM3.25 14.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z"
          fill="#079F62"
        />
        <path
          d="M11.25 12.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM3.25 14.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM13 5 5 7"
          stroke="#079F62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 12.75V4l8-2v8.75"
          stroke="#079F62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <path
          d="M11.25 12.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM3.25 14.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM13 5 5 7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 12.75V4l8-2v8.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};

interface MusicToneProps {
  active?: boolean;
  className?: string;
}

export default MusicTone;
