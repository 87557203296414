
import { BarTypeCreate } from "bars/types/create";

import { getRequest, postRequest, putRequest} from "utils/useApi";


export const handleGetAllBars = (form?) => {
  return getRequest("bars", form).then((response) => {
    return response.data;
  });
}

export const handleGetBar = (id) => {
  return getRequest("bars/" + id).then((response) => {
    return response.data;
  });
}

export const handleCreateBar = (bar: BarTypeCreate) => {
  return postRequest("bars", bar).then((response) => {
    return response.data;
  });
};

export const handleUpdateBar = (bar: any, id) => {
  return putRequest("bars/" + id, bar).then((response) => {
    return response.data;
  });
};

export const handleGetBarsExport = () => {
  return getRequest("bars/csv").then((response) => {
    return response;
  });
};
