const ArrowRight = (props: any) => {
  if (props.active) {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <path
          d="M5.625 2.813 10.313 7.5l-4.688 4.688"
          stroke="#079F62"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
      >
        <path
          d="M5.625 2.813 10.313 7.5l-4.688 4.688"
          stroke="#718096"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

export default ArrowRight;
