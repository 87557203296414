const Soundcloud = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M8.72 7.163c.22 0 .4 1.755.4 3.92v.293c-.017 2.028-.19 3.627-.4 3.627-.213 0-.388-1.637-.4-3.7v-.293c.004-2.132.182-3.847.4-3.847Zm-1.387.8c.216 0 .392 1.5.4 3.379v.283c-.008 1.878-.184 3.378-.4 3.378-.212 0-.386-1.462-.4-3.308v-.283l.002-.284c.02-1.777.192-3.165.398-3.165Zm-1.386 0c.212 0 .386 1.463.4 3.309v.283l-.002.283c-.02 1.778-.192 3.165-.398 3.165-.216 0-.392-1.5-.4-3.378v-.283c.008-1.879.184-3.379.4-3.379Zm-1.387.48c.22 0 .4 1.469.4 3.28l-.002.28c-.016 1.68-.189 3-.398 3-.218 0-.395-1.43-.4-3.21v-.14c.005-1.779.182-3.21.4-3.21Zm-1.387 1.44c.221 0 .4 1.146.4 2.56v.132c-.01 1.353-.185 2.428-.4 2.428-.22 0-.4-1.146-.4-2.56v-.131c.011-1.353.186-2.429.4-2.429Zm8.987-4a4.322 4.322 0 0 1 4.265 3.629 2.8 2.8 0 1 1 .828 5.49l-.053.001h-6.8a.64.64 0 0 1-.64-.64V6.611a4.3 4.3 0 0 1 2.4-.728ZM1.787 9.723c.22 0 .4 1.11.4 2.48v.13c-.012 1.309-.186 2.35-.4 2.35-.217 0-.394-1.075-.4-2.415v-.065c0-1.37.179-2.48.4-2.48Zm-1.387.8c.22 0 .4.789.4 1.76v.11c-.013.92-.188 1.65-.4 1.65-.22 0-.4-.788-.4-1.76v-.109c.013-.92.188-1.65.4-1.65Z"
      fill="#E36A36"
    />
  </svg>
);

export default Soundcloud;
