const Users = ({ className, active }: UsersProps) => {
  if (active) {
    return (
      <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <path
          opacity=".2"
          d="M5.5 10a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
          fill="#079F62"
        />
        <path
          d="M5.5 10a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
          stroke="#079F62"
          strokeMiterlimit="10"
        />
        <path
          d="M9.713 3.619A3.25 3.25 0 1 1 10.594 10M1 12.338a5.5 5.5 0 0 1 9 0M10.594 10a5.494 5.494 0 0 1 4.5 2.338"
          stroke="#079F62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path
          d="M5.5 10a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
          strokeMiterlimit="10"
        />
        <path
          d="M9.713 3.619A3.25 3.25 0 1 1 10.594 10M1 12.338a5.5 5.5 0 0 1 9 0M10.594 10a5.494 5.494 0 0 1 4.5 2.338"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};

interface UsersProps {
  active?: boolean;
  className?: string;
}

export default Users;
