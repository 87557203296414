const Spotify = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M10 1.875A8.125 8.125 0 1 0 18.125 10 8.14 8.14 0 0 0 10 1.875Zm2.234 11.305a.625.625 0 0 1-.836.273A3.094 3.094 0 0 0 10 13.125a3.054 3.054 0 0 0-1.406.336.625.625 0 0 1-.563-1.117 4.382 4.382 0 0 1 3.93-.008.633.633 0 0 1 .273.844Zm1.157-2.211a.617.617 0 0 1-.836.265 5.625 5.625 0 0 0-5.11.008.616.616 0 0 1-.844-.273.625.625 0 0 1 .274-.844 6.867 6.867 0 0 1 6.242 0 .624.624 0 0 1 .274.844Zm1.156-2.219a.617.617 0 0 1-.836.266A8.148 8.148 0 0 0 10 8.125a8.062 8.062 0 0 0-3.719.898.602.602 0 0 1-.281.07.625.625 0 0 1-.29-1.179 9.39 9.39 0 0 1 8.571-.008.626.626 0 0 1 .266.844Z"
      fill="#65D36E"
    />
  </svg>
);

export default Spotify;
