import {
  BLUES,
  CHANSON,
  CHANSON_ARRAY,
  CLASSIC_ARRAY,
  CLASSIC_MUSIC,
  ELECTRO_ARRAY,
  ELECTRO_MUSIC,
  FOLK,
  COUNTRY,
  FUNK_DISCO,
  JAZZ,
  JAZZ_ARRAY,
  LATINO_ARRAY,
  LATINO_MUSIC,
  METAL,
  METAL_ARRAY,
  POP,
  RAP_ARRAY,
  RAP_MUSIC,
  REGGAE_ARRAY,
  REGGAE_MUSIC,
  RNB_SOUL,
  ROCK_ARRAY,
  ROCK_MUSIC,
  TRADITIONNAL_ARRAY,
  TRADITIONNAL_MUSIC,
  WORLDS_ARRAY,
  WORLDS_MUSIC,
} from "bars/constants";
import { ChangeEvent } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Check from "ui/icons/check/Check";
import Cutlery from "ui/icons/cutlery/Cutlery";

import styles from "./StylesForm.module.scss";

type Props = {
  required?: boolean;
};

const StylesForm = ({ required }: Props) => {
  const { register, watch, getValues } = useFormContext();

  const selectStyle = watch("styles");

  const { append, remove } = useFieldArray({
    name: "styles", // unique name for your Field Array
  });

  function checkInEdit(data) {
    if (selectStyle) {
      return selectStyle.filter((e) => e.name === data).length > 0;
    }
  }

  const checkActiveStyle0 = (event: ChangeEvent<HTMLInputElement>, name) => {
    const {
      target: { checked: inputValue },
    } = event;
    const styles = getValues().styles;
    if (inputValue) {
      append({ name });
    } else {
      const index = styles.findIndex((x) => x.name === name);
      remove(index);
    }
  };

  return (
    <>
      <div className={styles.head}>
        <p className={styles.title}>
          Style et ligne éditoriale{required && <span>*</span>}
        </p>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(BLUES)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, BLUES)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{BLUES}</p>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(FOLK)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, FOLK)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{FOLK}</p>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(COUNTRY)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, COUNTRY)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{COUNTRY}</p>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(FUNK_DISCO)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, FUNK_DISCO)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{FUNK_DISCO}</p>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(POP)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, POP)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{POP}</p>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(RNB_SOUL)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, RNB_SOUL)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{RNB_SOUL}</p>
      </div>

      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(CHANSON)}
          value={selectStyle}
          onChange={(e) => checkActiveStyle0(e, CHANSON)}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{CHANSON}</p>
      </div>
      <div className={styles.grid}>
        <>
          {CHANSON_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor="blues">{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(JAZZ)}
          onChange={(e) => checkActiveStyle0(e, JAZZ)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{JAZZ}</p>
      </div>
      <div className={styles.grid}>
        <>
          {JAZZ_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(METAL)}
          onChange={(e) => checkActiveStyle0(e, METAL)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{METAL}</p>
      </div>
      <div className={styles.grid}>
        <>
          {METAL_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(CLASSIC_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, CLASSIC_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{CLASSIC_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {CLASSIC_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(WORLDS_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, WORLDS_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{WORLDS_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {WORLDS_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(ELECTRO_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, ELECTRO_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{ELECTRO_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {ELECTRO_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>

      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(LATINO_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, LATINO_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{LATINO_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {LATINO_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(TRADITIONNAL_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, TRADITIONNAL_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{TRADITIONNAL_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {TRADITIONNAL_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(RAP_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, RAP_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{RAP_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {RAP_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>

      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(REGGAE_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, REGGAE_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{REGGAE_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {REGGAE_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>

      <div className={styles.checkboxsSingle}>
        <input
          checked={checkInEdit(ROCK_MUSIC)}
          onChange={(e) => checkActiveStyle0(e, ROCK_MUSIC)}
          value={selectStyle}
          type={"checkbox"}
        />
        <div className={styles.checkmark}>
          <Check className={styles.check_icon} />
        </div>
        <p>{ROCK_MUSIC}</p>
      </div>
      <div className={styles.grid}>
        <>
          {ROCK_ARRAY.map((style) => {
            return (
              <div className={styles.subCategory}>
                <div className={styles.subCategoryLabel}>
                  <label htmlFor={style}>{style}</label>
                </div>

                <div className={styles.checkboxsItem}>
                  <input
                    checked={checkInEdit(style)}
                    onChange={(e) => checkActiveStyle0(e, style)}
                    value={selectStyle}
                    type={"checkbox"}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </>
  );
};

export default StylesForm;
