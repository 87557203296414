const CheckCircle = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="M7.5 1.406A6.094 6.094 0 1 0 13.594 7.5 6.106 6.106 0 0 0 7.5 1.406Zm2.9 5.028-3.433 3.28a.475.475 0 0 1-.65 0L4.6 8.075a.469.469 0 1 1 .644-.68l1.395 1.33 3.117-2.97a.469.469 0 0 1 .644.68Z"
      fill="#12ED94"
    />
  </svg>
);

export default CheckCircle;
