import BarItem from "bars/components/bars/barItem/BarItem";
import { useMemo } from "react";

import styles from "./UserSingleBars.module.scss";

type Props = {
  bars: any;
};

const UserSingleBars = ({ bars }: Props) => {
  const renderBars = useMemo(() => {
    return bars.map((bar, index) => {
      return (
        <div key={index} className={styles.item}>
          <BarItem bar={bar} />
        </div>
      );
    });
  }, [bars]);

  return <div className={styles.main}>{renderBars}</div>;
};

export default UserSingleBars;
