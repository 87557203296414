import styles from "./WhiteSection.module.scss";

const WhiteSection = ({ title, children, className }: WhiteSectionProps) => {
  return (
    <section className={`${styles.section} ${className}`}>
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
      </header>
      {children}
    </section>
  );
};

interface WhiteSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export default WhiteSection;
