import { handleForgetPassword } from "account/controllers/users";
import { showModal } from "app/actions/modal";
import Loading from "app/components/loading/Loading";
import { errorsAPI } from "app/constants/errors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "ui/components/button/Button";
import Label from "ui/components/label/Label";

import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import Logo from "ui/icons/logo/Logo";

import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    handleForgetPassword(data)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Email envoyé",
          error: false,
        };
        dispatch(showModal(data));
        setLoading(false);
        navigate("/compte/connexion");
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoading(false);
      });
  };

  return (
    <div>
      <Wrapper>
        <ContentContainer>
          <div className={styles.logo}>
            <Logo />
          </div>

          <FormContainer>
            <Link className={styles.back} to="/compte/connexion">
              <>
                <ArrowLeft className={styles.arrow_left} />
                Retour
              </>
            </Link>
            <Title>Vous avez oublié votre mot de passe ?</Title>
            <Text>
              Saisissez votre adresse email pour recevoir le lien de
              réinitialisation du mot de passe
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Label isRequired={true} htmlFor="email">
                Adresse email
              </Label>
              <input
                type={"email"}
                {...register("email", {
                  required: true,
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                })}
                placeholder="Votre adresse email"
                className={errors.email ? "m-input m-input--red" : "m-input"}
              />
              {errors.email && errors.email.type === "required" && (
                <p className={"m-error"}>Un email est requis</p>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <p className={"m-error"}>L'email n'est pas valide</p>
              )}

              <div className={styles.submit}>
                <Button
                  type={"submit"}
                  bgColor="green"
                  textColor="grey900"
                  widthFull={true}
                >
                  {loading ? <Loading /> : "Valider"}
                </Button>
              </div>
            </form>
          </FormContainer>
        </ContentContainer>
        <Image
          src="https://res.cloudinary.com/dvi4nelfa/image/upload/v1655408410/uxer/zicozinc/login.png"
          alt=""
          aria-hidden="true"
        />
      </Wrapper>
    </div>
  );
};

export default ForgotPassword;

const Wrapper = styled.main`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const ContentContainer = styled.div`
  position: relative;
  padding: 48px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    padding: 0;
    width: 50%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    max-width: 384px;
  }
`;

const Image = styled.img`
  display: none;
  width: 50%;
  height: 100vh;
  object-fit: cover;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--grey900);
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: var(--grey700);
  margin-bottom: 32px;
`;
