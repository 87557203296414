import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CompositionMusician from "./compositionMusician/CompositionMusician";

import styles from "./FormationMusician.module.scss";

const FormationMusician = () => {
  const { register, watch, getValues } = useFormContext();
  const [compositions, setCompositions] = useState([]);
  const { append, remove } = useFieldArray({
    name: "composition", // unique name for your Field Array
  });

  useEffect(() => {
    watch((value, { name, type }) => {
      setCompositions(value.composition);
    });
  }, [watch]);

  const addFormation = () => {
    const data = {
      name: "",
      nb_musicians: 1,
      description: "",
      prices: [],
      members: [],
    };
    append(data);
  };

  const removeComposition = (index) => {
    remove(index);
    setCompositions(compositions.filter((x, i) => i !== index));
  };

  const renderComposition = useMemo(() => {
    if ((compositions && compositions.length == 0) || compositions == undefined)
      return;
    return compositions.map((compo, i) => {
      return (
        <div key={Math.random().toString(36)}>
          <CompositionMusician
            composition={compo}
            indexFormation={i}
            removeComposition={removeComposition}
          />
        </div>
      );
    });
  }, [compositions]);

  return (
    <>
      {renderComposition}
      <button onClick={addFormation} type={"button"} className={styles.add}>
        Ajouter une formation
      </button>
    </>
  );
};

export default FormationMusician;
