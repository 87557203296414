export const errorsAPI = {
  TOKEN_FALSE: "Mauvais token",
  TOKEN_NOT_FOUND:
    "Oups, il semble que vous n'ayez pas cliqué sur le dernier lien de réinitialisation",
  NO_TOKEN: "Le token est manquant",
  AUTHENTIFICATION_FAILED: "Erreur d'authentification",
  EMPTY_DATA: "Des données sont manquantes",
  EMAIL_ALREADY_EXIST: "Cet email existe déjà",
  EMAIL_NOT_FOUND: "Cet email n'existe pas dans notre base de données",
  WRONG_PASSWORD: "Mauvais mot de passe",
  ACCOUNT_NOT_ACTIVE: "Ce compte n'est pas actif",
  PASSWORD_NOT_MATCH: "les mots de passes ne correspondent pas",
  USER_NOT_FOUND: "Cet utilisateur n'existe pas",
  BAR_NOT_FOUND: "Ce bar n'existe pas",
  ALREADY_IN_BAR: "Ce bar est deja lié a cette utilisateur",
  BAR_USER_LINK_NOT_FOUND:
    "Aucune relation n'existe entre le bar et l'utilisateur",
  EVENT_NOT_FOUND: "Cet évènement n'existe pas",
  GROUP_NOT_FOUND: "Ce groupe/musicien n'existe pas",
  ALREADY_IN_GROUP: "Ce groupe/musicien est deja lié a cette utilisateur",
  GROUP_USER_LINK_NOT_FOUND:
    "Aucune relation n'existe entre le groupe/musicien et l'utilisateur",
  MEDIA_NOT_FOUND: "Un média est lié mais n'existe plus",
  EMAIL_ALREADY_EXISTS: "Un compte est déjà associé à cette adresse email",
};
