const PaiementCardIcon = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      d="M12.25 3.063H1.75a.438.438 0 0 0-.438.437v7c0 .242.196.438.438.438h10.5a.438.438 0 0 0 .438-.438v-7a.438.438 0 0 0-.438-.438ZM9.188 9.188h1.75M6.563 9.188h.875M1.313 5.3h11.374"
      stroke-width="1.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default PaiementCardIcon;
