const WarningCircle = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="M7.5 1.406A6.094 6.094 0 1 0 13.594 7.5 6.106 6.106 0 0 0 7.5 1.406Zm-.469 3.282a.469.469 0 0 1 .938 0v3.28a.469.469 0 1 1-.938 0v-3.28ZM7.5 10.78a.704.704 0 1 1 0-1.407.704.704 0 0 1 0 1.407Z"
      fill="#D96E66"
    />
  </svg>
);

export default WarningCircle;
