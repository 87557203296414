import { handleDeleteMedia } from "app/controllers/media";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import Button from "ui/components/button/Button";
import File from "ui/icons/file/File";
import Trash from "ui/icons/trash/Trash";
import { getExternalUrl, removeExtension } from "utils/mediaSizes";
import styles from "./Media.module.scss";

import { addMediaToDelete } from "app/actions/media";
import { useDispatch } from "react-redux";

type Props = {
  media: any;
  sizes: any;
  index: number;
  onDelete: Function;
  type: string;
};

const Media = ({ media, sizes, index, onDelete }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);

  const { remove } = useFieldArray({
    name: "medias", // unique name for your Field Array
  });
  const [finalMedia, setFinalMedia] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setFinalMedia(true);
  //   }, 500);
  // }, []);

  function openDeleteModal() {
    setDeleteModal(true);
  }
  function closeDeleteModal() {
    setDeleteModal(false);
  }

  function confirmDelete() {
    if (id) {
      dispatch(addMediaToDelete(media.id));
      closeDeleteModal();
      onDelete(media.id);
    } else {
      handleDeleteMedia(media.id)
        .then(function (response: any) {
          onDelete(response.data.id);
          closeDeleteModal();
        })
        .catch((err) => {
          const data = {
            status: true,
            message: "aie",
            error: true,
          };
        });
    }
  }

  const renderMedia = useMemo(() => {
    if (media) {
      if (media.subtype === "documents") {
        return (
          <a target="_blank" href={getExternalUrl(media.url)}>
            <File />
          </a>
        );
      } else {
        return <img src={removeExtension(media.url, sizes)} />;
      }
    }
  }, [media, sizes, finalMedia]);

  return (
    <>
      <div className={styles.main}>
        <button
          onClick={openDeleteModal}
          type={"button"}
          className={styles.delete}
        >
          <Trash />
        </button>
        {renderMedia}
      </div>
      {media.subtype === "documents" && (
        <p className={styles.name}>{media.name}</p>
      )}
      {deleteModal && (
        <div className={styles.popup}>
          <div className={styles.box}>
            <p className={styles.popupTitle}>
              {media.subtype === "documents"
                ? "Supprimer un document"
                : "Supprimer une photo"}
            </p>
            <p className={styles.popupText}>
              Souhaitez-vous vraiment supprimer{" "}
              {media.subtype === "documents" ? "ce document" : "cette photo"} ?
              <br /> Cette action est irréversible.
            </p>
            <div className={styles.popupButtons}>
              <Button
                type={"button"}
                onClick={closeDeleteModal}
                bgColor="grey300"
                textColor="grey900"
              >
                Annuler
              </Button>
              <Button
                type={"button"}
                onClick={confirmDelete}
                bgColor="dark-red"
                textColor="grey0"
              >
                Supprimer
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Media;
