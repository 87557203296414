import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Loading from "app/components/loading/Loading";
import useOutsideClick from "hooks/useOutsideClick";
import { handleGetAllMusicians } from "musicians/controllers";

import { Link } from "react-router-dom";
import Button from "ui/components/button/Button";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import ArrowRight from "ui/icons/arrow-right/ArrowRight";
import Calendar from "ui/icons/calendar/Calendar";
import Check from "ui/icons/check/Check";
import ToggleIcon from "ui/icons/ToggleIcon";

import MusicTone from "ui/icons/music-tone/MusicTone";
import Plus from "ui/icons/plus/Plus";
import Search from "ui/icons/search/Search";
import SortAscending from "ui/icons/sort-ascending/SortAscending";
import SquaresIcon from "ui/icons/squaresIcon/SquaresIcon";
import { getAllStyles } from "utils/getAllStyles";

import GroupsItem from "musicians/components/groups/groupsItem/GroupsItem";
import ExportsForm from "form/components/exportsForm/ExportsForm";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

import styles from "./AllMusicians.module.scss";
import AllEventsDatepicker from "events/components/allEventsDatepicker/AllEventsDatepicker";
import { XCircle } from "phosphor-react";
import moment from "moment";

const AllMusicians = () => {
  const filterRef = useRef(null);
  const sortRef = useRef(null);
  const dateFilterRef = useRef(null);
  const dispatch = useDispatch();

  const [musicians, setMusicians] = useState([]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [filterStatus, setFiltersStatus] = useState(null);
  const [sortOpen, setSortOpen] = useState(false);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [getStyles, setGetStyles] = useState([]);
  const [activeState, setActiveState] = useState("all");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [createdStartDate, setCreatedStartDate] = useState<Date | null>(null);
  const [createdEndDate, setCreatedEndDate] = useState<Date | null>(null);

  const onClickOutsideFilter = useCallback(() => {
    setFilterOpen(false);
    setFiltersStatus(null);
  }, []);

  const onClickOutsideSort = useCallback(() => {
    setSortOpen(false);
  }, []);

  const onClickOutsideDateFilter = useCallback(() => {
    setDateFilterOpen(false);
  }, []);

  useOutsideClick(filterRef, onClickOutsideFilter);
  useOutsideClick(sortRef, onClickOutsideSort);
  useOutsideClick(dateFilterRef, onClickOutsideDateFilter);

  const [filters, setFilters] = useState({
    county: [],
    style: [],
  });

  useEffect(() => {
    setGetStyles(getAllStyles);
    const filtersFinal = {
      limit: limit,
      page: page,
      filterByStyles: [],
    };
    handleGetAllMusicians(filtersFinal)
      .then(function (response: any) {
        setMusicians(response.data);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: "aie",
          error: true,
        };
      });
  }, []);

  function openFilter() {
    setFilterOpen(true);
  }
  function openSort() {
    setSortOpen(true);
  }

  const openDateFilter = () => {
    setDateFilterOpen(true);
  };

  const resetDateFilter = () => {
    setCreatedStartDate(null);
    setCreatedEndDate(null);
    setPage(1);
  };

  function sortBy(type) {
    if (type == sort) {
      setSort("");
    } else {
      setSort(type);
    }
  }

  function getSearch(e) {
    const value = e.target.value;
    setSearch(value);
    setPage(1);
    setLoadingSearch(true);
  }
  function handleMore() {
    setPage(page + 1);
  }

  function filter(name, e) {
    const value = e.target.value;
    const checked = e.target.checked;
    const copyFilters = Object.assign({}, filters);
    if (checked) {
      copyFilters[name].push(value);
    } else {
      const findIndex = copyFilters[name].indexOf(value);
      copyFilters[name].splice(findIndex, 1);
    }
    setFilters(copyFilters);
    setPage(1);
  }

  useEffect(() => {
    setLoading(true);
    let needValidation, active;
    switch (activeState) {
      case "all":
        needValidation = null;
        active = null;
        break;
      case "actives":
        needValidation = null;
        active = 1;
        break;
      case "inactives":
        needValidation = null;
        active = 0;
        break;
      case "pending":
        needValidation = true;
        active = 0;
        break;
    }
    const filtersFinal = {
      filterByStyles: filters.style,
      orderBy: sort,
      needValidation,
      active,
      search: search,
      limit: limit,
      page: page,
      createdStartDate,
      createdEndDate,
    };
    handleGetAllMusicians(filtersFinal)
      .then(function (response: any) {
        if (page > 1) {
          setMusicians((musicians) => [...musicians, ...response.data]);
        } else {
          setMusicians(response.data);
        }

        if (response.data.length < limit) {
          setShowMore(false);
        } else {
          setShowMore(true);
        }
        setLoadingSearch(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoadingSearch(false);
        setLoading(false);
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
  }, [
    filters,
    sort,
    search,
    page,
    activeState,
    limit,
    createdStartDate,
    createdEndDate,
    dispatch,
  ]);

  const renderMusicians = useMemo(() => {
    if (musicians && musicians.length === 0)
      return (
        <div className={styles.empty}>
          <SquaresIcon />
          <p>Aucun résultat disponible</p>
        </div>
      );
    return musicians.map((musician, index) => {
      return (
        <div key={index} className={styles.item}>
          <GroupsItem group={musician} />
        </div>
      );
    });
  }, [musicians]);

  const renderMore = useMemo(() => {
    if (!showMore) return null;
    return (
      <div onClick={handleMore} className={styles.more}>
        <Button bgColor="grey300" textColor="purple">
          Charger plus de groupes et musiciens
        </Button>
      </div>
    );
  }, [musicians]);

  const renderDateFilter = () => {
    if (createdStartDate === null || createdEndDate === null) {
      return "Date d’inscription";
    } else {
      const formattedStartDate = moment(createdStartDate).format("DD/MM/YYYY");
      const formattedEndDate = moment(createdEndDate).format("DD/MM/YYYY");
      return `${formattedStartDate} au ${formattedEndDate}`;
    }
  };

  const renderFilters = useMemo(() => {
    if (filterStatus === null) return null;
    if (filterStatus === "style") {
      return (
        <div className={styles.filterSecond}>
          <ul>
            {getStyles.map((style, index) => (
              <li>
                <div className={styles.checkBoxItem}>
                  <input
                    name={"style" + index}
                    checked={filters["style"].find((x) => x == style)}
                    type={"checkbox"}
                    onChange={(e) => filter("style", e)}
                    value={style}
                  />
                  <div className={styles.checkmark}>
                    <Check className={styles.check_icon} />
                  </div>
                  <p className={styles.checkLabel}>{style}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className={styles.filterSecond}>
          <ul>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="all"
                  checked={activeState === "all"}
                  onChange={() => {
                    setActiveState("all");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>Tout afficher</p>
              </div>
            </li>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="actives"
                  checked={activeState === "actives"}
                  onChange={() => {
                    setActiveState("actives");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>Actifs</p>
              </div>
            </li>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="inactives"
                  checked={activeState === "inactives"}
                  onChange={() => {
                    setActiveState("inactives");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>Inactifs</p>
              </div>
            </li>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="pending"
                  checked={activeState === "pending"}
                  onChange={() => {
                    setActiveState("pending");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>En attente de validation</p>
              </div>
            </li>
          </ul>
        </div>
      );
    }
  }, [filterStatus, filters, activeState]);

  function selectStatus(status) {
    setFiltersStatus(status);
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1 className={styles.title}>Liste des groupes et musiciens</h1>
        <ExportsForm type={"groups"} />
      </div>
      <div className={styles.head}>
        <div className={styles.filter}>
          <div className={styles.filterLeft}>
            <div ref={filterRef}>
              <button
                type={"button"}
                className={styles.filterButton}
                onClick={openFilter}
              >
                Filtrer par
                {(filters.style.length || filters.county.length) > 0 && (
                  <span className={styles.number}>
                    {filters.county.length + filters.style.length}
                  </span>
                )}
                <ArrowDown />
              </button>
              {filterOpen && (
                <div className={styles.filterBox}>
                  <div className={styles.filterFirst}>
                    <ul>
                      <li>
                        <button
                          type={"button"}
                          className={
                            filterStatus === "style" ? styles.active : ""
                          }
                          onClick={() => selectStatus("style")}
                        >
                          <span className={styles.filter_icon}>
                            <MusicTone />
                          </span>
                          Styles
                          <ArrowRight className={styles.arrow_right} />
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            filterStatus === "active" ? styles.active : ""
                          }
                          onClick={() => selectStatus("active")}
                        >
                          <span className={styles.filter_icon}>
                            <ToggleIcon />
                          </span>
                          Statut
                          <ArrowRight className={styles.arrow_right} />
                        </button>
                      </li>
                    </ul>
                  </div>
                  {renderFilters}
                </div>
              )}
            </div>
            <div className={styles.sort}>
              <div ref={sortRef}>
                <button className={styles.filterButton} onClick={openSort}>
                  Trier par
                  <ArrowDown />
                </button>
                {sortOpen && (
                  <ul>
                    <li>
                      <button
                        className={sort === "date" ? styles.active : ""}
                        onClick={() => sortBy("date")}
                        type={"button"}
                      >
                        <Calendar />
                        Par date d’inscription
                      </button>
                    </li>
                    <li>
                      <button
                        className={sort === "alphabetic" ? styles.active : ""}
                        onClick={() => sortBy("alphabetic")}
                        type={"button"}
                      >
                        <SortAscending />
                        Par ordre alphabétique
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className={styles.dateFilter} ref={dateFilterRef}>
              <button
                type={"button"}
                className={styles.dateFilterButton}
                style={
                  createdStartDate !== null && createdEndDate !== null
                    ? { paddingRight: "39px" }
                    : {}
                }
                onClick={openDateFilter}
              >
                <Calendar />
                {renderDateFilter()}
              </button>
              {createdStartDate !== null && createdEndDate !== null && (
                <button
                  className={styles.resetDateFilter}
                  onClick={resetDateFilter}
                >
                  <XCircle weight="fill" />
                </button>
              )}
              {dateFilterOpen && (
                <div className={styles.dateFilterBox}>
                  <AllEventsDatepicker
                    startDate={createdStartDate}
                    setStartDate={setCreatedStartDate}
                    endDate={createdEndDate}
                    setEndDate={setCreatedEndDate}
                    setDateFilterOpen={setDateFilterOpen}
                    setPage={setPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.headRight}>
          <div className={styles.search}>
            <input
              onChange={getSearch}
              type={"text"}
              placeholder={"Rechercher"}
            />
            {loadingSearch ? (
              <div className={styles.searchLoading}>
                <Loading color={"green"} />
              </div>
            ) : (
              <Search />
            )}
          </div>
          <div className={styles.add}>
            <Link to="/groupes-et-musiciens/ajouter">
              <Button bgColor="green" textColor="purple">
                <Plus className={styles.add__icon} />
                Ajouter un musicien
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {loading && (
          <div className={styles.loading}>
            <Loading rounded={true} />
          </div>
        )}
        {renderMusicians}
      </div>
      {renderMore}
    </div>
  );
};

export default AllMusicians;
