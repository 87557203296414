import {
  BLUES,
  CHANSON,
  CHANSON_ARRAY,
  CLASSIC_ARRAY,
  CLASSIC_MUSIC,
  FOLK,
  COUNTRY,
  FUNK_DISCO,
  JAZZ,
  JAZZ_ARRAY,
  METAL,
  METAL_ARRAY,
  POP,
  RNB_SOUL,
} from "bars/constants";

export function getAllStyles() {
  let array = [];
  array.push(BLUES);
  array.push(FOLK);
  array.push(COUNTRY);
  array.push(FUNK_DISCO);
  array.push(POP);
  array.push(RNB_SOUL);
  array.push(CHANSON);
  CHANSON_ARRAY.map((style) => {
    array.push(style);
  });
  array.push(JAZZ);
  JAZZ_ARRAY.map((style) => {
    array.push(style);
  });
  array.push(METAL);
  METAL_ARRAY.map((style) => {
    array.push(style);
  });
  array.push(CLASSIC_MUSIC);
  CLASSIC_ARRAY.map((style) => {
    array.push(style);
  });
  return array;
}
