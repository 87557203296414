const CheerIcon = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      d="M5.245 3.281a1.094 1.094 0 1 0 0-2.187 1.094 1.094 0 0 0 0 2.187Z"
      stroke-width="1.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.245 3.281V7.22h4.812l1.75 3.5 1.313-.438"
      stroke-width="1.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.177 8.969A3.5 3.5 0 1 1 5.682 5.25h3.062"
      stroke-width="1.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CheerIcon;
