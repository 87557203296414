import moment from "moment";
import { useMemo } from "react";
import SingleListItem from "ui/components/single-list-item/SingleListItem";
import ArrowRightComplete from "ui/icons/arrow-right-complete/ArrowRightComplete";
import Info from "ui/icons/info/Info";
import Minus from "ui/icons/minus/Minus";
import styles from "./EventSingleInformation.module.scss";
import { Link } from "react-router-dom";

type Props = {
  information: any;
};

const EventSingleInformation = ({ information }: Props) => {
  const renderActiveClass = useMemo(() => {
    if (!information.active) return styles.active;
    return styles.active + " " + styles.green;
  }, [information.active]);

  const renderinformation = useMemo(() => {
    if (
      information.event_hours.am_start == "" &&
      information.event_hours.am_end == "" &&
      information.event_hours.pm_start == "" &&
      information.event_hours.pm_end == ""
    )
      return "Fermé";
    if (
      information.event_hours.am_end == "" &&
      information.event_hours.pm_start == "" &&
      information.event_hours.pm_end == ""
    ) {
      return <p>A partir de {information.event_hours.am_start}</p>;
    }
    if (
      information.event_hours.pm_end == "" &&
      information.event_hours.pm_start == ""
    ) {
      return (
        <>
          <p>{information.event_hours.am_start}</p>
          <ArrowRightComplete className={styles.arrow} />
          <p>{information.event_hours.am_end}</p>
        </>
      );
    }

    return (
      <>
        <p>
          {information.event_hours.am_start !== ""
            ? information.event_hours.am_start
            : "-"}
        </p>

        <ArrowRightComplete className={styles.arrow} />

        <p>
          {information.event_hours.am_end !== ""
            ? information.event_hours.am_end
            : "-"}
        </p>
        <Minus className={styles.minus} />

        <p>
          {information.event_hours.pm_start !== ""
            ? information.event_hours.pm_start
            : "-"}
        </p>

        <ArrowRightComplete className={styles.arrow} />

        <p>
          {information.event_hours.pm_end !== ""
            ? information.event_hours.pm_end
            : "-"}
        </p>
      </>
    );
  }, [information.event_hours]);

  console.log(information.created_by_user);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Détails de l’évènement</p>
      </div>

      <ul className={styles.list}>
        <li>
          <SingleListItem listItemLabel="État">
            <p className={renderActiveClass}>
              <span></span>
              {information.active == 1 ? "Activé" : "Désactivé"}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Créateur de l’évènement">
            <Link
              to={`/utilisateurs/single?id=${information.created_by_user?.id}`}
              className={styles.link}
              target="_blank"
            >
              {information.created_by_user?.firstname}{" "}
              {information.created_by_user?.lastname}
            </Link>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Date de l’évènement">
            <p className={styles.text}>
              {moment(information.event_date).format("DD/MM/YYYY")}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Événement créé le">
            <p className={styles.text}>
              {moment(information.event_created_at).format("DD/MM/YYYY")}
            </p>
          </SingleListItem>
        </li>
        <li>
          <SingleListItem listItemLabel="Horaires">
            <p className={styles.hours}>{renderinformation}</p>
          </SingleListItem>
        </li>
      </ul>
    </>
  );
};

export default EventSingleInformation;
