import { useCallback, useRef, useState } from "react";

import useOutsideClick from "utils/useOutsideClick";
import Play from "ui/icons/play/Play";
import styles from "./YoutubeMediaSingle.module.scss";
import { extractThumbnail, getVideoIframeUrl } from "utils/getYoutubeId";

type Props = {
  youtubeId: string;
  index: number;
};

const YoutubeMediaSingle = ({ youtubeId, index }: Props) => {
  const [open, setOpen] = useState(false);
  const iframeRef = useRef(null);

  const onClickOutsideIframe = useCallback(() => {
    setOpen(false);
  }, []);

  useOutsideClick(iframeRef, onClickOutsideIframe);

  return (
    <>
      <div className={styles.main}>
        <button
          type={"button"}
          onClick={() => setOpen(true)}
          className={styles.play}
        >
          <Play />
        </button>
        <img src={extractThumbnail(youtubeId)} />
      </div>
      {open && (
        <div className={styles.popup}>
          <div ref={iframeRef} className={styles.popupBox}>
            <iframe
              width="560"
              height="315"
              src={getVideoIframeUrl(youtubeId)}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default YoutubeMediaSingle;
