import BarItem from "bars/components/bars/barItem/BarItem";

import styles from "./EventSingleBars.module.scss";

type Props = {
  bar: any;
  transport: any;
};

const EventSingleBars = ({ bar, transport }: Props) => {
  return (
    <div className={styles.main}>
      <div className={styles.bar}>
        <BarItem target={true} bar={bar} />
      </div>
      {transport !== " " && (
        <div className={styles.transport}>
          <p className={styles.label}>Comment s’y rendre ?</p>
          <p className={styles.text}>{transport}</p>
        </div>
      )}
    </div>
  );
};

export default EventSingleBars;
