import { useMemo } from "react";
import Spotify from "ui/icons/spotify/Spotify";
import { getSpotifyUrl } from "utils/getSpotifyId";

import styles from "./MusicianSingleSpotify.module.scss";

type Props = {
  sounds: any;
};

const MusicianSingleSpotify = ({ sounds }: Props) => {
  const renderSounds = useMemo(() => {
    if (sounds && sounds.length == 0)
      return <p className={"m-empty"}>Aucun son Spotify</p>;
    return sounds.map((sound, index) => {
      return <li key={index}>{getSpotifyUrl(sound.url)}</li>;
    });
  }, [sounds]);

  return (
    <div className={styles.main}>
      <div className={styles.subtitle}>
        <Spotify className={styles.subtitle_icon} />
        <p>Spotify</p>
      </div>
      <div className={styles.list}>{renderSounds}</div>
    </div>
  );
};

export default MusicianSingleSpotify;
