
import { useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import TextAlign from "ui/icons/text-align/TextAlign";

import styles from "./DescriptionBar.module.scss";

const DescriptionBar = () => {
    const { register , formState: { errors }} = useFormContext();
    return (
        <>
            <p className={styles.subtitle}>
                <TextAlign className={styles.subtitle_icon} />
                Description du lieu
            </p>
            <div>
                <Label htmlFor="description" isRequired={true} >Description du lieu</Label>
                <textarea className={(errors.description) ? 'm-input m-input--red m-input--textarea' : 'm-input m-input--textarea'} {...register('description', { required: true})}> </textarea>
                {errors.description &&
                     <p className={'m-error'}>Une description est requise</p>
                }
            </div>
        </>
      
    )
}

export default DescriptionBar;