import styled from "styled-components";

const Button = (
  {
    disabled,
    children,
    bgColor,
    textColor,
    onClick,
    type,
    className,
    widthFull = false,
  }: ButtonBoxProps,
  props: any
) => {
  return (
    <ButtonBox
      {...props}
      disabled={disabled}
      bgColor={bgColor}
      textColor={textColor}
      type={type}
      onClick={onClick}
      className={className}
      widthFull={widthFull}
    >
      {children}
    </ButtonBox>
  );
};

export default Button;

interface ButtonBoxProps {
  readonly children: React.ReactNode;
  readonly disabled?: boolean;
  readonly bgColor: string;
  readonly textColor: string;
  readonly type?: string;
  readonly className?: string;
  readonly onClick?: () => void;
  readonly widthFull?: boolean;
}

const ButtonBox = styled.button<ButtonBoxProps>`
  width: ${(props) => (props.widthFull ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(props) => `var(--${props.bgColor})`};
  color: ${(props) => `var(--${props.textColor})`};
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  border-radius: 8px;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: ${(props) => {
      if (props.bgColor === "green") {
        return "var(--greenhover)";
      }
      if (props.bgColor === "grey300") {
        return "var(--grey400)";
      }
      if (props.bgColor === "grey400") {
        return "var(--grey500)";
      }
      if (props.bgColor === "red") {
        return "var(--dark-red)";
      }
      if (props.bgColor === "dark-red") {
        return "var(--dark-red-hover)";
      }
    }};
  }
`;
