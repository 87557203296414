export const formatPhoneNumber = (value): string => {
  let phoneNumber = value;
  phoneNumber = phoneNumber.replace(/\s/g, "");
  phoneNumber = phoneNumber.replace(/^0/, "+33");
  // limit 12 chars
  if (phoneNumber.length > 12) {
    phoneNumber = phoneNumber.substring(0, 12);
  }
  return phoneNumber;
};

export const internationalToPhoneNumber = (phoneNumber): string => {
  phoneNumber = phoneNumber.replace(/\s/g, "");
  phoneNumber = phoneNumber.replace(/^\+33/, "0");
  return phoneNumber;
};

