
type Props = {
    color?: string;
    rounded?: boolean;
};

const Loading = ({color, rounded} : Props) => {
    
    const classLoading = (rounded) ? 'm-loading m-loading--rounded' : 'm-loading';

    return (
        <div className={classLoading}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loading