import styled from "styled-components";

const SingleListItem = ({ listItemLabel, children }: SingleListItemProps) => {
  return (
    <Wrapper>
      <Label>{listItemLabel}</Label>
      {children}
    </Wrapper>
  );
};

interface SingleListItemProps {
  readonly listItemLabel: string;
  readonly children: React.ReactNode;
}

export default SingleListItem;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--grey900);
`;
