import StyleSingle from "app/components/stylesSingle/StyleSingle";
import StylesBar from "bars/components/bar/stylesBar/StylesBar";
import MusicianSingleCompo from "musicians/components/musicianSingle/musicianSingleCompo/MusicianSingleCompo";
import MusicianSingleDescription from "musicians/components/musicianSingle/musicianSingleDescription/MusicianSingleDescription";
import MusicianSingleDocuments from "musicians/components/musicianSingle/musicianSingleDocuments/MusicianSingleDocuments";
import MusicianSingleInformation from "musicians/components/musicianSingle/musicianSingleInformation/MusicianSingleInformation";
import MusicianSingleLocation from "musicians/components/musicianSingle/musicianSingleLocation/MusicianSingleLocation";
import MusicianSingleMedia from "musicians/components/musicianSingle/musicianSingleMedia/MusicianSingleMedia";
import MusicianSingleVideos from "musicians/components/musicianSingle/musicianSingleVideos/MusicianSingleVideos";
import MusicianSingleSpotify from "musicians/components/musicianSingle/musicianSingleSpotify/MusicianSingleSpotify";


import { handleGetMusician } from "musicians/controllers";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "ui/components/button/Button";
import SeeButton from "ui/components/see-button/SeeButton";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import LinkOut from "ui/icons/link-out/LinkOut";
import Pen from "ui/icons/pen/Pen";

import styles from "./SingleMusician.module.scss"
import MembersSingle from "app/components/membersSingle/MembersSingle";
import MusicianSingleSoundCloud from "musicians/components/musicianSingle/musicianSingleSoundCloud/MusicianSingleSoundCloud";
import MusicianSingleDeezer from "musicians/components/musicianSingle/musicianSingleDeezer/MusicianSingleDeezer";



const SingleMusician = () => {
    const { id } = useParams();
    const [group, setGroup] = useState(null);

    useEffect(() => {
        handleGetMusician(id).then(function (response) {
           
            setGroup(response.data);
          }).catch(err => {
              const data = {
              status: true,
              message: "aie",
              error: true
              }
          });
    }, []);

    if(!group) return null;
    return (

        <div className={styles.main}>
            <Link to="/groupes-et-musiciens/" className={styles.back_link}>
                <ArrowLeft className={styles.back_icon} />
                Retour
            </Link>
            <div className={styles.header}>
                <p className={styles.title}>{group.name}</p>
                <div className={styles.headerButtons}>
                    {/* <Button bgColor="grey300" textColor="grey900">
                        <p>Afficher sur Zicozinc</p>
                        <LinkOut className={styles.linkout_icon} />
                    </Button>
                    <SeeButton /> */}
                    <Link to={`/groupes-et-musiciens/${id}/edit`}>
                        <Button bgColor="green" textColor="purple">
                            <Pen className={styles.pen_icon} />
                            <p>Modifier</p>
                        </Button>
                    </Link> 
                </div>
            </div> 
         
            

            <section className={styles.section}>
                <div className={styles.information}>
                    <MusicianSingleInformation information={
                        {  
                            music_type: group.music_type,
                            active: group.active,
                            created_at: group.created_at,
                            negociation_open: group.negociation_open,
                            email: group.email,
                            phone: group.phone
                        }
                    }/>
                </div>
                <div className={styles.description}>
                    <MusicianSingleDescription description={group.description} />
                </div>
                <div className={styles.place}>
                    <MusicianSingleLocation places={group.places} />
                </div>
            </section>

            {(group.administrators && group.administrators.length > 0) && 
                <section className={styles.section}>
                    <div className={styles.subheader}>
                        <p className={styles.subtitle}>Membres du groupe</p>
                    </div>
                    <div className={styles.members}>
                        <MembersSingle members={group.administrators}/>
                    </div>
                </section>
            }
            

            <section className={styles.section}>
                <div className={styles.subheader}>
                    <p className={styles.subtitle}>Formations</p>
                </div>
                <div className={styles.compo}>
                    <MusicianSingleCompo  composition={group.composition}/>
                </div>

            </section>
            <section className={styles.section}>
                <div className={styles.subheader}>
                    <p className={styles.subtitle}>Style et ligne éditoriale</p>
                </div>
                <div className={styles.medias}>
                    <StyleSingle stylesList={group.styles} />
                </div>
            </section>
            <section className={styles.section}>
                <div className={styles.subheader}>
                    <p className={styles.subtitle}>Médias</p>
                </div>
                <div className={styles.medias}>
                    <MusicianSingleMedia medias={group.medias} />
                </div>
                <div className={styles.videos}>
                    <MusicianSingleVideos videos={group.videos} />
                </div>
                <div className={styles.sounds}>
                    <MusicianSingleSpotify sounds={group.spotify} />
                </div>
                <div className={styles.sounds}>
                    <MusicianSingleSoundCloud sounds={group.soundcloud} />
                </div>
                
                <div className={styles.sounds}>
                    <MusicianSingleDeezer sounds={group.deezer} />
                </div>
            </section>
            
            <section className={styles.section}>
                <div className={styles.subheader}>
                    <p className={styles.subtitle}>Documentation technique</p>
                </div>
                <div className={styles.medias}>
                    <MusicianSingleDocuments documents={group.documents} />
                </div>
            </section>

        </div>
    )
}

export default SingleMusician