const EyeOpened = (props: any) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
    <path
      d="M7.5 3.281C2.812 3.281.937 7.5.937 7.5s1.875 4.219 6.563 4.219S14.063 7.5 14.063 7.5 12.187 3.281 7.5 3.281Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 9.844a2.344 2.344 0 1 0 0-4.688 2.344 2.344 0 0 0 0 4.688Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeOpened;
