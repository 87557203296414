const Camera = (props: any) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path
      d="M1.406 3.516h7.5a1.875 1.875 0 0 1 1.875 1.875v5.625a.469.469 0 0 1-.469.468h-7.5A1.875 1.875 0 0 1 .938 9.61V3.984a.469.469 0 0 1 .468-.468v0ZM10.781 6.563l3.281-1.875v5.625l-3.28-1.876"
      stroke="#718096"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Camera;
