import EventSingleBars from "events/components/eventSingle/eventSingleBars/EventSingleBars";
import EventSingleCharacteristics from "events/components/eventSingle/eventSingleCharacteristics/EventSingleCharacteristics";
import EventSingleDescription from "events/components/eventSingle/eventSingleDescription/EventSingleDescription";
import EventSingleGroups from "events/components/eventSingle/eventSingleGroups/EventSingleGroups";
import EventSingleInformation from "events/components/eventSingle/eventSingleInformation/EventSingleInformation";
import EventSingleStyles from "events/components/eventSingle/eventSingleStyles/EventSingleStyles";
import EventSingleMedia from "events/components/eventSingle/eventSingleMedia/EventSingleMedia";
import { handleGetEvent } from "events/controllers";

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "ui/components/button/Button";

import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";

import Pen from "ui/icons/pen/Pen";

import styles from "./SingleEvents.module.scss";

const SingleEvents = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    handleGetEvent(id)
      .then(function (response: any) {
        if (response.data) {
          setEvent(response.data);
          let groupsFinal = [];

          if (response.data.groups) {
            response.data.groups.map((x) => {
              if (x) {
                groupsFinal.push(x);
              }
            });
          }

          if (response.data.otherGroups) {
            response.data.otherGroups.map((x) => {
              groupsFinal.push(x);
            });
          }
          setGroups(groupsFinal);
        }
      })
      .catch((err) => {
        const data = {
          status: true,
          message: "aie",
          error: true,
        };
      });
  }, []);

  if (!event) return null;
  return (
    <div className={styles.main}>
      <Link to="/evenements/" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Retour
      </Link>
      <div className={styles.header}>
        <p className={styles.title}>{event.name}</p>
        <div className={styles.headerButtons}>
          <Link to={`/evenements/${id}/edit`}>
            <Button bgColor="green" textColor="purple">
              <Pen className={styles.pen_icon} />
              <p>Modifier</p>
            </Button>
          </Link>
        </div>
      </div>

      <section className={styles.section}>
        <div className={styles.information}>
          <EventSingleInformation
            information={{
              active: event.active,
              event_hours: event.event_hours,
              event_date: event.event_date,
              created_by_user: event.created_by_user,
              event_created_at: event.created_at,
            }}
          />
        </div>
        <div className={styles.description}>
          <EventSingleDescription description={event.description} />
        </div>
        <div className={styles.styles}>
          <EventSingleStyles stylesList={event.styles} />
        </div>
        <div className={styles.characteristics}>
          <EventSingleCharacteristics caracteristics={event.caracteristics} />
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.subheader}>
          <p className={styles.subtitle}>Médias</p>
        </div>
        <EventSingleMedia medias={event.medias} />
      </section>

      <section className={styles.sectionBox}>
        <div className={styles.subheader}>
          <p className={styles.subtitle}>Groupe(s)</p>
        </div>
        {groups && <EventSingleGroups groups={groups} />}
      </section>

      <section className={styles.sectionBox}>
        <div className={styles.subheader}>
          <p className={styles.subtitle}>Bar</p>
        </div>
        <EventSingleBars bar={event.bars} transport={event.transport} />
      </section>
    </div>
  );
};

export default SingleEvents;
