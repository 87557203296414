import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import PasswordChecklist from "react-password-checklist";

import {
  handleaddBarToUser,
  handleaddGroupToUser,
  handleRegistration,
} from "account/controllers/users";
import { showModal } from "app/actions/modal";

import WhiteSection from "ui/components/white-section/WhiteSection";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import Label from "ui/components/label/Label";
import Button from "ui/components/button/Button";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import UserGroup from "users/components/userGroup/UserGroup";
import UserBar from "users/components/userBar/UserBar";

import styles from "./CreateUser.module.scss";
import Loading from "app/components/loading/Loading";
import { errorsAPI } from "app/constants/errors";

import generatePassword from "utils/generatePassword";
import HeaderForm from "form/components/headerForm/HeaderForm";
import { passwordTrad } from "app/constants/traductions";

const CreateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = methods;

  const [groups, setGroups] = useState([]);
  const [bars, setBars] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data) => {
    setLoading(true);
    let finalData = {
      email: data.email,
      password: handleGeneratePassword(),
      role_name: data.role_name,
      genre: data.genre,
      birthdate: data.birthdate == "" ? null : data.birthdate,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      active: data.active,
    };

    handleRegistration(finalData)
      .then(function (response: any) {
        if (data.groups && data.groups.length > 0) {
          data.groups.map((group) => {
            let groupData = {
              group_id: group.group_id,
              user_id: response.data.id,
              role: "ADMIN",
            };
            if (group.group_id) {
              setLoading(true);
              handleaddGroupToUser(groupData).then(function (response: any) {
                setLoading(false);
              });
            }
          });
        }
        if (data.bars && data.bars.length > 0) {
          data.bars.map((bar) => {
            let barData = {
              bar_id: bar.bar_id,
              user_id: response.data.id,
              role: "ADMIN",
            };
            if (bar.bar_id) {
              setLoading(true);
              handleaddBarToUser(barData).then(function (response: any) {
                setLoading(false);
              });
            }
          });
        }

        setTimeout(() => {
          setLoading(false);
          navigate("/utilisateurs");
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
  };

  const handleGeneratePassword = (): string => {
    // e.preventDefault();
    const password = generatePassword(8);
    return password;
    // setValue("password", password);
  };

  useEffect(() => {
    let defaultValues: any = {};
    defaultValues.active = true;
    reset({ ...defaultValues });
  }, []);

  return (
    <main className={styles.main}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Link to="/utilisateurs" className={styles.back_link}>
            <ArrowLeft className={styles.back_icon} />
            Annuler
          </Link>

          <HeaderForm title={"Créer un utilisateur"} />
          <WhiteSection title="Profil">
            <div className={styles.form_container}>
              <div>
                <Label isRequired={true} htmlFor="firstname">
                  Prénom
                </Label>
                <input
                  type="text"
                  id="firstname"
                  className={
                    errors.firstname ? "m-input m-input--red" : "m-input"
                  }
                  {...register("firstname", { required: "Un prénom requis" })}
                />
                {errors.firstname && (
                  <p className={"m-error"}>Un prénom est requis</p>
                )}
              </div>
              <div>
                <Label isRequired={true} htmlFor="lastname">
                  Nom
                </Label>
                <input
                  type="text"
                  id="lastname"
                  className={
                    errors.lastname ? "m-input m-input--red" : "m-input"
                  }
                  {...register("lastname", { required: "Un nom est requis" })}
                />
                {errors.lastname && (
                  <p className={"m-error"}>Un nom est requis</p>
                )}
              </div>
              <div>
                <Label isRequired={true} htmlFor="gender">
                  Civilité
                </Label>
                <div className={"m-select-box"}>
                  <select id="gender" {...register("genre")}>
                    <option value="Mme">Madame</option>
                    <option value="Mr">Monsieur</option>
                  </select>
                  <ArrowDown />
                </div>
              </div>
              <div>
                <Label isRequired={true} htmlFor="birthdate">
                  Date de naissance
                </Label>
                <input
                  className={
                    errors.birthdate
                      ? "m-input m-input--small115 m-input--red"
                      : "m-input m-input--small115"
                  }
                  type={"date"}
                  {...register("birthdate")}
                />
                {errors.birthdate && (
                  <p className={"m-error"}>Une date est requis</p>
                )}
              </div>
              <div>
                <Label isRequired={true} htmlFor="email">
                  Email
                </Label>
                <input
                  type="text"
                  id="email"
                  className={errors.email ? "m-input m-input--red" : "m-input"}
                  {...register("email", {
                    required: "Un email est requis",
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <p className={"m-error"}>Un email est requis</p>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <p className={"m-error"}>L'email n'est pas valide</p>
                )}
              </div>
              {/* <div className={styles.password}>
                <Label isRequired={true} htmlFor="password">
                  Mot de passe
                </Label>
                <input
                  type="text"
                  id="password"
                  className={
                    errors.password ? "m-input m-input--red" : "m-input"
                  }
                  {...register("password", {
                    required: "Un mot de passe est requis",
                    pattern:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~`¿é¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])[A-Za-z\d~`¿é¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,}$/,
                  })}
                />
                <button
                  className={styles.generate_password}
                  onClick={handleGeneratePassword}
                >
                  Générer un mot de passe aléatoire
                </button>
                {watch("password") === "" ||
                watch("password") === undefined ||
                watch("password") === null ? (
                  ""
                ) : (
                  <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={8}
                    value={watch("password")}
                    messages={passwordTrad}
                  />
                )}
                {errors.password && errors.password.type === "required" && (
                  <p className={"m-error"}>Un mot de passe est requis</p>
                )}
                {errors.password && errors.password.type === "pattern" && (
                  <p className={"m-error"}>
                    Le mot de passe ne respecte pas les conditions
                  </p>
                )}
              </div> */}

              <div>
                <Label isRequired={true} htmlFor="phone">
                  Numéro de téléphone
                </Label>
                <input
                  type="tel"
                  id="phone"
                  className={errors.phone ? "m-input m-input--red" : "m-input"}
                  {...register("phone", {
                    required: true,
                    pattern: /^\d{10}$/im,
                  })}
                />
                {errors.phone && errors.phone.type === "required" && (
                  <p className={"m-error"}>Un téléphone est requis</p>
                )}
                {errors.phone && errors.phone.type === "pattern" && (
                  <p className={"m-error"}>Le téléphone n'est pas valide</p>
                )}
              </div>
              <div>
                <Label isRequired={true} htmlFor="role">
                  Rôle
                </Label>
                <div className={"m-select-box"}>
                  <select
                    id="role"
                    {...register("role_name", {
                      required: "Un role est requis",
                    })}
                  >
                    <option value="USER">Utilisateur</option>
                    <option value="SUPER_ADMIN">Administrateur</option>
                  </select>
                  <ArrowDown />
                </div>
              </div>
            </div>
          </WhiteSection>
          <div className={styles.bottom}>
            <div className={styles.bottomBlock}>
              <p className={styles.bottomTitle}>Groupe(s)</p>
              <UserGroup groupsEdit={groups} />
            </div>
            <div className={styles.bottomBlock}>
              <p className={styles.bottomTitle}>Bar(s) et lieu(x)</p>
              <UserBar barsEdit={bars} />
            </div>
          </div>
          <Button
            type="submit"
            bgColor="green"
            textColor="purple"
            className={styles.create_button}
          >
            {loading ? <Loading /> : "Créer un utilisateur"}
          </Button>
        </form>
      </FormProvider>
    </main>
  );
};

export default CreateUser;
